<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card class="pa-3">
            <report-date-time-picker title="Fuel By State" @change="change"></report-date-time-picker>
            <v-divider></v-divider>
            <v-data-table
              :items="reportData"
              :headers="headers"
              ></v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ReportDateTimePicker from "@/components/core/ReportDateTimePicker";
export default {
  name: "FuelByState",
  components: {ReportDateTimePicker},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    reportData: [],

    headers: [
      {
        text: 'State',
        sortable: true,
        value: 'state',
      },
      {
        text: 'Item',
        sortable: true,
        value: 'state',
      },
      {
        text: 'Quantity',
        sortable: true,
        value: 'usg',
        align: 'right'

      },
      {
        text: 'Amount',
        sortable: true,
        value: 'amount',
        align: 'right'
      },
    ],

  }),


  methods:{
    change(obj){
      console.log(obj)
    }
  },

}
</script>

<style scoped>

</style>
