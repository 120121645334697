import Vue from 'vue'
import './components'

import axios from 'axios'
import vuetify from './plugins/vuetify'
import VuetifyConfirm from 'vuetify-confirm'

//import { sync } from 'vuex-router-sync'

import App from './App.vue'
import store from './store'
import router from './router'

import Pusher from 'pusher-js';
Vue.prototype.$pusher = new Pusher('b5c3fe12bedbe992906d',{
  cluster: 'us2'
})

Number.prototype.formatCurency = function(maxfrac= 2){
  return this.toLocaleString(undefined,{minimumFractionDigits:maxfrac,maximumFractionDigits:maxfrac})
}

//window.Pusher = require('pusher-js');

/*
import Echo from "laravel-echo";

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: 'ca309f7510e749f4da6a'
})
*/
import helpers from '@/plugins/Helpers'

import { firestorePlugin } from 'vuefire'

Vue.use(firestorePlugin)

Vue.prototype.$http = axios
// Sets the default url used by all of this axios instance's requests
if(process.env.NODE_ENV === 'development'){
  axios.defaults.baseURL = 'http://127.0.0.1:8081/api'
}else{
  axios.defaults.baseURL = 'https://gradea-api.fullnet.com/api'
}

axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';


//const token = localStorage.getItem('token')
const token = store.state.token
if (token) {
  axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}

// Sync store with router
//sync(store, router)


Vue.config.productionTip = false

Vue.use(VuetifyConfirm, {
  vuetify
});

Vue.use(helpers, {
});

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
