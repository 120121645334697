var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-snackbar',{attrs:{"timeout":_vm.snackBar.timeout,"color":_vm.snackBar.color,"top":"","multi-line":""},model:{value:(_vm.snackBar.show),callback:function ($$v) {_vm.$set(_vm.snackBar, "show", $$v)},expression:"snackBar.show"}},[_vm._v(_vm._s(_vm.snackBar.text))]),_c('v-breadcrumbs',{staticStyle:{"background-color":"#f5f5f5"},attrs:{"items":_vm.bread,"large":""}})],1),_c('v-col',{staticClass:"pa-5",attrs:{"cols":"12"}},[_c('v-card',[_c('v-row',{staticClass:"px-5"},[_c('v-col',{attrs:{"md":"auto"}},[_c('v-toolbar-title',[_vm._v("Client Fuel Report")])],1),_vm._l((_vm.quickButtons),function(button){return _c('v-col',{key:button.name,attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary","x-small":""},on:{"click":function($event){return _vm.setDate(button.name)}}},[_vm._v(_vm._s(button.name))])],1)}),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-switch',{staticStyle:{"margin-top":"-4px"},attrs:{"label":"All Clients"},on:{"change":_vm.getReportData},model:{value:(_vm.allClients),callback:function ($$v) {_vm.allClients=$$v},expression:"allClients"}})],1),_c('v-col',{attrs:{"cols":"auto"}},[(_vm.reportData.rows && _vm.reportData.rows.length)?_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":_vm.renderPDF}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-file-pdf-box")])],1):_vm._e()],1)],2),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Starting date for report","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.fromWithTime),callback:function ($$v) {_vm.fromWithTime=$$v},expression:"fromWithTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuFrom = false},"change":_vm.customDate},model:{value:(_vm.from),callback:function ($$v) {_vm.from=$$v},expression:"from"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4","lg":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Starting date for report","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.toWithTime),callback:function ($$v) {_vm.toWithTime=$$v},expression:"toWithTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{on:{"input":function($event){_vm.menuTo = false},"change":_vm.customDate},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1)],1),_c('v-col',{staticClass:"mt-5"},[_c('h2',[_vm._v(_vm._s(_vm.capDateSpan))])])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"dense":"","items":_vm.reportData.rows,"headers":_vm.headers,"no-data-text":"Client has no fuel transactions for the date's chosen.","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.quantity.formatCurency())+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount.formatCurency())+" ")]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.unit_price.formatCurency())+" ")]}},{key:"item.discount_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_price.formatCurency())+" ")]}},{key:"item.discount_ppg",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount_ppg.formatCurency())+" ")]}},{key:"item.discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.discount.formatCurency())+" ")]}},{key:"item.carrier_fee",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.carrier_fee.formatCurency())+" ")]}},{key:"item.line_total",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.line_total.formatCurency())+" ")]}},(_vm.reportData.rows && _vm.reportData.rows.length)?{key:"footer",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"pr-7",attrs:{"cols":"12","align":"right"}},[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("Amount Billed: "+_vm._s(_vm.reportData.total.formatCurency()))])])],1)]},proxy:true}:null],null,true)})],1)],1),_c('v-row',_vm._l((_vm.reportData.items),function(item){return _c('v-col',{key:item.item},[_c('fuel-item',{attrs:{"item":item}})],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }