<template>
  <v-dialog v-model="show" persistent :width="900">
    <v-card :disabled="adding">
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
            ref="cardtransactionform"
            v-model="cardTransactionValid"
            lazy-validation
        >
          <v-row no-gutters class="mt-3">
            <v-col cols="12" md="5" class="pr-3">
              <v-text-field
                  label="Description"
                  v-model="cardTransaction.description"
                  type="text"
                  :rules="[rules.required]"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="4" class="pr-3">
              <v-text-field
                  label="Reference"
                  v-model="cardTransaction.reference"
                  type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pr-3">
              <v-text-field
                  label="Amount"
                  v-model="cardTransaction.amount"
                  :rules="[rules.required,rules.notZero]"
                  type="number"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="adding" elevation="0" color="primary" @click="add">
          save transaction
        </v-btn>
        <v-btn elevation="0" color="secondary" @click="cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "CardTransactionDialog",

  props:{
    show:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Card Transaction'
    },

    transaction: {
      type: Object,
      required: true,
    }
  },

  data: () => ({

    adding: false,

    cardTransaction:{
      description: '',
      reference: '',
      amount: 0,
    },

    cardTransactionValid: true,

    rules: {
      required: value => !!value || 'Required.',
      notZero: v => Number(v) > 0 || 'Amount must be > 0.00'
    },


  }),

  beforeMount(){
    this.cardTransaction = {...this.transaction}
  },

  methods:{
    add(){
      if(this.$refs.cardtransactionform.validate() === true) {
        this.adding = true
        this.$emit('save', this.cardTransaction)
      }
    },
    cancel(){
      this.$emit('cancel')
    },
  },

  watch:{
    show(){
      if(this.$refs.cardtransactionform){
        this.$refs.cardtransactionform.resetValidation()
      }
      if(this.show === true){
        this.adding = false
        this.cardTransaction = {...this.transaction}
      }
    }
  }

}
</script>

<style scoped></style>
