<template>
  <v-app-bar
      id="core-toolbar"
      app
      flat
      dark
      color="black"
      >

    <v-app-bar-nav-icon @click.stop="onClickBtn"></v-app-bar-nav-icon>

    <v-toolbar-title v-if="mode === 'development'" class="red pr-5 mr-2">DEV</v-toolbar-title>

    <div v-if="$store.state.app.section === 'admin'">
      <v-btn text to="/admin/users">users</v-btn>
      <v-btn text to="/admin/roles">roles</v-btn>
      <v-btn text to="/admin/permissions">permissions</v-btn>
      <v-btn text to="/admin/log">log</v-btn>
    </div>

    <div v-else-if="$store.state.app.section === 'funding'">
      <v-btn text to="/funding/clients">clients</v-btn>
      <v-btn text to="/funding/moneycodes">money codes</v-btn>
      <v-btn text to="/funding/policylist">policys</v-btn>
      <v-btn text to="/funding/reports">reports</v-btn>
    </div>

    <v-spacer/>

    <v-menu
        bottom
        left
        :nudge-width="200"
    >
      <template v-slot:activator="{ on, attrs }">
          <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
              class="mr-3"
          >
            <v-avatar>
              <gravatar :email="$store.state.userEmail" default-img="mp"></gravatar>
            </v-avatar>
            <v-icon right>mdi-chevron-down</v-icon>
          </v-btn>
      </template>

      <v-card>
      <v-list>
        <v-list-item>
            <v-list-item-avatar>
              <gravatar :email="$store.state.userEmail" default-img="mp"></gravatar>
            </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ this.$store.state.userName }}</v-list-item-title>
            <v-list-item-subtitle>{{ this.$store.state.userEmail }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
            v-for="(item, i) in userItems"
            :key="i"
            v-on:click="userMenu(item.action)"
            link
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
      </v-card>
    </v-menu>


  </v-app-bar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

import Gravatar from 'vue-gravatar';

import UserService from '@/services/user.service'

//const store = require("core-js");

export default {
  components: {
    Gravatar
  },

  data: () => ({

    mode: process.env.NODE_ENV,

    userItems:[
      {title: 'Logout', action: 'logout'},
      {title: 'Change Password', action: 'pwd'}

    ],

    sections:[
      {title: "Funding", name: 'funding'}
    ],

    title: 'Stuff',
  }),


  computed: {
    ...mapGetters(['authorized']),

  },

  watch: {
    $route (val) {
      this.title = val.meta.name
    }
  },
  mounted () {
  },
  beforeDestroy () {
  },

  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),
    onClickBtn () {
      this.setDrawer(!this.$store.state.app.drawer)
    },
    userMenu (action) {

      if(action === 'logout'){
        this.logout()
      }else if(action === 'pwd'){
        this.changePassword()
      }


    },
    logout: function () {
      this.$store.dispatch('logout').then(() => {
        this.$router.push('/')
      })
    },

    changePassword: function(){

      this.$confirm('To change your password you will be logged out, then after logging back in you will be prompted for your new password.<br/><br/> Are you sure you want to change your password?',{title: 'Change Password'}).then(res => {
        if(res === true){
          UserService.setChangePassword(this.$store.state.uid,1)
          .then(
              this.logout()
          )
        }
      })
    },

  }
}
</script>

<style>
#core-toolbar a {
  text-decoration: none;
}
</style>
