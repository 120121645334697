import axios from 'axios'

class CreditManagerService {

    credit(cid, amount, description, reference) {
        return axios.post('/clients/creditmanager/credit', {cid: cid, amount: amount, description: description, reference: reference})
    }

    debit(cid, amount, description, reference) {
        return axios.post('/clients/creditmanager/debit', {cid: cid, amount: amount, description: description, reference: reference})
    }

    transactions(cid) {
        return axios.post('/clients/creditmanager/transactions', {cid: cid})
    }
}

export default new CreditManagerService();

