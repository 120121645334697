<template>
  <v-row v-if="client" no-gutters>
    <v-col cols="12">
      <v-row justify="center">
        <v-dialog v-model="showDriverDialog" persistent max-width="1000px">
          <v-card>
            <v-card-title>
              <span v-if="!isNewDriver" class="headline">Edit Driver</span>
              <span v-else class="headline">Add Driver</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-form ref="driverform" v-model="drivervalid" lazy-validation>
                  <v-row>
                    <v-col cols="12" class="col-lg-4">
                      <v-text-field
                        label="First Name *"
                        placeholder="John"
                        v-model="driverToEdit.first_name"
                        :counter="100"
                        :rules="driverRules.firstName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="col-lg-4">
                      <v-text-field
                        label="Last Name *"
                        placeholder="Henry"
                        v-model="driverToEdit.last_name"
                        :counter="100"
                        :rules="driverRules.lastName"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="col-lg-4">
                      <v-text-field
                        label="Phone Number"
                        placeholder="8126831234"
                        v-model="driverToEdit.phone"
                        :counter="10"
                        :rules="driverRules.phone"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="col-lg-4">
                      <v-text-field
                        label="E-Mail Address"
                        placeholder="greg@gradeafunding.com"
                        v-model="driverToEdit.email"
                        :counter="100"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="col-lg-4">
                      <v-select
                        label="Fuel Report"
                        :items="fuelReportItems"
                        v-model="driverToEdit.fuel_report"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" class="col-lg-4">
                      <v-text-field
                        label="Truck Number"
                        placeholder="179"
                        v-model="driverToEdit.truck_number"
                        :counter="32"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="col-lg-3">
                      <v-switch
                        label="Active"
                        v-model="driverToEdit.active"
                        @change="driverToEdit.mobile_access = false"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" class="col-lg-3">
                      <v-switch
                        label="Mobile Access"
                        v-model="driverToEdit.mobile_access"
                        :disabled="!driverToEdit.email"
                      ></v-switch>
                    </v-col>
                    <v-col cols="12" class="col-lg-6">
                      <v-text-field
                        label="Mobile Password"
                        v-model="driverToEdit.mobile_password"
                        :append-icon="
                          showMobilePassword ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        append-outer-icon="mdi-lock-plus"
                        :type="showMobilePassword ? 'text' : 'password'"
                        @click:append="showMobilePassword = !showMobilePassword"
                        @click:append-outer="makeMobilePassword"
                        :disabled="
                          !driverToEdit.email || !driverToEdit.mobile_access
                        "
                        counter
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="red darken-1" text @click="cancel"> Cancel </v-btn>
              <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
        >{{ snackBar.text }}</v-snackbar
      >
      <v-breadcrumbs
        style="background-color: #f5f5f5"
        :items="bread"
        large
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12" class="col-lg-6">
      <v-card class="ma-5" elevation="2">
        <v-card-title>{{ client.name }}</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col col="12" class="col-lg-6">
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-map-marker </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ client.street }}</v-list-item-title>
                    <v-list-item-subtitle v-if="client.street2">{{
                      client.street2
                    }}</v-list-item-subtitle>
                    <v-list-item-subtitle
                      >{{ client.city }}, {{ client.state }}
                      {{ client.zip }}</v-list-item-subtitle
                    >
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-phone </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ clientPhone }}</v-list-item-title>
                    <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-email </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ client.email }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
            <v-col col="12" class="col-lg-6">
              <v-list dense>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-gas-station </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ client.fuel_discount }}%</v-list-item-title
                    >
                    <v-list-item-subtitle>Fuel fee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-credit-card </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ client.transaction_fee }}%</v-list-item-title
                    >
                    <v-list-item-subtitle>Transaction fee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="indigo"> mdi-currency-usd </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{
                      client.minimum_fee.formatCurency()
                    }}</v-list-item-title>
                    <v-list-item-subtitle>Minimum fee</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="col-lg-6 text-right">
      <v-btn
        v-if="$store.state.permissions.clientCreditView"
        color="primary"
        class="mt-5 mr-2"
        to="clientcreditmanager"
      >
        <v-icon left>mdi-credit-card</v-icon>
        credit manager
      </v-btn>
      <v-btn color="primary" class="mt-5 mr-2" to="clientfuelreport">
        <v-icon left>mdi-file-document-multiple</v-icon>
        Fuel Report
      </v-btn>
    </v-col>
    <v-col cols="12" class="ma-2">
      <v-data-table
        style="cursor: pointer"
        :headers="headers"
        :items="filteredDrivers"
        no-data-text="This client has no driver's"
        :sort-by="['last_name', 'first_name']"
        multi-sort
        :search="search"
        v-on:dblclick:row="doubleClickedRow"
        :loading="loading"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-btn
              icon
              color="blue"
              @click="getDrivers"
              :loading="loading"
              :disabled="loading"
            >
              <v-icon>mdi-refresh</v-icon>
            </v-btn>

            <v-toolbar-title>Driver's</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
            <v-switch
              v-if="inactiveDrivers > 0"
              class="pr-5 pt-5"
              v-model="showInactive"
              :label="
                showInactive
                  ? 'Hide ' + inactiveDrivers + ' Inactive'
                  : 'Show ' + inactiveDrivers + ' Inactive'
              "
            ></v-switch>
            <v-btn color="primary" @click="newDriver">
              <v-icon left>mdi-plus</v-icon>
              New Driver
            </v-btn>
          </v-toolbar>
        </template>
        <template v-slot:item.last_name="{ item }">
          <v-icon v-if="!item.active" small color="red">mdi-close</v-icon>
          <v-badge
            v-if="messageCount(item)"
            left
            :content="messageCount(item)"
            color="red"
            class="ml-3 mr-2"
          >
          </v-badge>
          {{ item.last_name }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="viewDriver(item)">
            mdi-eye
          </v-icon>
          <v-icon small class="mr-2" @click="editDriver(item)">
            mdi-pencil
          </v-icon>
          <v-icon small @click="deleteDriver(item)"> mdi-delete </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import ClientService from "@/services/client.service";

export default {
  props: {
    //client: {
    //  type: Object,
    //  default: null,
    //  required: true
    //}
  },

  data: () => ({
    client: {},
    snackBar: {
      show: false,
      text: "",
      timeout: 5000,
      color: "green",
    },

    loading: false,

    search: null,
    showInactive: false,
    drivervalid: true,
    showDriverDialog: false,
    isNewDriver: true,
    editedIndex: -1,
    showMobilePassword: false,
    driverToEdit: {},
    defaultDriver: {
      id: 0,
      client_id: 0,
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      efs_card: "",
      truck_number: "0",
      fuel_report: null,
      fuel_discount: 0,
      credit_type: 0,
      payment_type: 0,
      payment_frequency: 0,
      credit_override: 0,
      mobile_access: false,
      active: true,
    },
    fuelReportItems: ["None", "Daily", "Weekly", "Both"],

    drivers: [],
    headers: [
      {
        text: "Last Name",
        sortable: true,
        value: "last_name",
      },
      {
        text: "First Name",
        sortable: true,
        value: "first_name",
      },
      {
        text: "Phone Number",
        sortable: true,
        value: "phone",
      },
      {
        text: "EFS Card",
        sortable: true,
        value: "efs_card",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    driverRules: {
      firstName: [
        (v) => !!v || "First Name is required",
        (v) =>
          (v && v.length <= 100) || "First Name must be 100 characters or less",
      ],
      lastName: [
        (v) => !!v || "Last Name is required",
        (v) =>
          (v && v.length <= 100) || "Last Name must be 100 characters or less",
      ],
      phone: [
        (v) => !!v || "Phone Number is required.",
        (v) =>
          /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im.test(v) ||
          "Phone number is invalid.",
      ],
    },
  }),

  beforeMount() {
    this.client = this.$store.state.client;
    if (!this.client) {
      this.$router.back();
    }
  },

  mounted() {
    if (this.client) {
      this.getDrivers();
    }
  },

  methods: {
    makeMobilePassword() {
      const length = 10;
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let pwd = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        pwd += charset.charAt(Math.floor(Math.random() * n));
      }
      this.driverToEdit.mobile_password = pwd;
    },

    messageCount(driver) {
      const counts = this.$store.state.messageCounts;
      for (let i = 0; i < counts.drivers.length; i++) {
        if (counts.drivers[i].id === driver.id) {
          return counts.drivers[i].count;
        }
      }
      return 0;
    },

    closeDialog(driver) {
      this.showDriverDialog = false;
      if (driver) {
        let _this = this;
        if (driver.id > 0) {
          ClientService.updateDriver(driver)
            .then(function (response) {
              Object.assign(
                _this.drivers[_this.editedIndex],
                response.data.driver
              );
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;
            })
            .catch(function (error) {
              console.log(error.response);

              _this.snackBar.text =
                "Error updating driver!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
            });
        } else {
          ClientService.addDriver(driver)
            .then(function (response) {
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;
              //_this.getClients()
              _this.drivers.push(response.data.driver);
            })
            .catch(function (error) {
              _this.snackBar.text =
                "Error adding driver!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
              console.log(error.response);
            });
        }
      }
    },

    viewDriver(driver) {
      this.$store.commit("current_driver", driver);
      this.$router.push({ name: "Driver", params: { driver: driver } });
    },

    doubleClickedRow(mouse_event, item) {
      this.viewDriver(item.item);
    },

    getDrivers() {
      let _this = this;
      this.loading = true;
      ClientService.driverList(this.client.id)
        .then(function (response) {
          _this.drivers = response.data;
        })
        .catch(function (error) {
          console.log(error);
          let msg = _this.$errorMessage(error);
          _this.snackBar.text = "Error loading drivers!\n(" + msg + ")";
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
        })
        .then(function () {
          _this.loading = false;
        });
    },

    newDriver() {
      this.isNewDriver = true;
      this.editedIndex = -1;
      this.driverToEdit = Object.assign({}, this.defaultDriver);
      this.driverToEdit.client_id = this.client.id;
      this.showDriverDialog = true;
    },

    editDriver(driver) {
      this.isNewDriver = false;
      this.editedIndex = this.drivers.indexOf(driver);
      this.driverToEdit = Object.assign({}, driver);
      this.showDriverDialog = true;
    },

    deleteDriver(driver) {
      this.editedIndex = this.drivers.indexOf(driver);
      let _this = this;
      this.$confirm(
        "Are you sure you want to delete " +
          driver.first_name +
          " " +
          driver.last_name +
          " from " +
          this.client.name,
        { title: "Delete Driver" }
      ).then((res) => {
        if (res === true) {
          ClientService.deleteDriver(driver)
            .then(function (response) {
              _this.drivers.splice(_this.editedIndex, 1);
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;
            })
            .catch(function (error) {
              _this.snackBar.text =
                "Error deleting driver!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
              console.log(error.response);
            });
        }
      });
    },

    save() {
      if (
        this.driverToEdit.mobile_access &&
        (!this.driverToEdit.mobile_password ||
          this.driverToEdit.mobile_password.length < 8)
      ) {
        this.snackBar.color = "red";
        this.snackBar.text =
          "A password of 8 characters or more is required to enable mobile access.";
        this.snackBar.show = true;
        return;
      }

      if (this.$refs.driverform.validate() === true) {
        this.closeDialog(this.driverToEdit);
      }
    },

    cancel() {
      this.$refs.driverform.resetValidation();
      this.showDriverDialog = false;
    },
  },

  computed: {
    inactiveDrivers() {
      return this.drivers.filter((driver) => driver.active == false).length;
    },

    filteredDrivers() {
      if (this.showInactive) {
        return this.drivers;
      }
      return this.drivers.filter((driver) => driver.active == true);
    },

    clientPhone() {
      if (this.client.phone.length === 10) {
        let npa = this.client.phone.substr(0, 3);
        let nxx = this.client.phone.substr(3, 3);
        let num = this.client.phone.substr(6, 4);
        return "(" + npa + ") " + nxx + "-" + num;
      }
      return this.client.phone;
    },

    bread() {
      return [
        {
          text: "Clients",
          disabled: false,
          to: "/funding/clients",
        },

        {
          text: this.client.name,
          disabled: false,
        },
      ];
    },
  },

  watch: {
    showDriverDialog() {
      if (this.$refs.driverform) {
        this.$refs.driverform.resetValidation();
      }
    },
  },
};
</script>

<style scoped>
</style>
