<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <please-wait :show="loading" title="Communicating with EFS..."></please-wait>
    <yesno-dialog :title="yesNo.title" :show="yesNo.show" :info="yesNo.info" @no="yesNo.show=false" @yes="confirmDeleteLimit"></yesno-dialog>
    <yesno-dialog :title="yesNoPrompt.title" :show="yesNoPrompt.show" :info="yesNoPrompt.info" @no="yesNoPrompt.show=false" @yes="confirmDeletePrompt"></yesno-dialog>
    <add-infos-dialog :show="showAddPromptDialog"  @cancel="cancelAddPrompt" @addPrompt="addPrompt"></add-infos-dialog>
    <add-limits-dialog :show="showAddLimitDialog"  @cancel="cancelAddLimit" @addLimit="addLimit"></add-limits-dialog>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12" class="pa-5" md="6">
          <v-card>
            <v-card-text>
                  <v-select
                      label="Policy Number"
                      placeholder="Select a policy"
                      :items="policyDescriptions"
                      no-data-text="No policy's avaible."
                      :disabled="loading"
                      :loading="loading"
                      @change="fetchPolicy"
                  ></v-select>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" class="pa-5" md="6">
          <v-btn dark color="red" v-if="dirty" :disabled="loading" @click="updatePolicy">
            <v-icon left>mdi-content-save</v-icon>
            update policy
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="policy">
        <v-col cols="12" class="pa-5">
          <v-card>
            <v-card-title>Header</v-card-title>
            <v-card-text>
              <v-row no-gutters v-if="policy.header">
                <v-col cols="12" md="6" class="pl-3">
                  <v-text-field
                      label="Description"
                      v-model="policy.header.description"
                      @change="dirty=true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="pl-3">
                  <v-text-field
                      label="Payroll Contract ID"
                      v-model="policy.header.payrollContractId"
                      @change="dirty=true"
                  >
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Hand Enter"
                      v-model="policy.header.handEnter"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Payroll ACH"
                      v-model="policy.header.payrollAch"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Payroll ATM"
                      v-model="policy.header.payrollAtm"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Payroll Check"
                      v-model="policy.header.payrollChk"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Payroll Debit"
                      v-model="policy.header.payrollDebit"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="2" sm="6" class="pl-3">
                  <v-switch
                      label="Payroll Wire"
                      v-model="policy.header.payrollWire"
                      @change="dirty=true"
                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row no-gutters v-if="infos">
        <v-col cols="12" class="pa-5">
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title>Info Prompts</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn outlined v-if="!adding" @click="showAddPromptDialog=true">
                <v-icon left>mdi-plus</v-icon>
                add
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in infos" :key="index">
                  <v-expansion-panel-header color="blue-grey lighten-5">{{item.infoId + ' - ' + $store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === item.infoId)[0].description}}
                    <v-spacer></v-spacer>
                    <v-btn color="red" icon x-small max-width="20" @click="deletePrompt(item)" title="Delete Prompt" class="mr-5">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <info-prompt :info="item"></info-prompt>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row no-gutters v-else>
        <v-col col="12" class="pa-5" v-if="policy">
          <v-btn outlined @click="showAddPromptDialog=true">
            <v-icon left>mdi-plus</v-icon>
            add policy info prompt
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="policy && policy.limits">
        <v-col cols="12" class="pa-5">
          <v-card>
            <v-toolbar elevation="0">
              <v-toolbar-title>Limits</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn outlined v-if="!adding" @click="showAddLimitDialog=true">
                <v-icon left>mdi-plus</v-icon>
                add
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-expansion-panels>
                <v-expansion-panel v-for="(item, index) in policy.limits" :key="index">
                  <v-expansion-panel-header color="blue-grey lighten-5">{{item.limitId + ' - ' + $store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === item.limitId)[0].description}}
                  <v-spacer></v-spacer>
                    <v-btn color="red" icon x-small max-width="20" @click="deleteLimit(item)" title="Delete Limit" class="mr-5">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <policy-limit :limit="item" @change="dirty=true"></policy-limit>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import PolicyService from '@/services/efs/policy.service'
import InfoPrompt from "@/components/FundingViews/policys/InfoPrompt";
import PolicyLimit from "@/components/FundingViews/policys/PolicyLimit";
import PleaseWait from "@/components/PleaseWait";
import YesnoDialog from "@/components/YesnoDialog";
import AddInfosDialog from "@/components/FundingViews/policys/AddInfosDialog";
import AddLimitsDialog from "@/components/FundingViews/policys/AddLimitsDialog";


export default {
  name: "PolicyList",
  components: {AddLimitsDialog, AddInfosDialog, YesnoDialog, PleaseWait, PolicyLimit, InfoPrompt},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    loading: false,
    descriptions: [],
    policy: null,
    adding: false,
    dirty: false,
    currentPolicyNumber: 0,

    yesNo: {
      show: false,
      title: 'Delete',
      info: 'You ok with this'
    },

    yesNoPrompt: {
      show: false,
      title: 'Delete',
      info: 'You ok with this'
    },


    showAddPromptDialog: false,
    showAddLimitDialog: false,

    indexToDelete: -1,

  }),

  mounted() {
    this.fetchDescriptions()
  },

  methods:{

    cancelAddLimit(){
      this.showAddLimitDialog = false
    },

    addLimit(limit){
      this.policy.limits.push(limit)
      this.dirty = true
      this.showAddLimitDialog = false
    },

    confirmDeleteLimit(){
      if(this.indexToDelete !== -1){
        this.policy.limits.splice(this.indexToDelete,1)
        this.indexToDelete = -1
        this.dirty = true
        this.yesNo.show = false
      }
    },

    deleteLimit(limit){
      this.indexToDelete = this.policy.limits.indexOf(limit)
      this.yesNo.title = "Delete Limit "+limit.limitId + ' - ' + this.$store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === limit.limitId)[0].description+" ?"
      this.yesNo.info = "Once saved this operation can not be undone and will affect any cards this policy is assigned to!"
      this.yesNo.show = true
    },

    cancelAddPrompt(){
      this.showAddPromptDialog = false
    },

    addPrompt(prompt){
      this.policy.infos.push(prompt)
      this.dirty = true
      this.showAddPromptDialog = false
    },

    confirmDeletePrompt(){
      if(this.indexToDelete !== -1){
        this.policy.infos.splice(this.indexToDelete,1)
        this.indexToDelete = -1
        this.dirty = true
        this.yesNoPrompt.show = false
      }
    },

    deletePrompt(prompt){
      this.indexToDelete = this.policy.infos.indexOf(prompt)
      this.yesNoPrompt.title = "Delete Prompt "+prompt.infoId + ' - ' + this.$store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === prompt.infoId)[0].description+" ?"
      this.yesNoPrompt.info = "Once saved this operation can not be undone and will affect any cards this policy is assigned to!"
      this.yesNoPrompt.show = true
    },


    updatePolicy(){
      this.loading = true
      let _this = this

      PolicyService.updatePolicy(this.policy).then(
          response => {
            _this.dirty = false
            _this.snackBar.text = response.data.message;
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
          }
      ).catch(function (error) {
        _this.eCodeRequest = false;
        _this.viewMoneyCodeDialog = false;
        _this.snackBar.text = "Error getting Policy!\n(" + error.response.status + ") " + error.response.statusText
        _this.snackBar.color = 'red';
        _this.snackBar.show = true;
        console.log(error.response);
      }).then(function () {
        _this.loading = false
      })
    },

    fetchDescriptions(){
      this.loading = true
      let _this = this

      PolicyService.descriptions().then(
          response => {
            _this.descriptions = response.data.descriptions
          }
      ).catch(function (error) {
        _this.eCodeRequest = false;
        _this.viewMoneyCodeDialog = false;
        _this.snackBar.text = "Error getting Policy Descriptions!\n(" + error.response.status + ") " + error.response.statusText
        _this.snackBar.color = 'red'
        _this.snackBar.show = true
        console.log(error.response)
      }).then(function () {
        _this.loading = false
      })
    },

   fetchPolicy(policy_number){
      this.loading = true
      this.policy = null
      let _this = this

      PolicyService.policy(policy_number).then(
          response => {
            console.log(response.data.policy)
            _this.policy = response.data.policy
            _this.policy.header.handEnter = _this.policy.header.handEnter === 'true'
            _this.policy.header.payrollAch = _this.policy.header.payrollAch === 'true'
            _this.policy.header.payrollAtm = _this.policy.header.payrollAtm === 'true'
            _this.policy.header.payrollChk = _this.policy.header.payrollChk === 'true'
            _this.policy.header.payrollDebit = _this.policy.header.payrollDebit === 'true'
            _this.policy.header.payrollWire = _this.policy.header.payrollWire === 'true'
            _this.currentPolicyNumber = policy_number
          }
      ).catch(function (error) {
        _this.eCodeRequest = false;
        _this.viewMoneyCodeDialog = false;
        _this.snackBar.text = "Error getting Policy!\n(" + error.response.status + ") " + error.response.statusText
        _this.snackBar.color = 'red'
        _this.snackBar.show = true
        console.log(error.response)
      }).then(function () {
        _this.loading = false
      })
    },

  },

  computed:{

    prompts(){
      let items = []
      for(let i=0;i<this.$store.state.efsInfoIds.length;i++){
        items.push({text: this.$store.state.efsInfoIds[i].description, value: this.$store.state.efsInfoIds[i].name})
      }
      return items
    },

    infos(){
      let items = []
      if(this.policy){
        if(typeof this.policy.infos !== 'undefined'){
          if(Array.isArray(this.policy.infos)){
            items = this.policy.infos
          }else{
            items.push(this.policy.infos)
          }
        }
      }

      if(items.length){
        return items
      }
      return null
    },

    policyDescriptions(){
      let items = []
      if(Array.isArray(this.descriptions.value)){
        for(let i=0;i<this.descriptions.value.length;i++){
          items.push({text: this.descriptions.value[i].policyNumber+' - '+this.descriptions.value[i].description, value: this.descriptions.value[i].policyNumber })
        }
      }else{
        if(Array.isArray(this.descriptions) === false){
          items.push({text: this.descriptions.value.policyNumber+' - '+this.descriptions.value.description, value: this.descriptions.value.policyNumber })
        }
      }
      return items
    }
  },

}
</script>

<style scoped>
</style>
