<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-if="!multiline" :label="inputTitle" required v-model="dataInput"></v-text-field>
                <v-textarea v-else :label="inputTitle" required v-model="dataInput"></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="cancel">{{ cancelButton }}</v-btn>
          <v-btn color="blue darken-1" text @click="save"> {{okButton}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "InputDialog",
  props:{
    title:{
      type: String,
      default: 'Input'
    },
    inputTitle:{
      type: String,
      default: 'Enter data here'
    },
    okButton:{
      type: String,
      default: 'Ok'
    },
    cancelButton:{
      type: String,
      default: 'Cancel'
    },
    show:{
      type: Boolean,
      default: false
    },
    multiline:{
      type: Boolean,
      default: false
    },
    dataValue:{
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      dataInput: ''
    }
  },

  watch:{
    dataValue(){
      this.dataInput = this.dataValue
    },
  },


  mounted() {
    this.dataInput = this.dataValue
  },


  methods:{
    save(){
      this.$emit('input-save',{data: this.dataInput});
      this.dataInput = ''
    },
    cancel(){
      this.$emit('input-cancel');
      this.dataInput = ''
    }
  }

}
</script>

<style scoped>

</style>
