<template>
  <v-card
      flat
      :color="color"
      dark
  >
    <v-toolbar flat :color="color">
      <v-icon left small>{{ icon }}</v-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn text :to="to">
        Details
      </v-btn>
    </v-toolbar>
    <v-divider dark></v-divider>
    <v-card-text class="pa-10">
      <v-row>
        <v-col cols="12">
          {{ smallText }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="font-size: 28px">
          <span style="color: white">{{ largeText }}</span>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "DashCard",
  props:{
    title:{
      type: String,
      default: 'DashCard'
    },
    icon:{
      type: String,
      default: 'mdi-circle'
    },
    to:{
      type: String,
      default: '/'
    },
    color:{
      type: String,
      default: '#385F73'
    },
    smallText:{
      type: String,
      default: '0'
    },
    largeText:{
      type: String,
      default: '0'
    },
  }
}
</script>

<style scoped>

</style>
