<template>
<v-row no-gutters>
  <v-col cols="12">
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line

    >{{snackBar.text}}</v-snackbar>
    <v-breadcrumbs
        style="background-color: #f5f5f5"
        :items="bread"
        large
    ></v-breadcrumbs>
  </v-col>
  <v-col cols="12" class="pa-5">
    <v-card>
      <v-row class="px-5">
        <v-col md="auto">
          <v-toolbar-title>Client Fuel Report</v-toolbar-title>
        </v-col>
        <v-col cols="auto" v-for="button in quickButtons" v-bind:key="button.name">
          <v-btn color="primary" x-small @click="setDate(button.name)">{{ button.name }}</v-btn>
        </v-col>
        <v-col cols="auto">
          <v-switch label="All Clients" v-model="allClients" style="margin-top: -4px" @change="getReportData"></v-switch>
        </v-col>
        <v-col cols="auto">
          <v-btn icon small @click="renderPDF" v-if="reportData.rows && reportData.rows.length">
            <v-icon color="red">mdi-file-pdf-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4" lg="2">
            <v-menu
                v-model="menuFrom"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="fromWithTime"
                    label="Starting date for report"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="from"
                  @input="menuFrom = false"
                  @change="customDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="4" lg="2">
            <v-menu
                v-model="menuTo"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="toWithTime"
                    label="Starting date for report"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="to"
                  @input="menuTo = false"
                  @change="customDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col class="mt-5">
            <h2>{{ capDateSpan }}</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
                dense
                :items="reportData.rows"
                :headers="headers"
                no-data-text="Client has no fuel transactions for the date's chosen."
                :loading="loading"
            >
              <template v-slot:item.quantity="{ item }">
                {{ item.quantity.formatCurency() }}
              </template>

              <template v-slot:item.amount="{ item }">
                {{ item.amount.formatCurency() }}
              </template>

              <template v-slot:item.unit_price="{ item }">
                {{ item.unit_price.formatCurency() }}
              </template>

              <template v-slot:item.discount_price="{ item }">
                {{ item.discount_price.formatCurency() }}
              </template>

              <template v-slot:item.discount_ppg="{ item }">
                {{ item.discount_ppg.formatCurency() }}
              </template>

              <template v-slot:item.discount="{ item }">
                {{ item.discount.formatCurency() }}
              </template>

              <template v-slot:item.carrier_fee="{ item }">
                {{ item.carrier_fee.formatCurency() }}
              </template>

              <template v-slot:item.line_total="{ item }">
                {{ item.line_total.formatCurency() }}
              </template>


              <template
                  v-if="reportData.rows && reportData.rows.length"
                  v-slot:footer
              >
                <v-row>
                  <v-col cols="12" align="right" class="pr-7">
                    <span style="font-weight: bold">Amount Billed: {{ reportData.total.formatCurency() }}</span>
                  </v-col>
                </v-row>
              </template>


            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col v-for="item in reportData.items" v-bind:key="item.item">
            <fuel-item :item="item"></fuel-item>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>
</template>

<script>

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import ReportService from '@/services/report.service'
import FuelItem from "@/components/FundingViews/FuelItem";


export default {
name: "ClientFuelReport",
  components: {FuelItem},
  data: () => ({

    reportData: [],

    loading: false,

    dateSpan: 'today',

    quickButtons:[
      {name: 'yesterday'},
      {name: 'last week'},
      {name: 'last month'},
      {name: 'last year'},
      {name: 'today'},
      {name: 'this week'},
      {name: 'this month'},
      {name: 'this year'},
    ],


    headers: [
      {
        text: 'Trans Date',
        sortable: true,
        value: 'trans_date',
        class: 'client-fuel-report',

      },
      {
        text: 'Card',
        sortable: false,
        class: 'client-fuel-report',
        value: 'card_number'
      },
      {
        text: 'Unit',
        sortable: false,
        class: 'client-fuel-report',
        value: 'unit'
      },
      {
        text: 'Driver',
        sortable: false,
        class: 'client-fuel-report',
        value: 'driver_name'
      },
      {
        text: 'Invoice',
        sortable: false,
        class: 'client-fuel-report',
        value: 'invoice_number'
      },
      {
        text: 'Location',
        sortable: false,
        class: 'client-fuel-report',
        value: 'location'
      },
      {
        text: 'State',
        sortable: false,
        class: 'client-fuel-report',
        value: 'state'
      },
      {
        text: 'Item',
        sortable: false,
        class: 'client-fuel-report',
        value: 'item'
      },
      {
        text: 'Qty',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'quantity'
      },
      {
        text: 'Amount',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'amount'
      },
      {
        text: 'Unit Price',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'unit_price'
      },
      {
        text: 'Discount Unit Price',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'discount_price'
      },
      {
        text: 'Discount PPG',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'discount_ppg'
      },
      {
        text: 'Discount',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'discount'
      },
      {
        text: 'Carrier Fee',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'carrier_fee'
      },
      {
        text: 'Line Total',
        sortable: false,
        align: 'right',
        class: 'client-fuel-report',
        value: 'line_total'
      },




    ],


    client: {},
    allClients: false,
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    from: new Date().toISOString().substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
    menuFrom: false,
    menuTo: false,
  }),


  methods:{
    getReportData(){
      this.loading = true;
      let _this = this
      ReportService.clientFuelReport(this.client.id,this.fromWithTime,this.toWithTime,this.allClients)
              .then(function (response) {
                _this.loading = false;
                _this.reportData = response.data;
                if(_this.reportData.items.length){
                  _this.reportData.items.push(_this.totalItem)
                }
              })
              .catch(function (error) {
                console.log(error);
                let msg = _this.$errorMessage(error)
                _this.snackBar.text = "Error loading report data!\n(" + msg + ")"
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
              })
              .then(function () {
                _this.loading = false
              })
    },

    renderPDF(){

      let body = []
      let headers = []
      let widths = []
      for(let i=0;i<this.headers.length;i++){
        headers.push(this.headers[i].text)
        widths.push('auto')
      }
      body.push(headers)

      for(let i=0;i<this.reportData.rows.length;i++){
        let row = []
        for(let ii=0;ii<this.headers.length;ii++){
          if(this.headers[ii].value === 'discount'
                  || this.headers[ii].value === 'line_total'
                  || this.headers[ii].value === 'unit_price'
                  || this.headers[ii].value === 'quantity'
                  || this.headers[ii].value === 'amount'
                  || this.headers[ii].value === 'carrier_fee'
                  || this.headers[ii].value === 'discount_price'
                  || this.headers[ii].value === 'discount_ppg') {
            row.push({text: this.reportData.rows[i][this.headers[ii].value].formatCurency(), alignment:'right'})

          }else if(this.headers[ii].value === 'trans_date'){
            row.push(new Date(this.reportData.rows[i][this.headers[ii].value]).toLocaleDateString())
          }else{
            row.push(this.reportData.rows[i][this.headers[ii].value])
          }
        }
        body.push(row)
      }

      let pbody = []
      pbody.push(['Product','Amount','Quantity','Avg PPU'])

      for(let i=0;i<this.reportData.items.length;i++){
        pbody.push([
          this.reportData.items[i].item,
          {text: this.reportData.items[i].amount.formatCurency(), alignment:'right'},
          {text: this.reportData.items[i].quantity.formatCurency(), alignment:'right'},
          {text: this.reportData.items[i].avg.formatCurency(), alignment:'right'},
        ])
      }

      pbody.push([
        'Fees',
        {text: this.reportData.fees.formatCurency(),alignment:'right'},
        '',
        ''
      ])

      let title = 'Client Fuel Report for '
      if(this.allClients){
        title += ' All Clients'
      }else{
        title += this.client.name
      }


      let docDefinition = {
        pageOrientation: 'landscape',
        info: {
          title: 'Client Fuel Report',
          author: 'Grade A App',
          subject: 'fuel report for current client',
          keywords: 'fuel report client',
        },
        content: [
          //{text: 'Client Fuel Report', style: 'header',fontSize: 15},
          {text: title, style: 'header',fontSize: 15},
          {text: 'Grade A Transport Inc.'},
          {text: 'Start Date: '+this.from},
          {text: 'End Date: '+this.to, margin: [0,0,0,20]},
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: widths,
              body: body
            }
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['*'],
              body: [
                [''],
                [{text: 'Amount Billed: '+this.reportData.total.formatCurency(),alignment:'right'}],
              ]
            },
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['*','*','*','*',],
              body: pbody
            },
          },
        ],
        defaultStyle: {
          fontSize: 8,
          bold: false
        }
      };

      pdfMake.createPdf(docDefinition).download('client-fuel-report');
    },



    customDate(){
      this.dateSpan = 'custom date'
      this.getReportData()
    },

    setDate(when){
      if(when === 'yesterday'){
        this.from = this.$ISODate(this.$yesterday())
        this.to = this.$ISODate(this.$yesterday())
      }else if(when === 'last week'){
        this.from = this.$ISODate(this.$lastMonday())
        this.to = this.$ISODate(this.$lastSunday())
      }else if(when === 'last month'){
        this.from = this.$ISODate(this.$lastMonthStart())
        this.to = this.$ISODate(this.$lastMonthEnd())
      }else if(when === 'last year'){
        this.from = this.$ISODate(this.$startOfLastYear())
        this.to = this.$ISODate(this.$endOfLastYear())
      }else if(when === 'today'){
        this.from = this.$ISODate(new Date())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this week'){
        this.from = this.$ISODate(this.$monday())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this month'){
        this.from = this.$ISODate(this.$startOfMonth())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this year'){
        this.from = this.$ISODate(this.$startOfYear())
        this.to = this.$ISODate(new Date())
      }

      this.dateSpan = when;
      this.getReportData()

    },

  },

  beforeMount() {
    this.client = this.$store.state.client
    if(!this.client){
      this.$router.back();
    }
  },

  mounted() {
    this.getReportData();
  },

  computed: {

    capDateSpan(){
      return this.dateSpan.charAt(0).toUpperCase() + this.dateSpan.slice(1);
    },

    totalItem(){
      let avg = 0;
      if(this.reportData.fuel_quantity_total > 0){
        avg = this.reportData.fuel_amount_total / this.reportData.fuel_quantity_total
      }
      return {
        item: 'TOTAL',
        amount: this.reportData.fuel_amount_total,
        quantity: this.reportData.fuel_quantity_total,
        avg: avg
      }
    },

    fromWithTime(){
      return this.from + ' 00:00:00'
    },

    toWithTime(){
      return this.to + ' 23:59:59'
    },

    bread() {
      return [
        {
          text: 'Clients',
          disabled: false,
          to: '/funding/clients',
        },

        {
          text: this.client.name,
          disabled: false,
          to: '/funding/client',
        },
        {
          text: 'Fuel Report',
          disabled: false,
        },
      ]
    },
  },

}
</script>

<style>

.client-fuel-report{
  color: whitesmoke !important;
  background-color: #0d47a1 !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, .025);
}

</style>
