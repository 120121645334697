<template>
  <v-row no-gutters>
    <v-col cols="12">
      <card-transaction-dialog :title="transactionDialog.title" :show="transactionDialog.show" :transaction="transactionDialog.transaction" @cancel="cancelTransaction" @save="saveTransaction"></card-transaction-dialog>
      <v-snackbar
          v-model="snackBar.show"
          :timeout="snackBar.timeout"
          :color="snackBar.color"
          :page="2"
          top
          multi-line

      >{{snackBar.text}}</v-snackbar>
      <v-breadcrumbs
          style="background-color: #f5f5f5"
          :items="bread"
          large
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12>">

          <v-expansion-panels focusable>
            <v-expansion-panel>
              <v-expansion-panel-header>Bank/Credit Information
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row no-gutters>
                  <v-col cols="4" class="pt-2">
                    <v-card outlined>
                      <v-card-title>Credit Type</v-card-title>
                      <v-card-text>
                        <v-radio-group
                            v-model="client.credit_type"
                            row
                            dense
                            @change="accountChanged"
                        >
                          <v-radio
                              label="Open"
                              :value="1"
                          ></v-radio>
                          <v-radio
                              label="Deposit"
                              :value="2"
                          ></v-radio>
                          <v-radio
                              label="Drawdown"
                              :value="3"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4" class="pl-3 pt-2">
                    <v-card outlined>
                      <v-card-title>Payment Type</v-card-title>
                      <v-card-text>
                        <v-radio-group
                            v-model="client.payment_type"
                            row
                            dense
                            @change="accountChanged"
                        >
                          <v-radio
                              label="ACH"
                              :value="1"
                          ></v-radio>
                          <v-radio
                              label="Settlement"
                              :value="2"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="4" class="pl-3 pt-2">
                    <v-card outlined>
                      <v-card-title>Payment Frequency</v-card-title>
                      <v-card-text>
                        <v-radio-group
                            v-model="client.payment_frequency"
                            row
                            dense
                            @change="accountChanged"
                        >
                          <v-radio
                              label="Daily"
                              :value="1"
                          ></v-radio>
                          <v-radio
                              label="Weekly"
                              :value="2"
                          ></v-radio>
                        </v-radio-group>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12" class="pt-2">
                    <v-card outlined>
                      <v-card-title>Bank</v-card-title>
                      <v-card-text>
                        <v-row no-gutters>
                          <v-col cols="4">
                            <v-text-field v-model="client.bank_name" label="Bank Name" @change="accountChanged"></v-text-field>
                          </v-col>
                          <v-col cols="4" class="pl-3">
                            <v-text-field v-model="client.bank_routing_number" label="Routing Number" @change="accountChanged"></v-text-field>
                          </v-col>
                          <v-col cols="4" class="pl-3">
                            <v-text-field v-model="client.bank_account_number" label="Account Number" @change="accountChanged"></v-text-field>
                          </v-col>
                          <v-col cols="4">
                            <v-text-field  type="number" v-model="client.default_replenish" label="Default Replenish" @change="accountChanged"></v-text-field>
                          </v-col>
                          <v-col cols="4" class="pl-3">
                            <v-text-field  type="number" v-model="client.shutoff_balance" label="Shutoff Balance" @change="accountChanged"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="mt-1">
          <v-toolbar flat>
              <v-btn v-if="client.default_replenish && client.funding_balance.amount/100 < client.default_replenish" color="green" @click="showReplenishAccount">Replenish to: ${{client.default_replenish}}</v-btn>
            <v-btn color="green darken-4" dark class="ml-2" @click="showCreditAccount">Credit Account</v-btn>
            <v-btn color="red darken-4" dark class="ml-2" @click="showDebitAccount">Debit Account</v-btn>
            <v-spacer></v-spacer>
            <v-switch
                dense
                inset
                color="green"
                v-model="client.credit_override"
                :label="client.credit_override?'Credit Override On':'Credit Override Off'"
                @change="accountChanged"
              ></v-switch>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="mt-1">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>Transactions:</v-toolbar-title>
              <v-text-field v-model="search" label="Search" class="ml-5 mt-3"></v-text-field>
              <v-spacer></v-spacer>
              <v-progress-linear
                  v-model="balancePercent"
                  v-if="client.default_replenish"
                  class="mr-5" style="max-width: 300px"
                  height="25"
                  :color="(client.funding_balance.amount/100) <= client.shutoff_balance?'red':'green'"
              >
                <strong class="mt-1">{{balancePercent}}%</strong>
              </v-progress-linear>
              <!---
              <h3>Balance: ${{client.funding_balance.amount/100}}</h3>
              -->
              <animated-number
                :value="client.funding_balance.amount"
                :formatValue="formatBalance"
                :duration="1000"
                ></animated-number>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="headers"
              :items="transactions"
              :search="search"
            >
              <template v-slot:item.debit="{ item }">
                {{item.debit?Number(item.debit/100).formatCurency():''}}
              </template>

              <template v-slot:item.credit="{ item }">
                <v-row no-gutters>
                  <v-col cols="12">
                    {{item.credit?Number(item.credit/100).formatCurency():''}}
                  </v-col>
                </v-row>
              </template>

              <template v-slot:item.balance="{ item }">
                {{Number(item.balance/100).formatCurency()}}
              </template>

            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import ClientService from '@/services/client.service'
import CreditManagerService from '@/services/credit_manager.service'
import CardTransactionDialog from "@/components/FundingViews/cards/CardTransactionDialog";
import AnimatedNumber from "animated-number-vue";


export default {
  name: "CreditManager",
  components: {CardTransactionDialog,AnimatedNumber},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    client: {},

    search: '',
    transactions: [],
    headers: [
    {
      text: 'Description',
      sortable: true,
      value: 'description'
    },
    {
      text: 'Reference',
      sortable: true,
      value: 'reference'
    },
    {
      text: 'Date',
      sortable: true,
      value: 'date'
    },

      {
        text: 'Who',
        sortable: true,
        value: 'who',
        divider: true
      },

    {
      text: 'Debit',
      sortable: true,
      align: 'right',
      value: 'debit',
      divider: true
    },
    {
      text: 'Credit',
      sortable: true,
      align: 'right',
      value: 'credit',
      divider: true,
    },
    {
      text: 'Balance',
      sortable: true,
      align: 'right',
      value: 'balance',
      divider: true

    },
    {text: 'Actions', value: 'actions', sortable: false},
    ],

    transactionDialog:{
      show: false,
      title: 'Transaction',
      type: 'C',
      transaction:{
        description: '',
        reference: '',
        amount: 0,
      },
    },


  }),

  beforeMount() {
    this.client = this.$store.state.client
    if(!this.client){
      this.$router.back();
    }
  },

  mounted() {

    console.log(this.$store.state.permissions)

    const _this = this
    const channel = this.$pusher.subscribe('client-'+this.client.id);
    channel.bind('balance-changed', function (data){
      _this.client.funding_balance = data.balance
      _this.$store.commit('current_client', _this.client)
      _this.getTransactions()
    });

    this.getTransactions()

  },

  beforeDestroy() {
    this.$pusher.unsubscribe('client-'+this.client.id)
  },


  methods:{

    formatBalance(value){
      return `<h3>Balance: $${(value/100).toFixed(2)}</h3>`
    },

    getTransactions(){
      let _this = this
        CreditManagerService.transactions(this.client.id)
            .then(function(response){
              _this.transactions = response.data
              //_this.$nextTick(() => {
              //  _this.page = 2;
              //});
            })
            .catch(function (error) {
              console.log(error);
              _this.snackBar.text = "Error getting transaction!\n(" + error.response.status + ") " + error.response.statusText
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
            .then(function () {
            })
    },

    saveTransaction(transaction){
      let _this = this
      if(this.transactionDialog.type === 'C'){
        CreditManagerService.credit(this.client.id,transaction.amount,transaction.description,transaction.reference)
            .then(function(response){
              console.log(response)
            })
            .catch(function (error) {
              console.log(error);
              _this.snackBar.text = "Error adding transaction!\n(" + error.response.status + ") " + error.response.statusText
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
            .then(function () {
              _this.transactionDialog.show = false
            })
      }else{
        CreditManagerService.debit(this.client.id,transaction.amount,transaction.description,transaction.reference)
            .then(function(response){
              console.log(response)
            })
            .catch(function (error) {
              console.log(error);
              _this.snackBar.text = "Error adding transaction!\n(" + error.response.status + ") " + error.response.statusText
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
            .then(function () {
              _this.transactionDialog.show = false
            })
      }
    },

    cancelTransaction(){
      this.transactionDialog.show=false
    },

    showReplenishAccount(){
      this.transactionDialog.type = 'C'
      this.transactionDialog.title = "Replenish Account"
      this.transactionDialog.transaction.amount =   (this.client.default_replenish - (this.client.funding_balance.amount/100)).toFixed(2)
      this.transactionDialog.show = true
    },

    showCreditAccount(){
      this.transactionDialog.type = 'C'
      this.transactionDialog.title = "Credit Account"
      this.transactionDialog.transaction.amount = 0
      this.transactionDialog.show = true
    },

    showDebitAccount(){
      this.transactionDialog.type = 'D'
      this.transactionDialog.title = "Debit Account"
      this.transactionDialog.transaction.amount = 0
      this.transactionDialog.show = true
    },

    accountChanged(){
      let _this = this
      ClientService.updateClient(this.client)
          .then(function () {
            _this.$store.commit('current_client', _this.client)
          })
          .catch(function (error) {
            console.log(error);
            _this.snackBar.text = "Error adding client!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
            console.log(error.response);
          })
    },
  },

  computed: {

    balancePercent() {
      let bal = this.client.funding_balance.amount/100
      if(bal >= this.client.default_replenish)
        return 100
      if(bal <= 0)
        return 0

      return  Math.round((bal / this.client.default_replenish) * 100)
    },

    bread() {
      return [
        {
          text: 'Clients',
          disabled: false,
          to: '/funding/clients',
        },

        {
          text: this.client.name,
          disabled: false,
          to: '/funding/client',
        },
        {
          text: 'Credit Manager',
          disabled: false,
        },
      ]
    },
  },

}
</script>

<style scoped>
</style>
