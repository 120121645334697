<template>
  <v-dialog
      v-model="show"
      scrollable
      max-width="600px"
      v-if="messages"
  >
    <v-card>
      <v-card-title>Messages</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="max-height: 600px" id="bottom" ref="bottom">
        <v-row v-for="item in messages" v-bind:key="item.id" :justify="!item.direction?'end':'start'">
          <v-col cols="auto" :align="!item.direction?'right':'left'">
            <v-img contain v-for="image in item.media" max-width="400px" :src="image.url" v-bind:key="image.id" class="pb-1"></v-img>
            <p v-if="!item.direction" class="pa-2" style="background-color: green;color:white;max-width:400px;border-radius: 20px 20px 0px 20px">{{message(item.message)}}</p>
            <p v-else class="pa-2" style="background-color: #aaaaaa;color:#000000;max-width:400px;border-radius: 20px 20px 20px 0px">{{message(item.message)}}</p>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-dialog>
</template>

<script>

import * as easings from 'vuetify/lib/services/goto/easing-patterns'

export default {
  name: "ChatDialog",

  props:{
    show:{
      type: Boolean,
      default: false
    },
    messages:{
      type: Array,
    },
  },


  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },
  }),

  mounted() {
  },

  updated() {
    let objDiv = document.getElementById("bottom");
    objDiv.scrollTop = objDiv.scrollHeight;

  },

  methods:{
    message(val){
      let message = val.slice()
      return message.replace(/(?:\r\n|\r|\n)/g, '\n');
    },

  },
  computed:{
    options () {
      return {
        duration: 300,
        offset: 0,
        easing: Object.keys(easings),
      }
    },
  },
  watch: {
    show(){
      this.$nextTick(() => {
        let objDiv = document.getElementById("bottom");
        objDiv.scrollTop = objDiv.scrollHeight;
      })

    },
  },

}
</script>

<style scoped>

</style>
