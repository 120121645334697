<template>
<div>Funding</div>
</template>

<script>
export default {
name: "Funding",
  mounted() {
    this.$router.push('/funding/clients')
  }
}
</script>

<style scoped>

</style>
