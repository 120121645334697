<template>
  <v-navigation-drawer
      v-model="inputValue"
      app
      dark
  >
    <template v-slot:img>
        <v-img
            src="/assets/images/road.jpg"
            height="100%"
            gradient="rgba(0,0,0,0.6),rgba(0,0,0,.9),rgba(50,50,50,0.8),rgba(0,0,0,.9),rgba(0,0,0,1.0)"
        >
        </v-img>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-content>
          <v-row>
            <v-col cols="12" align="center">
              <v-img
                  src="/assets/images/logo.svg"
                  width="150"
                  height="150"
              ></v-img>
            </v-col>
          </v-row>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider dark></v-divider>

    <v-list
    >

      <v-list-item v-if="this.$store.state.isAdmin" link to="/admin" color="red">
        <v-list-item-icon>
          <v-icon>mdi-account-supervisor</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Admin</v-list-item-title>
      </v-list-item>


      <v-list-item link to="/dashboard" color="blue">
        <v-list-item-icon>
          <v-icon>mdi-monitor-dashboard</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Dashboard</v-list-item-title>
      </v-list-item>


      <template v-for="(item) in sections"

      >
        <v-list-item link to="/funding" v-bind:key="item.name">
          <v-list-item-icon>
            <v-icon>{{item.icon}}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{item.title}}</v-list-item-title>
          <v-list-item-action>
            <v-badge v-if="$store.state.messageCounts.unread"  inline color="red" :content="$store.state.messageCounts.unread"></v-badge>
          </v-list-item-action>
        </v-list-item>
      </template>


    </v-list>
    <template v-slot:append>
      <v-list-item link to="/dashboard/about">
        <v-list-item-icon>
          <v-icon>mdi-information</v-icon>
        </v-list-item-icon>
        <v-list-item-title>About</v-list-item-title>
      </v-list-item>
    </template>

  </v-navigation-drawer>
</template>

<script>


import {
  mapMutations,
  mapState
} from 'vuex'


//import PermissionService from '@/services/permissions.service'

export default {
  name: "Drawer",


  data: () => ({
    sections: [
      {name: 'funding', title: 'Funding', icon: 'mdi-cash-multiple'}
    ],
  }),

  computed: {
    ...mapState('app', ['image', 'color']),
    inputValue: {
      get () {
        return this.$store.state.app.drawer
      },
      set (val) {
        this.setDrawer(val)
      }
    },
    items () {
      return this.$t('Layout.View.items')
    }
  },
  methods: {
    ...mapMutations('app', ['setDrawer', 'toggleDrawer']),

    setSection(section){
      this.$store.state.app.section = section;
    },

  },
  mounted() {

  }
}
</script>

<style scoped>

</style>
