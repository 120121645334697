import axios from 'axios'

class CodeService {

    listCodes(driver_id) {
        return axios.post('/clients/driver/codes/list', {driver_id:driver_id})
    }

    requestCode(request){
        let formData = new FormData();
        formData.append("driver_id", request.driver_id)
        formData.append("created_date", request.created_date)
        formData.append("amount", request.amount)
        formData.append("broker", request.broker)
        formData.append("load_number", request.load_number)
        formData.append("document", request.document, 'rateconf') //if this was multiple documents you would use request.document[0]
        console.log(request.document);
        return axios.post('/efs/issuemoneycode', formData,{
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    getECode(code_id) {
        return axios.post('/efs/getmoneycode', {code_id:code_id})
    }

    voidECode(code_id) {
        return axios.post('/efs/voidmoneycode', {code_id:code_id})
    }

    deleteCode(code) {
        return axios.post('funding/code/delete', {id:code.id})
    }

    addCode(code) {
        return axios.post('funding/code/add', {
            driver_id:code.driver_id,
            broker:code.broker,
            load_number:code.load_number,
            amount:code.amount,
        })
    }

    updateCode(code) {
        return axios.post('funding/code/update', {
            id:code.id,
            driver_id:code.driver_id,
            broker:code.broker,
            load_number:code.load_number,
            amount:code.amount,
        })
    }

}

export default new CodeService()
