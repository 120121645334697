// https://vuex.vuejs.org/en/actions.html
import axios from 'axios'

// The login action passes vuex commit helper that we will use to trigger mutations.
export default {

    login ({ commit }, userData) {

        return new Promise((resolve, reject) => {
            commit('auth_request')
            axios.post('/auth/login', { email: userData.email, password: userData.password })
                .then(response => {
                    if(!response.data.user.change_password){
                        console.log(response.data)
                        let payload = {
                            token: response.data.access_token,
                            user: response.data.user,
                            isAdmin: response.data.isAdmin,
                            expires: new Date().getTime() + (response.data.expires_in * 1000),
                            permissions: response.data.permissions
                        }
                        //localStorage.setItem('token', payload.token)
                        //localStorage.setItem('userName', payload.user.name)
                        //localStorage.setItem('userEmail', payload.user.email)
                        //localStorage.setItem('uid', payload.user.id)
                        //localStorage.setItem('isAdmin', payload.isAdmin)
                        //localStorage.setItem('tokenExpires', payload.expires)
                        axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token
                        commit('auth_success', payload)
                    }
                    resolve(response)
                })
                .catch(err => {
                    console.log('login error')
                    commit('auth_error')
                    //localStorage.removeItem('token')
                    //localStorage.removeItem('userName')
                    //localStorage.removeItem('userEmail')
                    //localStorage.removeItem('uid')
                    //localStorage.removeItem('isAdmin')
                    //localStorage.removeItem('client')
                    //localStorage.removeItem('driver')
                    //localStorage.removeItem('tokenExpires')
                    //localStorage.removeItem('client')
                    //localStorage.removeItem('driver')
                    //localStorage.removeItem('card')


                    reject(err)
                })
        })
    },

    logout ({ commit }) {
        // eslint-disable-next-line no-unused-vars
        return new Promise((resolve, reject) => {
            commit('logout')
            //localStorage.removeItem('token')
            //localStorage.removeItem('userName')
            //localStorage.removeItem('userEmail')
            //localStorage.removeItem('uid')
            //localStorage.removeItem('isAdmin')
            //localStorage.removeItem('client');
            //localStorage.removeItem('driver');
            //localStorage.removeItem('tokenExpires')
            //localStorage.removeItem('client')
            //localStorage.removeItem('driver')
            //localStorage.removeItem('card')

            delete axios.defaults.headers.common['Authorization']
            resolve()
        })
    },
    refreshtoken ({ commit }) {
        axios.post('/auth/refresh')
            .then(response => {
                let payload = {
                    token: response.data.access_token,
                    user: response.data.user,
                    isAdmin: response.data.isAdmin,
                    expires: new Date().getTime() + (response.data.expires_in * 1000)
                }
                //localStorage.setItem('token', payload.token)
                //localStorage.setItem('userName', payload.user.name)
                //localStorage.setItem('userEmail', payload.user.email)
                //localStorage.setItem('uid', payload.user.id)
                //localStorage.setItem('isAdmin', payload.isAdmin)
                //localStorage.setItem('tokenExpires', payload.expires)

                axios.defaults.headers.common['Authorization'] = 'Bearer ' + payload.token

                commit('auth_success', payload)
            })
            .catch(error => {
                console.log('refresh token error')
                commit('logout')
                //localStorage.removeItem('token')
                //localStorage.removeItem('userName')
                //localStorage.removeItem('userEmail')
                //localStorage.removeItem('uid')
                //localStorage.removeItem('isAdmin')
                //localStorage.removeItem('client');
                //localStorage.removeItem('driver');
                //localStorage.removeItem('tokenExpires')
                //localStorage.removeItem('client')
                //localStorage.removeItem('driver')
                //localStorage.removeItem('card')


                console.log(error)
            })
    },
    getTableList ({ commit }, tableName) {
        this.$http.get(`/${tableName}`)
            .then(response => {
                console.log(response)
                let tableList = response.data.Keys
                commit('setTableList', { tableList })
            })
            .catch(error => console.log(error))
    },
   // updateTableItem ({ commit }, tableData) {
   //     return new Promise((resolve, reject) => {
   //         let httpmethod = tableData.method
   //         axios({ url: `/${tableData.endpoint}`, method: httpmethod, data: tableData.tableItem })
   //             .then(response => {
   //                 resolve(response)
   //             })
   //             .catch(error => {
   //                 console.log(error)
   //                 reject(error)
   //             })
   //     })
   // }

    // autoRefreshToken ({ commit }) {
    //   return new Promise((resolve, reject) => {
    //     setInterval(function () {
    //       // code goes here that will be run every 20 minutes.
    //       axios.get('/refresh')
    //         .then(response => {
    //           const token = response.data.access_token
    //           localStorage.setItem('token', token)
    //           axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    //           commit('auth_success', { token })
    //           resolve(response)
    //         })
    //         .catch(error => {
    //           console.log('refresh token error')
    //           console.log(error)
    //           reject(error)
    //         })
    //     }, 1200000)
    //   }
    //   )
    // },

}
