import axios from 'axios'

class ClientService {

    transactionLedger(card_id){
        return axios.post('/clients/driver/cards/transaction/ledger', {card_id: card_id})
    }
    saveTransaction(transaction) {
        return axios.post('/clients/driver/cards/transaction/save', transaction)
    }

    getEFSCard(card_id) {
        return axios.post('/efs/getcard', {card_id:card_id})
    }
    moveCard(card_id,driver_id){
        return axios.post('clients/driver/cards/move', {card_id:card_id, driver_id:driver_id})
    }

    saveCard(card){
        return axios.post('clients/driver/cards/save', card)
    }

    getCards(driver_id){
        return axios.post('clients/driver/cards', {driver_id:driver_id})
    }

    sendDriverSMS(driver_id,message) {
        return axios.post('clients/driver/sms', {driver_id:driver_id,message: message})
    }

    driverSelectList(client_id) {
        return axios.post('clients/driver/selectlist', {client_id:client_id})
    }

    driverList(client_id) {
        return axios.post('clients/driver/list', {client_id:client_id})
    }

    deleteDriver(driver){
        return axios.post('clients/driver/delete', {
            id:driver.id
        });
    }

    driverMessages(driver_id){
        return axios.post('clients/driver/messages', {
            driver_id:driver_id
        });
    }

    addDriver(driver){
        return axios.post('clients/driver/add', {
            client_id:driver.client_id,
            first_name:driver.first_name,
            last_name:driver.last_name,
            phone:driver.phone,
            email:driver.email,
            efs_card: driver.efs_card,
            mobile_access: driver.mobile_access,
            mobile_password: driver.mobile_password,
            active: driver.active
        });
    }
/*
    updateDriver(driver){
        return axios.post('clients/driver/update', {
            id:driver.id,
            client_id:driver.client_id,
            first_name:driver.first_name,
            last_name:driver.last_name,
            phone:driver.phone,
            email:driver.email,
            efs_card: driver.efs_card,
        });
    }
 */
    updateDriver(driver){
        return axios.post('clients/driver/update', driver);
    }

    clientList() {
        return axios.post('clients/list', {})
    }

    addClient(client){
        return axios.post('clients/add', client);
    }

    updateClient(client){
        return axios.post('clients/update', client);
    }

    deleteClient(client){
        return axios.post('clients/delete', {
            id:client.id
        });
    }
}
export default new ClientService();

