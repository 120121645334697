import axios from 'axios'

class PolicyService {

    descriptions() {
        return axios.post('/efs/policy/descriptions', {})
    }
    policy(policy_number) {
        return axios.post('/efs/policy/policy', {policy_number: policy_number})
    }
    cardAndPolicy(card_id) {
        return axios.post('/efs/policy/cardandpolicy', {card_id: card_id})
    }
    updatePolicy(policy) {
        return axios.post('/efs/policy/updatepolicy', {policy: policy})
    }

}

export default new PolicyService()
