<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <v-row no-gutters v-if="info.validationType === 'REPORT_ONLY'">
            <v-col cols="12" md="6">
              <v-text-field
                  label="Validation"
                  value="Report Only"
                  :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                label="Report Value"
                v-model="info.reportValue"
                @change="fireChange"
                :disabled="disabled"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row no-gutters v-else-if="info.validationType === 'EXACT_MATCH'">
            <v-col cols="12" md="6">
              <v-text-field
                  label="Validation"
                  value="Exact Match"
                  :disabled="true"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                  label="Match Value"
                  v-model="info.matchValue"
                  :disabled="disabled"
                  @change="fireChange"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "InfoPrompt",
  props: {
    info: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },
  }),

  methods: {

    fireChange() {
      this.$emit('change')
    },
  }

  }
</script>

<style scoped>

</style>
