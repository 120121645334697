import axios from 'axios'

class DashboardService {

    stats() {
        return axios.post('dashboard/stats', {});
    }

}

export default new DashboardService();

