<template>
  <v-row v-if="client" no-gutters>
    <v-dialog v-model="voidMoneyCodeDialog" persistent max-width="600px">
      <v-card :min-height="200">
        <v-card-text>
          <v-overlay :absolute="true" :opacity="0.7">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-h6 text-center" cols="12">
                Voiding EFS Money Code
              </v-col>
              <v-col cols="10">
                <v-progress-linear
                  color="green"
                  indeterminate
                  rounded
                  height="16"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-overlay>
          <v-row v-if="ecode"> </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="viewMoneyCodeDialog"
      persistent
      max-width="600px"
      min-height="200px"
    >
      <v-card :min-height="eCodeMinHeight" min-width="300">
        <v-card-title v-if="ecode">View EFS Money Code</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table v-if="ecode" dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Name</th>
                  <th class="text-left">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(value, name) in ecode" :key="name">
                  <td>{{ name }}</td>
                  <td>{{ value }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-overlay :absolute="true" :opacity="0.7" :value="eCodeRequest">
            <v-row class="fill-height" align-content="center" justify="center">
              <v-col class="text-h6 text-center" cols="12">
                Getting EFS Money Code
              </v-col>
              <v-col cols="10">
                <v-progress-linear
                  color="green"
                  indeterminate
                  rounded
                  height="16"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </v-overlay>
          <v-row v-if="ecode"> </v-row>
        </v-card-text>
        <v-card-actions v-if="ecode">
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="viewMoneyCodeDialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showDialog" persistent max-width="1000px">
      <v-card>
        <v-card-title>
          <v-icon large color="green" class="mr-2"
            >mdi-currency-usd-circle</v-icon
          >
          <span class="headline">New Money Code</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="codeform" v-model="codeValid" lazy-validation>
            <v-row>
              <v-col cols="12" class="col-lg-4">
                <v-text-field
                  label="Broker *"
                  placeholder="XYZ Trucking"
                  v-model="code.broker"
                  :counter="100"
                  :rules="codeRules.broker"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="col-lg-4">
                <v-text-field
                  label="Load Number *"
                  placeholder="B711-918"
                  v-model="code.load_number"
                  :counter="100"
                  :rules="codeRules.load"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="col-lg-4">
                <v-text-field
                  label="Amount *"
                  placeholder="100.00"
                  v-model="code.amount"
                  type="number"
                  :rules="codeRules.amount"
                  required
                ></v-text-field>
              </v-col>
              <v-col
                cols="12"
                v-cloak
                @drop.prevent="addDropFile"
                @dragover.prevent
              >
                <v-file-input
                  v-model="code.document"
                  small-chips
                  show-size
                  counter
                  label="Rate Conformation"
                  :rules="codeRules.document"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="cancel"> Cancel </v-btn>
          <v-btn color="blue darken-1" text @click="request"> request </v-btn>
        </v-card-actions>
        <v-overlay :absolute="true" :opacity="0.7" :value="requested">
          <v-row class="fill-height" align-content="center" justify="center">
            <v-col class="text-h6 text-center" cols="12">
              Requesting Money Code
            </v-col>
            <v-col cols="10">
              <v-progress-linear
                color="green"
                indeterminate
                rounded
                height="16"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-overlay>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
        >{{ snackBar.text }}</v-snackbar
      >
      <v-breadcrumbs
        style="background-color: #f5f5f5"
        :items="bread"
        large
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12" class="col-lg-6">
      <v-card class="ma-5" elevation="2" height="243">
        <v-toolbar flat>
          <v-toolbar-title>{{
            driver.first_name + " " + driver.last_name
          }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="requestCode" outlined>
            <v-icon left color="green" title="Request new money code."
              >mdi-currency-usd-circle</v-icon
            >
            money code
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="col-lg-6">
              <span>
                <v-icon>mdi-phone</v-icon>
                {{ this.$formatPhoneNumber(driver.phone) }}
                <br />
              </span>
              <span v-if="driver.email">
                <v-icon>mdi-email</v-icon>
                {{ driver.email }}
                <br />
              </span>
              <span v-if="driver.efs_card">
                <v-icon>mdi-credit-card</v-icon>
                {{ driver.efs_card }}
                <br />
              </span>
              <span v-if="driver.truck_number">
                <v-icon>mdi-truck</v-icon>
                {{ driver.truck_number }}
              </span>
              <br />
              <span v-if="driver.fuel_report">
                <v-icon>mdi-file-document-multiple</v-icon>
                {{ driver.fuel_report }}
              </span>
            <span v-if="driver.email">
                <div v-if="driver.mobile_access">
                <v-icon color="green" class="mt-1">mdi-cellphone</v-icon>
                Mobile access is enabled.
                </div>
                <div v-else>
                <v-icon color="red" class="mt-1">mdi-cellphone</v-icon>
                Mobile access is disabled.
                </div>
              </span>
            </v-col>
            <v-col cols="12" class="col-lg-6">
              <v-row no-gutters>
                <v-col cols="6" class="text-lg-right font-weight-bold">
                  Money Code's Issued:
                </v-col>
                <v-col cols="6" class="text-right">
                  {{ codes.length }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-lg-right font-weight-bold">
                  Total Amount Issued:
                </v-col>
                <v-col cols="6" class="text-right">
                  {{
                    totalAmt.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    })
                  }}
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="6" class="text-lg-right font-weight-bold">
                  Total Fee's:
                </v-col>
                <v-col cols="6" class="text-right">
                  {{
                    totalFee.toLocaleString(undefined, {
                      style: "currency",
                      currency: "USD",
                    })
                  }}
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" class="col-lg-6">
      <v-row no-gutters>
        <v-col cols="12" class="text-right">
          <v-btn color="primary" class="mt-5 mr-2" to="driverfuelreport">
            <v-icon left>mdi-file-document-multiple</v-icon>
            Fuel Report
          </v-btn>
          <v-btn color="primary" class="mt-5 mr-2" to="cardmanager">
            <v-icon left>mdi-credit-card</v-icon>
            Manage Cards
          </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card v-if="messageData && messageData.messages">
            <v-card-title>
              <v-btn
                icon
                outlined
                class="mr-5"
                @click="toggleMessages"
                titlt="Expand messages"
              >
                <v-badge
                  left
                  color="red"
                  :content="messageCount()"
                  v-if="chatHeight === this.chatHeightClosed && messageCount()"
                >
                </v-badge>
                <v-icon v-if="chatHeight === this.chatHeightClosed"
                  >mdi-chevron-left</v-icon
                >
                <v-icon v-else>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                icon
                outlined
                class="mr-5"
                @click="messageBottom"
                title="Goto the bottom"
              >
                <v-icon>mdi-chevron-triple-down</v-icon>
              </v-btn>
              Messages
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text
              :style="'max-height:' + chatHeight + 'px;overflow: auto'"
              id="bottom"
              ref="bottom"
            >
              <v-row
                v-for="item in messageData.messages"
                v-bind:key="item.id"
                :justify="!item.direction ? 'end' : 'start'"
              >
                <v-col cols="auto" :align="!item.direction ? 'right' : 'left'">
                  <div style="font-size: 10px">
                    {{ messageDate(item.created_at) }}
                  </div>
                  <v-btn
                    icon
                    v-for="image in item.media"
                    v-bind:key="image.id"
                    :href="image.url"
                    target="_blank"
                  >
                    <v-icon class="pb-3">mdi-image</v-icon>
                  </v-btn>
                  <money-code-card
                    v-if="messageREX(item.message, 1) === 'Money Transfer Code'"
                    :title="messageREX(item.message, 4)"
                    :code="messageREX(item.message, 2)"
                    :amount="messageREX(item.message, 3)"
                    :name="messageREX(item.message, 5)"
                    :broker="messageREX(item.message, 6)"
                    :load="messageREX(item.message, 7)"
                    :date="item.created_at"
                  >
                  </money-code-card>
                  <div v-else>
                    <p
                      v-if="!item.direction"
                      class="pa-2"
                      style="
                        background-color: green;
                        color: white;
                        max-width: 400px;
                        border-radius: 20px 20px 0px 20px;
                      "
                    >
                      {{ message(item.message) }}
                    </p>
                    <p
                      v-else
                      class="pa-2"
                      style="
                        background-color: #aaaaaa;
                        color: #000000;
                        max-width: 400px;
                        border-radius: 20px 20px 20px 0px;
                      "
                    >
                      {{ message(item.message) }}
                    </p>
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-row no-gutters>
                <v-col col="12">
                  <v-text-field
                    v-model="newSMS"
                    filled
                    dense
                    rounded
                    placeholder="Type a message"
                    @keydown.enter.prevent="sendSMS"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12" class="ma-2">
      <v-data-table
        :headers="headers"
        :items="codes"
        no-data-text="This driver has no money code's"
        :sort-by="['created_date', 'amount']"
        :sort-desc="[1, 0]"
        multi-sort
        :search="search"
        :loading="loading"
      >
        <template v-slot:item.issued_code="{ item }">
          {{ item.issued_code }}
          <v-chip v-if="item.voided" x-small color="yellow">Voided</v-chip>
        </template>

        <template v-slot:item.amount="{ item }">
          {{
            item.amount.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })
          }}
        </template>

        <template v-slot:item.calculated_fee="{ item }">
          {{
            item.calculated_fee.toLocaleString(undefined, {
              style: "currency",
              currency: "USD",
            })
          }}
        </template>

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Money Code's</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="viewDocument(item)"
            title="View rate conformation document."
          >
            mdi-file-document
          </v-icon>
          <v-icon
            small
            class="mr-2"
            @click="viewECode(item)"
            title="View money code information."
          >
            mdi-eye
          </v-icon>

          <v-icon v-if="item.voided" disabled small class="mr-2">
            mdi-stop-circle
          </v-icon>

          <v-icon
            v-else
            small
            class="mr-2"
            @click="voidCode(item)"
            title="Void money code."
          >
            mdi-stop-circle
          </v-icon>
          <v-icon
            :v-if="$store.state.isAdmin"
            small
            @click="deleteCode(item)"
            title="Delete money code."
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import MessageService from "@/services/message.service";
import CodeService from "@/services/code.service";
import ClientService from "@/services/client.service";
import MoneyCodeCard from "@/components/FundingViews/MoneyCodeCard";

export default {
  name: "GaDriver",
  components: { MoneyCodeCard },
  data: () => ({
    loading: false,

    voidMoneyCodeDialog: false,

    viewMoneyCodeDialog: false,
    eCodeRequest: false,
    ecode: null,
    eCodeMinHeight: 200,

    showDialog: false,
    requested: false,
    codeValid: true,
    code: null,
    chatHeightClosed: 35,
    chatHeightOpen: 800,
    chatHeight: 35,
    unread: 0,
    newSMS: "",

    defaultCode: {
      driver_id: 0,
      broker: "",
      load_number: "",
      amount: 0.0,
      document: null,
      voided: false,
    },

    client: null,
    driver: null,
    snackBar: {
      show: false,
      text: "",
      timeout: 5000,
      color: "green",
    },

    messageData: null,
    search: null,
    codes: [],
    totalAmt: 0,
    totalFee: 0,
    headers: [
      {
        text: "Code",
        sortable: true,
        value: "issued_code",
      },
      {
        text: "Amount",
        sortable: true,
        value: "amount",
        align: "end",
      },
      {
        text: "Issued",
        sortable: true,
        value: "created_date",
      },
      {
        text: "Broker",
        sortable: true,
        value: "broker_name",
      },
      {
        text: "Load Number",
        sortable: true,
        value: "load_number",
      },
      {
        text: "Fee",
        sortable: true,
        value: "calculated_fee",
        align: "end",
      },
      { text: "Actions", value: "actions", sortable: false, align: "center" },
    ],

    codeRules: {
      broker: [
        (v) => !!v || "Please provide a broker name!",
        (v) =>
          (v && v.length <= 100) ||
          "Broker name must be 100 characters or less",
      ],
      load: [
        (v) => !!v || "Please provide a load number!",
        (v) =>
          (v && v.length <= 100) ||
          "Load number must be 100 characters or less",
      ],
      amount: [
        (v) => !!v || "Please provide an amount!",
        (v) => (v && Number(v) <= 2500) || "Amount must be less then 2500.00",
      ],
      document: [
        (v) => !!v || "You must attach a load document!",
        (v) =>
          (v && v.size < 1024 * 1024 * 10) ||
          "Document must me less 10 megs or less.",
      ],
    },
  }),

  beforeMount() {
    this.client = this.$store.state.client;
    this.driver = this.$store.state.driver;
    this.code = Object.assign({}, this.defaultCode);
  },

  mounted() {
    if (!this.driver) {
      this.$router.back();
    }
    this.loadCodes();
    this.getMessages();

    const _this = this;
    const channel = this.$pusher.subscribe("driver-message-" + this.driver.id);
    channel.bind("received", function () {
      _this.getMessages();
      if (_this.chatHeight === !this.chatHeightClosed) {
        MessageService.clearUnreadForDriver(_this.driver.id);
      }
    });
  },

  beforeDestroy() {
    this.$pusher.unsubscribe("driver-message-" + this.driver.id);
  },

  methods: {
    messageCount() {
      const counts = this.$store.state.messageCounts;
      for (let i = 0; i < counts.drivers.length; i++) {
        if (counts.drivers[i].id === this.driver.id) {
          return counts.drivers[i].count;
        }
      }
      return 0;
    },

    toggleMessages() {
      if (this.chatHeight === this.chatHeightClosed) {
        this.chatHeight = this.chatHeightOpen;
      } else {
        this.chatHeight = this.chatHeightClosed;
      }
      if (this.messageCount()) {
        MessageService.clearUnreadForDriver(this.driver.id);
      }
    },

    addDropFile(e) {
      this.code.document = e.dataTransfer.files[0];
    },

    sendSMS() {
      const _this = this;
      ClientService.sendDriverSMS(this.driver.id, this.newSMS)
        .then(function () {
          _this.getMessages();
          _this.newSMS = "";
        })
        .catch(function (error) {
          console.log(error.response);
          if (error.response.data) {
            _this.snackBar.text = error.response.data.errors;
          } else {
            _this.snackBar.text =
              "Error sending message!\n(" +
              error.response.status +
              ") " +
              error.response.statusText;
          }
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
        });
    },

    cancelSMS() {
      this.showSMSInput = false;
    },

    voidCode(code) {
      this.$confirm(
        "Are you sure you want to void money code<br/>" +
          code.issued_code +
          " for " +
          code.broker_name +
          " / " +
          code.load_number +
          '<br/><br/><span class="pl-14 font-weight-bold">This action can not be undone!</span>',
        { title: "Void Money Code" }
      ).then((res) => {
        if (res === true) {
          this.voidMoneyCodeDialog = true;
          let _this = this;
          CodeService.voidECode(code.id)
            .then(function (response) {
              _this.voidMoneyCodeDialog = false;
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;
              _this.loadCodes();
            })
            .catch(function (error) {
              if (error.response.data) {
                _this.snackBar.text = error.response.data.errors;
              } else {
                _this.snackBar.text =
                  "Error requesting money code!\n(" +
                  error.response.status +
                  ") " +
                  error.response.statusText;
              }
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
              console.log(error.response);
            });
        }
      });
    },

    viewECode(code) {
      this.ecode = null;
      this.eCodeRequest = true;
      this.viewMoneyCodeDialog = true;
      this.eCodeMinHeight = 200;
      let _this = this;

      CodeService.getECode(code.id)
        .then((response) => {
          _this.eCodeRequest = false;
          this.eCodeMinHeight = 0;
          _this.ecode = response.data.ecode;
        })
        .catch(function (error) {
          _this.eCodeRequest = false;
          _this.viewMoneyCodeDialog = false;
          _this.snackBar.text =
            "Error getting money code!\n(" +
            error.response.status +
            ") " +
            error.response.statusText;
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
          console.log(error.response);
        });
    },

    viewDocument(code) {
      window.open(code.path);
    },

    requestCode() {
      this.code = Object.assign({}, this.defaultCode);
      this.showDialog = true;
    },

    loadCodes() {
      this.loading = true;
      let _this = this;
      CodeService.listCodes(this.driver.id)
        .then(function (response) {
          _this.codes = response.data.codes;
          _this.totalAmt = response.data.total_amt;
          _this.totalFee = response.data.total_fee;
        })
        .catch(function (error) {
          console.log(error);
          let msg = _this.$errorMessage(error);
          _this.snackBar.text = "Error loading codes!\n(" + msg + ")";
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
        })
        .then(function () {
          _this.loading = false;
        });
    },

    request() {
      if (this.$refs.codeform.validate() === true) {
        this.requested = true;
        this.code.driver_id = this.driver.id;
        this.code.created_date = moment().format("YYYY-MM-DD HH:mm:ss");

        let _this = this;
        CodeService.requestCode(this.code)
          .then(function (response) {
            _this.requested = false;
            _this.showDialog = false;
            _this.snackBar.text = response.data.message;
            _this.snackBar.color = "green";
            _this.snackBar.show = true;
            _this.loadCodes();
          })
          .catch(function (error) {
            _this.requested = false;
            if (error.response.data.message) {
              _this.snackBar.text = error.response.data.message;
            } else if (error.response.data.errors) {
              _this.snackBar.text = error.response.data.errors;
            } else {
              _this.snackBar.text =
                "Error requesting money code!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
            }
            _this.snackBar.color = "red";
            _this.snackBar.show = true;
            console.log(error.response);
          });
      }
    },

    cancel() {
      this.$refs.codeform.resetValidation();
      this.showDialog = false;
    },

    messageDate(date) {
      return moment(date).format("LLLL");
    },

    message(val) {
      if (val === null) return "";
      let message = val.slice();
      return message.replace(/(?:\r\n|\r|\n)/g, "\n");
    },

    messageBottom() {
      this.$nextTick(() => {
        let objDiv = document.getElementById("bottom");
        objDiv.scrollTop = objDiv.scrollHeight;
      });
    },

    getMessages() {
      let _this = this;
      ClientService.driverMessages(this.driver.id)
        .then(function (response) {
          const oldUnread =
            _this.messageData === null ? 0 : _this.messageData.unread;
          _this.messageData = response.data;
          //console.log(response.data)
          if (_this.messageData.unread !== oldUnread) {
            _this.$nextTick(() => {
              let objDiv = document.getElementById("bottom");
              objDiv.scrollTop = objDiv.scrollHeight;
            });
          }
        })
        .catch(function (error) {
          console.log(error);
          let msg = _this.$errorMessage(error);
          _this.snackBar.text = "Error loading messages!\n(" + msg + ")";
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
        })
        .then(function () {
          _this.loading = false;
        });
    },

    messageREX(m, index) {
      let res = undefined;

      if (m && m.substring(1, 20) === "Money Transfer Code") {
        res = m.match(
          /^\n(Money Transfer Code)\n\nCode:\s(.+)\nAmount:\s(.+)\nCompany:\s(.+)\nDriver:\s(.+)\nBroker:\s(.+)\nLoad:\s(.+)\n\n$/i
        );
        if (!res) return "error!";
      } else {
        return m;
      }
      return res[index];
    },
  },

  computed: {
    clientPhone() {
      if (this.client.phone.length === 10) {
        let npa = this.client.phone.substr(0, 3);
        let nxx = this.client.phone.substr(3, 3);
        let num = this.client.phone.substr(6, 4);
        return "(" + npa + ") " + nxx + "-" + num;
      }
      return this.client.phone;
    },

    bread() {
      return [
        {
          text: "Clients",
          disabled: false,
          to: "/funding/clients",
        },
        {
          text: this.client.name,
          disabled: false,
          to: "/funding/client",
        },

        {
          text: this.driver.first_name + " " + this.driver.last_name,
          disabled: false,
        },
      ];
    },
  },

  watch: {
    showDialog() {
      if (this.$refs.codeform) {
        this.$refs.codeform.resetValidation();
      }
    },
  },

  // jhgyy644434rtyGFFDrt443rtGR43ertZ566
};
</script>

<style scoped>
</style>
