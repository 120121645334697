<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <v-toolbar flat>
            <v-toolbar-title>Funding Reports</v-toolbar-title>
          </v-toolbar>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col col="12" md="3">
          <v-card>
            <v-card-title>Fuel</v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-btn text to="/funding/reports/all">all</v-btn>
              <v-btn text to="/funding/reports/bystate" :disabled="true">by state</v-btn>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" v-if="$route.name === 'ByState'">
          <fuel-by-state></fuel-by-state>
        </v-col>
        <v-col cols="12"  v-else-if="$route.name === 'FuelAll'">
          <fuel-all></fuel-all>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import FuelByState from "@/components/FundingViews/reports/FuelByState";
import FuelAll from "@/components/FundingViews/reports/FuelAll";
export default {
  name: "FundingReports",
  components: {FuelAll, FuelByState},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },
  }),

}
</script>

<style scoped>

</style>
