export default {
    install (Vue, options) {
        // 1. add global method or property
        Vue.myGlobalMethod = function () {
            console.log(options);
            // some logic ...
        }

        // 2. add a global asset
        //  Vue.directive('my-directive', {
        //    bind (el, binding, vnode, oldVnode) {
        //        // some logic ...
        //    }
        //})

        // 3. inject some component options
        //Vue.mixin({
        //    created: function () {
        //        // some logic ...
        //    }
        //})

        // 4. add an instance method
        Vue.prototype.$formatPhoneNumber = function (phone) {
            if (phone.length === 10) {
                let npa = phone.substr(0, 3);
                let nxx = phone.substr(3, 3);
                let num = phone.substr(6, 4);
                return '(' + npa + ') ' + nxx + '-' + num;
            }
            return phone
        }

        Vue.prototype.$yesterday = function () {
            let today = new Date();
            return new Date(today.setDate(today.getDate() - 1))
        }

        Vue.prototype.$lastWeek = function () {
            let today = new Date();
            return new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
        }

        Vue.prototype.$lastSunday = function () {
            let today = new Date();
            return new Date(today.setDate(today.getDate() - today.getDay()))
        }

        Vue.prototype.$lastMonday = function (){
            let  beforeOneWeek = new Date(new Date().getTime() - 60 * 60 * 24 * 7 * 1000)
                , day = beforeOneWeek.getDay()
                , diffToMonday = beforeOneWeek.getDate() - day + (day === 0 ? -6 : 1)
                return new Date(beforeOneWeek.setDate(diffToMonday))
        }

        Vue.prototype.$monday = function (){
            let d = new Date()
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        }


        Vue.prototype.$lastMonthStart = function (){
            let now = new Date()
            return  new Date(now.getFullYear() - (now.getMonth() > 0 ? 0 : 1), (now.getMonth() - 1 + 12) % 12, 1)
        }

        Vue.prototype.$lastMonthEnd = function (){
            let now = new Date()
            return  new Date(now.getFullYear(), now.getMonth(), 0)
        }

        Vue.prototype.$startOfLastYear = function (){
            let year = new Date().getFullYear();
            return new Date(year-1,0,1)
        }

        Vue.prototype.$startOfYear = function (){
            let year = new Date().getFullYear();
            return new Date(year,0,1)
        }

        Vue.prototype.$endOfLastYear = function (){
            let year = new Date().getFullYear();
            return new Date(year-1,11,31)
        }

        Vue.prototype.$startOfMonth = function (){
            let date = new Date();
            return new Date(date.getFullYear(), date.getMonth(), 1);
        }


        Vue.prototype.$ISODate = function (date){
            return date.toISOString().substr(0, 10)
        }

        Vue.prototype.$errorMessage = function (error){
            let msg = 'Unknown'
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                msg = '#' + error.response.status + ' ' + error.response.statusText + ' => ' + error.response.data.error
                //msg = error.response.data
                //error.response.status + error.response.headers
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                msg = error.request
            } else {
                // Something happened in setting up the request that triggered an Error
                msg = 'Error' + error.message
            }
            console.log(error.config)
            return msg
        }
    }
}

