<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12">
      <v-breadcrumbs
          style="background-color: #f5f5f5"
          :items="bread"
          large
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <v-toolbar flat>
            <v-toolbar-title>Card Transaction Ledger</v-toolbar-title>
          </v-toolbar>
          <v-data-table
              :headers="headers"
              :items="ledger"
              :loading="loading"
          >
            <template v-slot:item.debit="{ item }">
              {{item.debit?Number(item.debit).formatCurency():''}}
            </template>

            <template v-slot:item.credit="{ item }">
              {{item.credit?Number(item.credit).formatCurency():''}}
            </template>

            <template v-slot:item.balance="{ item }">
              {{Number(item.balance).formatCurency()}}
            </template>
          </v-data-table>

        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import ClientService from '@/services/client.service'


export default {
  name: "CardTransactionLedger",

  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    client: null,
    driver: null,
    card: [],
    loading: false,
    ledger: [],

    headers: [
      {
        text: 'Description',
        sortable: true,
        value: 'description'
      },
      {
        text: 'Reference',
        sortable: true,
        value: 'reference'
      },
      {
        text: 'Date',
        sortable: true,
        value: 'date'
      },

      {
        text: 'Debit',
        sortable: true,
        align: 'right',
        value: 'debit'
      },
      {
        text: 'Credit',
        sortable: true,
        align: 'right',
        value: 'credit'
      },
      {
        text: 'Balance',
        sortable: true,
        align: 'right',
        value: 'balance'
      },
      {text: 'Actions', value: 'actions', sortable: false},
    ],


  }),

  beforeMount() {
    this.client = this.$store.state.client
    this.driver = this.$store.state.driver
    this.card = this.$store.state.card
    if(!this.client || !this.driver || !this.card){
      this.$router.back();
    }
  },

  mounted() {
    this.getLedger()
  },

  beforeDestroy() {
    //localStorage.removeItem('card')
  },

  methods:{
    getLedger() {
      let _this = this
      this.loading = true
      ClientService.transactionLedger(this.card.id)
          .then(function (response) {
            _this.ledger = response.data;
            console.log(_this.ledger);
          })
          .catch(function (error) {
            console.log(error);
            let msg = _this.$errorMessage(error)
            _this.snackBar.text = "Error loading ledger!\n(" + msg + ")"
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
          })
          .then(function () {
            _this.loading = false
          })
    },

  },

  computed: {

    bread() {
      return [
        {
          text: 'Clients',
          disabled: false,
          to: '/funding/clients',
        },
        {
          text: this.client.name,
          disabled: false,
          to: '/funding/client',
        },

        {
          text: this.driver.first_name+' '+this.driver.last_name,
          disabled: false,
          to: '/funding/driver',
        },
        {
          text: "Card Manager",
          disabled: false,
          to: '/funding/cardmanager',
        },
        {
          text: this.card.number,
          disabled: false,
        },
      ]
    },
  },


}
</script>

<style scoped>

</style>
