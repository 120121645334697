<template>
  <v-row class="pa-5">
    <v-col v-if="$store.state.isAdmin" cols="12">
      <v-snackbar
          v-model="snackBar.show"
          :timeout="snackBar.timeout"
          :color="snackBar.color"
          top
          multi-line

      >{{snackBar.text}}</v-snackbar>
      <please-wait title="Disabling User Please Wait.." :show="false"></please-wait>
      <v-row>
        <v-col cols="12">
          <v-btn color="primary" @click="addUserDialog = true">
            <v-icon left>mdi-account-plus</v-icon>
            Add User
          </v-btn>
        </v-col>
        <v-col cols="12">
          <v-data-table
              :items="userlist"
              :headers="headers"
              @dblclick:row="doubleClickedRow"
          >
            <template v-slot:item.actions="{ item }">
              <v-icon
                  small
                  class="mr-2"
                  @click="viewUser(item)"
              >
                mdi-eye
              </v-icon>

              <v-icon
                  v-if="item.enabled"
                  color="red"
                  class="mr-2"
                  @click="disableUser(item)"
                  title="Disable user"
              >
                mdi-account-lock
              </v-icon>
              <v-icon
                  v-else
                  color="green"
                  class="mr-2"
                  @click="enableUser(item)"
                  title="Enable user"
              >
                mdi-account-check
              </v-icon>
              <v-icon
                  class="mr-2"
                  @click="editUser(item)"
                  title="Edit user"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  @click="deleteUser(item)"
                  title="Delete user"
              >
                mdi-delete
              </v-icon>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
      <UserForm :showDialog="addUserDialog" v-on:close="closeDialog"></UserForm>
    </v-col>
    <v-col v-else cols="12">
      Access to this page is restricted!
    </v-col>
  </v-row>

</template>

<script>

import UserService from '@/services/user.service'
import UserForm from "@/components/AdminViews/forms/UserForm";
import PleaseWait from "@/components/PleaseWait";

export default {

  name: "Users",
  components: {PleaseWait, UserForm},

  data: () => ({
    snackBar:{
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'

    },
    addUserDialog: false,
    user:{
      name: '',
      email: '',
      password: '',
    },
    userlist: [],
    headers: [
      {
        text: 'Name',
        sortable: true,
        value: 'name'
      },
      {
        text: 'Email Address',
        sortable: true,
        value: 'email'
      },
      {
        text: 'Last Login',
        sortable: true,
        value: 'last_login'
      },
      {text: 'Actions', value: 'actions', sortable: false},

    ],
  }),

  methods:{

    viewUser(user){
      //this.$store.commit('current_driver', driver)
      this.$router.push({name: 'User',params:{userToEdit: user}})
    },


    doubleClickedRow(mouse_event,item){
      this.viewUser(item.item)
    },


    closeDialog(user){
      this.addUserDialog = false;
      if(user){
        let _this = this;
        UserService.addUser(user)
            .then(function(){
              _this.snackBar.text = 'The user has been added'
              _this.snackBar.color = 'green';
              _this.snackBar.show = true;
              _this.loadUsers()
            })
            .catch(function (error){

              _this.snackBar.text = "Error adding user\n("+error.response.status+") "+error.response.statusText
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
      }
    },

    loadUsers(){
      UserService.users().then(
          response => {
            this.userlist = response.data;
          }
      )
    },

  },

  mounted() {
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>
