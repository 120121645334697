<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12">
        <v-row class="px-5">
          <v-col md="auto">
            <v-toolbar-title>{{title}}</v-toolbar-title>
          </v-col>
          <v-col md="auto" v-for="button in quickButtons" v-bind:key="button.name">
            <v-btn color="primary" x-small @click="setDate(button.name)">{{ button.name }}</v-btn>
          </v-col>
          <v-col md="auto" v-if="showPdf">
            <v-btn icon small @click="showPdfClicked">
              <v-icon color="red accent-4">mdi-file-pdf-box</v-icon>
            </v-btn>
          </v-col>
          <v-col md="auto" v-if="showCsv">
            <v-btn icon small @click="showCsvClicked">
              <v-icon color="blue accent-4">mdi-file-delimited</v-icon>
            </v-btn>
          </v-col>

        </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="1" lg="1">
              <v-btn icon @click="change" class="mt-3">
                <v-icon x-large>mdi-refresh</v-icon>
              </v-btn>

            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-menu
                  v-model="menuFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="from"
                      label="Starting date for report"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="from"
                    @input="menuFrom = false"
                    @change="customDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-menu
                  v-model="menuTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="to"
                      label="Ending date for report"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="to"
                    @input="menuTo = false"
                    @change="customDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mt-5">
              <h2>{{ capDateSpan }}</h2>
            </v-col>
          </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "ReportDateTimePicker",

  props:{
    title:{
      type: String,
      default: 'Report'
    },
    when:{
      type: String,
      default: 'yesterday'
    },
    showPdf:{
      type: Boolean,
      default: false
    },
    showCsv:{
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    dateSpan: 'yesterday',

    quickButtons:[
      {name: 'yesterday'},
      {name: 'last week'},
      {name: 'last month'},
      {name: 'last year'},
      {name: 'today'},
      {name: 'this week'},
      {name: 'this month'},
      {name: 'this year'},
    ],

    from: new Date().toISOString().substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
    menuFrom: false,
    menuTo: false,


  }),

  mounted(){
    this.setDate(this.when)
  },

  methods:{

    change(){
      this.$emit('change',{from: this.fromWithTime, to: this.toWithTime, span: this.dateSpan})
    },

    showPdfClicked(){
      this.$emit('pdf')
    },

    showCsvClicked(){
      this.$emit('csv')
    },

    customDate(){
      this.dateSpan = 'custom'
      this.getReportData()
    },

    setDate(when){
      if(when === 'yesterday'){
        this.from = this.$ISODate(this.$yesterday())
        this.to = this.$ISODate(this.$yesterday())
      }else if(when === 'last week'){
        this.from = this.$ISODate(this.$lastMonday())
        this.to = this.$ISODate(this.$lastSunday())
      }else if(when === 'last month'){
        this.from = this.$ISODate(this.$lastMonthStart())
        this.to = this.$ISODate(this.$lastMonthEnd())
      }else if(when === 'last year'){
        this.from = this.$ISODate(this.$startOfLastYear())
        this.to = this.$ISODate(this.$endOfLastYear())
      }else if(when === 'today'){
        this.from = this.$ISODate(new Date())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this week'){
        this.from = this.$ISODate(this.$monday())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this month'){
        this.from = this.$ISODate(this.$startOfMonth())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this year'){
        this.from = this.$ISODate(this.$startOfYear())
        this.to = this.$ISODate(new Date())
      }
      this.dateSpan = when
      this.change()
    },
  },

  computed: {
    capDateSpan(){
      return this.dateSpan.charAt(0).toUpperCase() + this.dateSpan.slice(1);
    },

    fromWithTime(){
      return this.from + ' 00:00:00'
    },

    toWithTime(){
      return this.to + ' 23:59:59'
    },
  },


}
</script>

<style scoped>

</style>
