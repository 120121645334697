<template>
  <v-row justify="center">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>{{title}}</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-3">
          <span :class="infoColor">{{info}}</span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red darken-4" outlined @click="no">{{ noButton }}</v-btn>
          <v-btn color="blue darken-1" outlined @click="yes"> {{ yesButton }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>

export default {
  name: "YesnoDialog",
  props:{
    title:{
      type: String,
      default: 'Input'
    },
    object:{
      type: Object,
      default: null,
    },
    info:{
      type: String,
      default: 'Are you sure?'
    },
    infoColor: {
      type: String,
      default: 'red--text font-weight-bold'
    },
    yesButton:{
      type: String,
      default: 'yes'
    },
    noButton:{
      type: String,
      default: 'no'
    },
    show:{
      type: Boolean,
      default: false
    },
    multiline:{
      type: Boolean,
      default: false
    },
    dataValue:{
      type: String,
      default: ''
    }
  },

  data: function () {
    return {
      dataInput: ''
    }
  },

  watch:{
    dataValue(){
      this.dataInput = this.dataValue
    },
  },


  mounted() {
    this.dataInput = this.dataValue
  },

  methods:{
    yes(){
      this.$emit('yes',this.$props.object);
    },
    no(){
      this.$emit('no');
    }
  }

}
</script>

<style scoped>

</style>
