import axios from 'axios'

class ReportService {

    clientFuelReport(client_id,from,to,all) {
        if(all){
            return axios.post('clients/allfuelreport', {client_id:client_id,from: from, to: to});
        }else{
            return axios.post('clients/fuelreport', {client_id:client_id,from: from, to: to});
        }
    }

    driverFuelReport(driver_id,from,to) {
        return axios.post('clients/driver/fuelreport', {driver_id:driver_id,from: from, to: to});
    }
    moneyCodeReport(from,to) {
        return axios.post('clients/mcreport', {from: from, to: to});
    }

    fuelProfitReport(from,to) {
        return axios.post('reports/fuelprofit', {from: from, to: to});
    }

}

export default new ReportService();

