<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-snackbar
          v-model="snackBar.show"
          :timeout="snackBar.timeout"
          :color="snackBar.color"
          top
          multi-line

      >{{snackBar.text}}</v-snackbar>
    </v-col>
    <v-col cols="12" class="pa-5">
      <v-card>
          <v-row class="px-5">
            <v-col md="auto">
              <v-toolbar-title>Money Code Report</v-toolbar-title>
            </v-col>
            <v-col md="auto" v-for="button in quickButtons" v-bind:key="button.name">
              <v-btn color="primary" x-small @click="setDate(button.name)">{{ button.name }}</v-btn>
            </v-col>
          </v-row>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-menu
                  v-model="menuFrom"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="fromWithTime"
                      label="Starting date for report"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="from"
                    @input="menuFrom = false"
                    @change="customDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="4" lg="2">
              <v-menu
                  v-model="menuTo"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="toWithTime"
                      label="Starting date for report"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="to"
                    @input="menuTo = false"
                    @change="customDate"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="mt-5">
              <h2>{{ capDateSpan }}</h2>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <v-text-field
                  label="Search"
                  v-model="search"
                  prepend-inner-icon="mdi-nfc-search-variant"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                  dense
                  :items="reportData.rows"
                  :headers="headers"
                  :search="search"
                  no-data-text="Client has no money codes issued for the date's chosen."
              >

                <template v-slot:item.amount="{ item }">
                  {{ item.amount.toLocaleString(undefined,{style:'currency', currency:'USD',minimumFractionDigits:2}) }}
                </template>

                <template v-slot:item.fee="{ item }">
                  {{ item.fee.toLocaleString(undefined,{style:'currency', currency:'USD',minimumFractionDigits:2}) }}
                </template>

                <template
                    v-if="reportData.rows && reportData.rows.length"
                    v-slot:footer
                >
                  <v-row>
                    <v-col cols="12" align="right" class="pr-7">
                      <span style="font-weight: bold">Total Funded: {{ reportData.total_amount.toLocaleString(undefined,{style:'currency', currency:'USD',minimumFractionDigits:2}) }}</span>
                      <span style="font-weight: bold" class="ml-15">Total Fees: {{ reportData.total_fee.toLocaleString(undefined,{style:'currency', currency:'USD',minimumFractionDigits:2}) }}</span>
                    </v-col>
                  </v-row>
                </template>


              </v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import ReportService from '@/services/report.service'


export default {
  name: "MoneyCodes",

  data: () => ({

    reportData: [],

    dateSpan: 'yesterday',

    quickButtons:[
      {name: 'yesterday'},
      {name: 'last week'},
      {name: 'last month'},
      {name: 'last year'},
      {name: 'today'},
      {name: 'this week'},
      {name: 'this month'},
      {name: 'this year'},
    ],

    search: '',

    headers: [
      {
        text: 'Client',
        sortable: true,
        value: 'client_name',

      },
      {
        text: 'Money Code',
        sortable: true,
        value: 'money_code'
      },
      {
        text: 'Amount',
        sortable: true,
        value: 'amount',
        align: 'right'
      },
      {
        text: 'Driver',
        sortable: true,
        value: 'driver_name'
      },
      {
        text: 'Issue Date',
        sortable: true,
        value: 'issue_date'
      },
      {
        text: 'Broker',
        sortable: true,
        value: 'broker'
      },
      {
        text: 'Load Number',
        sortable: true,
        value: 'load_number'
      },
      {
        text: 'Fee',
        sortable: true,
        value: 'fee',
        align: 'right'
      },
    ],


    client: {},
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    from: new Date().toISOString().substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
    menuFrom: false,
    menuTo: false,
  }),

  mounted() {
    let date = new Date()
    date.setDate(date.getDate() - 1);
    this.from = date.toISOString().substr(0, 10)
    this.to = date.toISOString().substr(0, 10)
    this.getReportData();
  },

  methods:{
    getReportData(){
      ReportService.moneyCodeReport(this.fromWithTime,this.toWithTime).then(
          response => {
            this.reportData = response.data;
          }
      )
    },

    customDate(){
      this.dateSpan = 'custom'
      this.getReportData()
    },

    setDate(when){
      if(when === 'yesterday'){
        this.from = this.$ISODate(this.$yesterday())
        this.to = this.$ISODate(this.$yesterday())
      }else if(when === 'last week'){
        this.from = this.$ISODate(this.$lastMonday())
        this.to = this.$ISODate(this.$lastSunday())
      }else if(when === 'last month'){
        this.from = this.$ISODate(this.$lastMonthStart())
        this.to = this.$ISODate(this.$lastMonthEnd())
      }else if(when === 'last year'){
        this.from = this.$ISODate(this.$startOfLastYear())
        this.to = this.$ISODate(this.$endOfLastYear())
      }else if(when === 'today'){
        this.from = this.$ISODate(new Date())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this week'){
        this.from = this.$ISODate(this.$monday())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this month'){
        this.from = this.$ISODate(this.$startOfMonth())
        this.to = this.$ISODate(new Date())
      }else if(when === 'this year'){
        this.from = this.$ISODate(this.$startOfYear())
        this.to = this.$ISODate(new Date())
      }

      this.dateSpan = when;
      this.getReportData()

    },

  },


  computed: {

    capDateSpan(){
      return this.dateSpan.charAt(0).toUpperCase() + this.dateSpan.slice(1);
    },

    fromWithTime(){
      return this.from + ' 00:00:00'
    },

    toWithTime(){
      return this.to + ' 23:59:59'
    },
  },
}
</script>

<style scoped>

</style>
