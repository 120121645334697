<template>
  <v-row class="ma-4">
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line

    >{{snackBar.text}}</v-snackbar>
    <input-dialog
        :dataValue="permInput.data"
        :show="permInput.show"
        :title="permInput.title"
        :inputTitle="permInput.inputTitle"
        v-on:input-save="returnInput"
        v-on:input-cancel="permInput.show=false"
    ></input-dialog>

    <v-col cols="12">
      <v-row no-gutters>
        <v-col>
          <h2>Permission Administration</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col align="right">
          <v-btn  @click="addPermission" color="primary" title="New role" class="mr-2">
            <v-icon small left>mdi-plus</v-icon>
            New Permission
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-3">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>

    <v-col cols="12">
      <v-row no-gutters v-for="perm in perms"
             :key="perm.id"
             class="mb-5"
      >
        <v-col cols="12">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>{{ perm.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn  @click="editPermission(perm)" color="secondary" title="edit role" class="mr-2" small>
                <v-icon small left>mdi-pencil</v-icon>
                Rename
              </v-btn>
              <v-btn  @click="deletePermission(perm)" color="red" title="New role" dark small>
                <v-icon small left>mdi-delete</v-icon>
                Delete
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row no-gutters>
                <v-col v-for="role in perm.roles"
                       :key="role.id"
                       cols="12"
                       class="col-xl-2 col-lg-3 col-md-4"
                >
                  <v-switch
                      v-model="role.hasPermission"
                      :label="role.name"
                      hide-details
                      color="green"
                      @change="setPermission(role,perm)"

                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-col>

  </v-row>
</template>

<script>

import PermissionService from '@/services/permissions.service'
import InputDialog from "@/components/InputDialog";


export default {
  name: "Permissions",

  components: {InputDialog},
  data: () => ({

    func: 'none',
    editingPermission: null,

    permInput: {
      show: false,
      title: 'Add Permission',
      inputTitle: 'Permission Name',
      data: ''
    },

    perms: [],

    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'

    },
  }),

  mounted() {

    this.loadPerms();

  },

  methods: {
    loadPerms() {
      PermissionService.permissionList().then(
          response => {
            this.perms = response.data;
          }
      )
    },

    setPermission(role,perm){
      let _this = this

      PermissionService.setPermission(role.id, perm.id, role.hasPermission)
          .then(function () {
            _this.snackBar.text = 'Permission updated';
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
            _this.$nextTick(() => {
              _this.loadRoles();
            });
          })
          .catch(function (error) {
            if(error.response.data){
              _this.snackBar.text = error.response.data.message
            }else{
              _this.snackBar.text = "Error updating permission!\n(" + error.response.status + ") " + error.response.statusText
            }
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
            console.log(error.response);
          })


    },

    addPermission(){
      this.func = 'add'
      this.permInput.title = 'Add Permission'
      this.permInput.show = true
    },

    editPermission(permission){
      this.func = 'edit'
      this.editingPermission = permission;
      this.permInput.title = 'Rename permission ('+permission.name+')'
      this.permInput.data = permission.name
      this.permInput.show = true
    },

    deletePermission(perm) {
      this.$confirm('Are you sure you want to delete the permission <br/>' + perm.name + '<br/><br/><span class="pl-14 font-weight-bold">This action can not be undone!</span>', {title: 'Delete Permission'}).then(res => {
        if (res === true) {
          let _this = this
          PermissionService.deletePermission(perm.id)
              .then(function () {
                _this.snackBar.text = 'Permission deleted';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.$nextTick(() => {
                  _this.loadPerms();
                });
              })
              .catch(function (error) {
                if (error.response.data) {
                  _this.snackBar.text = error.response.data.message
                } else {
                  _this.snackBar.text = "Error deleting permission!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }
      })
    },
    returnInput(input){
      this.permInput.show = false;
      if(input.data !== ''){
        let _this = this;

        if(this.func === 'add'){
          PermissionService.addPermission(input.data)
              .then(function () {
                _this.snackBar.text = 'New permission added';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.$nextTick(() => {
                  _this.loadPerms();
                });
              })
              .catch(function (error) {
                if(error.response.data){
                  _this.snackBar.text = error.response.data.message
                }else{
                  _this.snackBar.text = "Error adding permission!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }else if(this.func === 'edit'){

          if(input.data === this.editingPermission.name) {
            return
          }

          PermissionService.renamePermission(this.editingPermission.id, input.data)
              .then(function () {
                _this.snackBar.text = 'Permission updated';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.$nextTick(() => {
                  _this.loadPerms();
                });

              })
              .catch(function (error) {
                if(error.response.data){
                  _this.snackBar.text = error.response.data.message
                }else{
                  _this.snackBar.text = "Error editing permission!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }
      }
    },


  }
}
</script>

<style scoped>

</style>
