<template>
  <v-dialog v-model="show" persistent :width="900">
    <v-card :disabled="adding">
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="selectformform" v-model="selectFormValid" lazy-validation>
          <v-row no-gutters class="mt-3">
            <v-col cols="12" class="pr-3">
              <v-select
                  :label="itemsTitle"
                  v-model="selectForm.item"
                  :rules="[rules.required]"
                  :items="items"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="adding" elevation="0" color="primary" @click="add">
          {{ saveButton }}
        </v-btn>
        <v-btn elevation="0" color="secondary" @click="cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SelectPicker",

  props:{
    show:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Select'
    },

    itemsTitle: {
      type: String,
      default: 'Items'
    },

    saveButton: {
      type: String,
      default: 'save'
    },

    items:{
      type: Array,
      default: null
    }
  },

  data: () => ({

    adding: false,

    selectForm:{
      item: '',

    },

    selectFormValid: true,

    rules: {
      required: value => !!value || 'Required.',
    },


  }),

  methods:{
    add(){
      if(this.$refs.selectformform.validate() === true) {
        this.adding = true
        this.$emit('save', this.selectForm.item)
      }
    },
    cancel(){
      this.$emit('cancel')
    },
  },

  watch:{
    show(){
      if(this.$refs.selectformform){
        this.$refs.selectformform.resetValidation()
      }
      if(this.show === true){
        this.adding = false
      }
    }
  }

}
</script>

<style scoped></style>
