import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
//import Meta from 'vue-meta'

import paths from './paths'

Vue.use(VueRouter)


const router = new VueRouter({

  base: '',
  mode: 'history',
  routes: paths,

  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return { selector: to.hash }
    }
    return { x: 0, y: 0 }
  }


})

// Route guard checks to see if you are logged in, if not reroutes to login
// to is where you are going, matched.some is to find which routes have requiresAuth
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.authorized) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

//Vue.use(Meta)

export default router
