var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"no-gutters":""}},[_c('v-snackbar',{attrs:{"timeout":_vm.snackBar.timeout,"color":_vm.snackBar.color,"top":"","multi-line":""},model:{value:(_vm.snackBar.show),callback:function ($$v) {_vm.$set(_vm.snackBar, "show", $$v)},expression:"snackBar.show"}},[_vm._v(" "+_vm._s(_vm.snackBar.text)+" ")]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pa-3"},[_c('report-date-time-picker',{attrs:{"title":"All Fuel Transactions","show-pdf":_vm.showPdf,"show-csv":_vm.showPdf},on:{"change":_vm.change,"csv":_vm.csvClicked,"pdf":_vm.renderPDF}}),_c('v-divider'),_c('v-data-table',{attrs:{"items":_vm.reportData.rows,"headers":_vm.headers,"loading":_vm.loading},scopedSlots:_vm._u([{key:"footer.prepend",fn:function(){return [_c('div',{staticStyle:{"background-color":"#00acc1","width":"100%"}},[_vm._v(" Footer ")])]},proxy:true},{key:"item.retail",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.retail.formatCurency())+" ")]}},{key:"item.ga_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ga_discount.formatCurency())+" ")]}},{key:"item.customer_discount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.customer_discount.formatCurency())+" ")]}},{key:"item.ga_income",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ga_income.formatCurency())+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }