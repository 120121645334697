<template>
  <v-row class="ma-4">
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line

    >{{snackBar.text}}</v-snackbar>
    <input-dialog
        :dataValue="roleInput.data"
        :show="roleInput.show"
        :title="roleInput.title"
        :inputTitle="roleInput.inputTitle"
        v-on:input-save="returnInput"
        v-on:input-cancel="roleInput.show=false"
    ></input-dialog>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col>
          <h2>Role Administration</h2>
        </v-col>
        <v-spacer></v-spacer>
        <v-col align="right">
          <v-btn  @click="addRole" color="primary" title="New role" class="mr-2">
            <v-icon small left>mdi-plus</v-icon>
            New Role
          </v-btn>
        </v-col>
        <v-col cols="12" class="mt-3">
          <v-divider></v-divider>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-row no-gutters v-for="role in roles"
             :key="role.id"
             class="mb-5"
      >
        <v-col cols="12">
          <v-card>
            <v-toolbar flat>
              <v-toolbar-title>{{ role.name }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn  @click="editRole(role)" color="secondary" title="edit role" class="mr-2" small>
                <v-icon small left>mdi-pencil</v-icon>
                Rename
              </v-btn>
              <v-btn  @click="deleteRole(role)" color="red" title="New role" dark small>
                <v-icon small left>mdi-delete</v-icon>
                Delete
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-row no-gutters>
                <v-col v-for="perm in role.permissions"
                       :key="perm.id"
                       cols="12"
                       class="col-xl-2 col-lg-3 col-md-4"
                >
                  <v-switch
                      v-model="perm.inRole"
                      :label="perm.name"
                      hide-details
                      color="green"
                      @change="setPermission(role,perm)"

                  ></v-switch>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-col>

  </v-row>
</template>

<script>

import PermissionService from '@/services/permissions.service'
import InputDialog from "@/components/InputDialog";


export default {
  name: "Roles",
  components: {InputDialog},
  data: () => ({

    testDate: 'Test Data',

    func: 'none',
    editingRole: null,

    roleInput:{
      show: false,
      title: 'Add Role',
      inputTitle: 'Role Name',
      data: ''
    },

    roles: [],

    snackBar:{
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'

    },
  }),

  mounted() {

    this.loadRoles();

  },

  methods:{
    loadRoles(){
      PermissionService.roleList().then(
          response => {
            this.roles = response.data;
          }
      )
    },

    setPermission(role,perm){
      let _this = this

      PermissionService.setPermission(role.id, perm.id, perm.inRole)
          .then(function () {
            _this.snackBar.text = 'Permission updated';
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
            _this.$nextTick(() => {
              _this.loadRoles();
            });
          })
          .catch(function (error) {
            if(error.response.data){
              _this.snackBar.text = error.response.data.message
            }else{
              _this.snackBar.text = "Error updating permission!\n(" + error.response.status + ") " + error.response.statusText
            }
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
            console.log(error.response);
          })


    },

    addRole(){
      this.func = 'add'
      this.roleInput.title = 'Add Role'
      this.roleInput.show = true
    },

    editRole(role){
      this.func = 'edit'
      this.editingRole = role;
      this.roleInput.title = 'Rename Role ('+role.name+')'
      this.roleInput.data = role.name
      this.roleInput.show = true
    },

    deleteRole(role) {
      this.$confirm('Are you sure you want to delete the role <br/>' + role.name + '<br/><br/><span class="pl-14 font-weight-bold">This action can not be undone!</span>', {title: 'Delete Role'}).then(res => {
        if (res === true) {
          let _this = this
          PermissionService.deleteRole(role.id)
              .then(function () {
                _this.snackBar.text = 'Role deleted';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.loadRoles();
              })
              .catch(function (error) {
                if (error.response.data) {
                  _this.snackBar.text = error.response.data.message
                } else {
                  _this.snackBar.text = "Error deleting role!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }
      })
    },

    returnInput(input){
      this.roleInput.show = false;
      if(input.data !== ''){
        let _this = this;

        if(this.func === 'add'){
          PermissionService.addRole(input.data)
              .then(function () {
                _this.snackBar.text = 'New role added';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.loadRoles();
              })
              .catch(function (error) {
                if(error.response.data){
                  _this.snackBar.text = error.response.data.message
                }else{
                  _this.snackBar.text = "Error adding role!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }else if(this.func === 'edit'){

          if(input.data === this.editingRole.name) {
            return
          }

          PermissionService.renameRole(this.editingRole.id, input.data)
              .then(function () {
                _this.snackBar.text = 'Role updated';
                _this.snackBar.color = 'green';
                _this.snackBar.show = true;
                _this.loadRoles();
              })
              .catch(function (error) {
                if(error.response.data){
                  _this.snackBar.text = error.response.data.message
                }else{
                  _this.snackBar.text = "Error editing role!\n(" + error.response.status + ") " + error.response.statusText
                }
                _this.snackBar.color = 'red';
                _this.snackBar.show = true;
                console.log(error.response);
              })
        }
      }
    },


  },

}
</script>

<style scoped>

</style>
