// https://vuex.vuejs.org/en/getters.html

// authorized lets you know if the token is true or not
export default {
    authorized: function(state){

        if(!state.token){
            return false
        }else if(new Date().getTime() > state.tokenExpires){
            return false
        }

        return true;
    },
    authstatus: state => state.authStatus,
}
