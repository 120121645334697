import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate"
import SecureLS from "secure-ls";
import simpleBrowserFingerprint from "simple-browser-fingerprint";

//const ls = new SecureLS({ isCompression: true, encodingType: 'aes', encryptionSecret: '8876HGgfgty%56FT54e3454rRT67786%^' })

const securityOptions = {
  encodingType: "",
  encryptionSecret: undefined,
  isCompression: false
}

  if (process.env.NODE_ENV === "production"){
    Object.assign(securityOptions, {
      encodingType: "aes",
      encryptionSecret: simpleBrowserFingerprint(),
      isCompression: true
    })
}
const ls = new SecureLS(securityOptions)



// Store functionality
import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'
//import axios from "axios";


Vue.use(Vuex)

export default new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  plugins: [
    createPersistedState({
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key)
      }
    })
  ],
})
