<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card class="pa-3">
            <report-date-time-picker title="Activity Log" @change="change" when="today"></report-date-time-picker>
            <v-divider></v-divider>
            <v-data-table
                :items="reportData"
                :headers="headers"
                :loading="loading"
                :search="search"
            >

              <template v-slot:top>
                <v-text-field
                    v-model="search"
                    label="Search"
                    class="mx-4"
                ></v-text-field>
              </template>

              <template v-slot:item.type="{ item }">
                <v-chip small label :color="chipColor(item.type)" :dark="isChipDark(item.type)">{{item.type}}</v-chip>
              </template>


            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import ReportDateTimePicker from "@/components/core/ReportDateTimePicker";
import LogService from '@/services/log.service'
export default {
  name: "ActivityLog",
  components: {ReportDateTimePicker},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    search: '',

    loading: false,

    reportData: [],

    headers: [
      {
        text: 'Date/Time',
        sortable: true,
        value: 'log_date',
      },
      {
        text: 'Type',
        sortable: true,
        value: 'type',
      },
      {
        text: 'Info',
        sortable: true,
        value: 'message',

      },
      {
        text: 'Who',
        sortable: true,
        value: 'who',
      },
    ],


  }),

  methods:{

    isChipDark(type){
      if(type == 'error'){
        return true
      }else if(type == 'info'){
        return true
      }else if(type == 'security'){
        return true
      }
      return true
    },


    chipColor(type){
      if(type == 'error'){
        return 'red'
      }else if(type == 'info'){
        return 'primary'
      }else if(type == 'security'){
        return 'red darken-4'
      }
      return 'secondary'
    },

    getLog(from, to){
      this.loading = true
      let _this = this
      LogService.log(from, to)
          .then(function (response) {
            _this.reportData = response.data;
            console.log(response.data)
          })
          .catch(function (error) {
            console.log(error);
            let msg = _this.$errorMessage(error)
            _this.snackBar.text = "Error loading log!\n(" + msg + ")"
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
          })
          .then(function () {
            _this.loading = false
          })
    },

    change(obj){
      this.getLog(obj.from, obj.to)
      //console.log(obj)
    }
  },


}
</script>

<style scoped>

</style>
