<template>
  <v-row no-gutters>
    <yesno-dialog :title="yesNoPrompt.title" :show="yesNoPrompt.show" :info="yesNoPrompt.info" @no="yesNoPrompt.show=false" @yes="confirmDeletePrompt"></yesno-dialog>
    <yesno-dialog :title="yesNo.title" :show="yesNo.show" :info="yesNo.info" @no="yesNo.show=false" @yes="confirmDeleteLimit"></yesno-dialog>
    <add-infos-dialog :show="showAddPromptDialog"  @cancel="cancelAddPrompt" @addPrompt="addPrompt"></add-infos-dialog>
    <add-limits-dialog :show="showAddLimitDialog"  @cancel="cancelAddLimit" @addLimit="addLimit"></add-limits-dialog>
    <yesno-dialog
        title="Update Card"
        info="The policy number has been changed, You must update the card to continue."
        yes-button="update"
        no-button="revert"
        :show="policyChanged"
        @no="revertPolicyNumber"
        @yes="policyChange"
        ></yesno-dialog>
    <please-wait
        :title="pleaseWait.title"
        :show="pleaseWait.show"
    ></please-wait>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
            <v-breadcrumbs
                style="background-color: #f5f5f5;"
                :items="bread"
                large
            ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row no-gutters v-if="dirty">
        <v-col cols="12" class="pl-10 mt-3">
            <v-btn dark color="red" @click="updateCard">
              <v-icon left>mdi-content-save</v-icon>
              update card
            </v-btn>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <v-card
              class="pa-5"
              elevation="2"
          >
            <v-card-text>
              <v-form
                  ref="cardform"
                  v-model="valid"
                  lazy-validation
              >
                <v-row no-gutters>
                  <v-col cols="12" md="6" class="pr-3">
                    <v-text-field
                        label="Card Number"
                        v-model="card.number"
                        :rules="[rules.required,rules.cardLength]"
                        type="text"
                        @change="dirty=true"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pr-3">
                    <v-text-field

                        label="Card Limit"
                        v-model="card.limit"
                        type="number"
                        @change="dirty=true"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" md="3" class="pr-3">
                    <v-text-field
                        label="Card Balance"
                        v-model="card.balance"
                        type="number"
                        @change="dirty=true"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" class="text-right" style="margin-top: -20px; padding-bottom:10px;padding-right: 25px;font-size:10px">Setting the card limit to 0 will allow an unlimited balance.</v-col>
                </v-row>
                <v-row no-gutters v-if="efsCard">
                  <v-col cols="12" md="4">
                    <v-select
                        v-if="policyList.length"
                        label="Policy Number"
                        v-model="efsCard.header.policyNumber"
                        :items="policyList"
                        @change="policyNumberChange"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2" class="pl-3">
                    <v-select
                        label="Card Status"
                        v-model="efsCard.header.status"
                        :items="statList"
                        @change="dirty=true"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2" class="pl-3">
                    <v-select
                        label="Hand Enter"
                        v-model="efsCard.header.handEnter"
                        :items="handEnterList"
                        @change="dirty=true"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2" class="pl-3">
                    <v-select
                        label="Information Source"
                        v-model="efsCard.header.infoSource"
                        :items="informationList"
                        @change="dirty=true"
                    >
                    </v-select>
                  </v-col>
                  <v-col cols="12" md="2" class="pl-3">
                    <v-select
                        label="Limit Source"
                        v-model="efsCard.header.limitSource"
                        :items="informationList"
                        @change="dirty=true"
                    >
                    </v-select>
                  </v-col>
                </v-row>
                <v-row no-gutters>
                  <v-col cols="12">
                    <v-textarea
                        label="Memo"
                        v-model="card.memo"
                        outlined
                        @change="dirty=true"
                    ></v-textarea>
                  </v-col>
                </v-row>
              </v-form>
              <v-row no-gutters v-if="efsCard">
                <v-col cols="12">
                  <v-card outlined elevation="0">
                    <v-toolbar elevation="0">
                      <v-toolbar-title>Info Prompts</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="showAddPromptDialog=true" v-if="efsCard.header.infoSource === 'CARD' || efsCard.header.infoSource === 'BOTH'">
                        <v-icon left>mdi-plus</v-icon>
                        add
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row no-gutters v-if="efsCard.header.infoSource === 'BOTH'">
                        <v-col col="12" align="right">
                          <span style="color:darkred;font-weight: bold;font-size: 12px">Card Prompt always overrides the policy Prompt.</span>
                        </v-col>
                      </v-row>
                      <v-expansion-panels inset hoover v-model="infoValue">
                        <template v-if="efsCard.header.infoSource !== 'POLICY'">
                        <v-expansion-panel v-for="(item, index) in efsCard.infos" :key="index">
                          <v-expansion-panel-header color="blue-grey lighten-5">{{'CARD > ' + item.infoId + ' - ' + $store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === item.infoId)[0].description}}
                            <v-spacer></v-spacer>
                            <v-btn color="red" icon x-small max-width="20" @click="deletePrompt(item)" title="Delete Prompt" class="mr-5">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <info-prompt :info="item" @change="dirty=true"></info-prompt>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        </template>
                        <template v-if="efsCard.header.infoSource !== 'CARD'">
                        <v-expansion-panel v-for="(item, index) in policy.infos" :key="9999+index">
                          <v-expansion-panel-header color="blue-grey lighten-5">{{'POLICY > ' + item.infoId + ' - ' + $store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === item.infoId)[0].description}}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <info-prompt :info="item" :disabled="true"></info-prompt>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="efsCard" class="mt-5">
                <v-col cols="12">
                  <v-card outlined elevation="0">
                    <v-toolbar elevation="0">
                      <v-toolbar-title>Limits</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-btn outlined @click="showAddLimitDialog=true" v-if="efsCard.header.limitSource === 'CARD' || efsCard.header.limitSource === 'BOTH'">
                        <v-icon left>mdi-plus</v-icon>
                        add
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <v-row no-gutters v-if="efsCard.header.limitSource === 'BOTH'">
                        <v-col col="12" align="right">
                          <span style="color:darkred;font-weight: bold;font-size: 12px">Card Limit always overrides the policy Limit.</span>
                        </v-col>
                      </v-row>
                      <v-expansion-panels inset hover v-model="limitValue">
                        <template v-if="efsCard.header.limitSource !== 'POLICY'">
                        <v-expansion-panel v-for="(item, index) in efsCard.limits" :key="index">
                          <v-expansion-panel-header color="blue-grey lighten-5">{{'CARD > ' + item.limitId + ' - ' + $store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === item.limitId)[0].description}}
                            <v-spacer></v-spacer>
                            <v-btn color="red" icon x-small max-width="20" @click="deleteLimit(item)" title="Delete Prompt" class="mr-5">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <policy-limit :limit="item" @change="dirty=true"></policy-limit>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        </template>
                        <template v-if="efsCard.header.limitSource !== 'CARD'">
                        <v-expansion-panel v-for="(item, index) in policy.limits" :key="9999+index">
                          <v-expansion-panel-header color="blue-grey lighten-5">{{'POLICY > ' + item.limitId + ' - ' + $store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === item.limitId)[0].description}}
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <policy-limit :limit="item" :disabled="true"></policy-limit>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                        </template>
                      </v-expansion-panels>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

//import ClientService from '@/services/client.service'
import CardService from '@/services/efs/card.service'
import PolicyService from '@/services/efs/policy.service'
import InfoPrompt from "@/components/FundingViews/policys/InfoPrompt";
import PolicyLimit from "@/components/FundingViews/policys/PolicyLimit";
import YesnoDialog from "@/components/YesnoDialog";
import AddInfosDialog from "@/components/FundingViews/policys/AddInfosDialog";
import AddLimitsDialog from "@/components/FundingViews/policys/AddLimitsDialog";


export default {
  name: "EfsCard",
  components: {AddLimitsDialog, AddInfosDialog, YesnoDialog, PolicyLimit, InfoPrompt},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    pleaseWait:{
      show: false,
      title: 'Getting Card from EFS...Please Wait...',
    },

    infoValue: undefined, // what expansion pannel is open
    limitValue: undefined,

    dirty: false,
    wasDirty: false,
    policyDescriptions: [],
    policyChanged: false,
    oldPolicyNumber: 0,

    //infos: [],

    client: null,
    driver: null,
    card: null,

    efsCard: null,
    policy: null,

    valid: false,

    yesNo: {
      show: false,
      title: 'Delete',
      info: 'You ok with this'
    },
    yesNoPrompt: {
      show: false,
      title: 'Delete',
      info: 'You ok with this'
    },
    showAddPromptDialog: false,
    showAddLimitDialog: false,
    indexToDelete: -1,



    rules: {
      required: v => !!v || 'Required.',
      cardLength: v => v && v.search(/^\d{19}$/)  !== -1 || 'Card length must be 19 numeric characters.',
    },
    statList:[
      {text: 'Active', value: 'ACTIVE'},
      {text: 'Inactive', value: 'INACTIVE'},
      {text: 'Hold', value: 'HOLD'},
    ],
    handEnterList: [
      {text: 'Allow Hand Enter', value: 'ALLOW'},
      {text: 'Disallow Hand Enter', value: 'DISALLOW'},
      {text: 'Policy', value: 'POLICY'},
    ],

    informationList: [
      {text: 'Card', value: 'CARD'},
      {text: 'Policy', value: 'POLICY'},
      {text: 'Both', value: 'BOTH'},
    ],
  }),

  beforeMount() {
    this.client = this.$store.state.client
    this.driver = this.$store.state.driver
    this.card = this.$store.state.card
    if(!this.client || !this.driver || !this.card){
      this.$router.back();
    }

  },

  mounted() {
    this.fetchDescriptions()
    this.getEFSCard(this.card)
  },

  methods:{

    cancelAddLimit(){
      this.showAddLimitDialog = false
    },

    addLimit(limit){
      this.efsCard.limits.push(limit)
      this.dirty = true
      this.showAddLimitDialog = false
    },

    confirmDeleteLimit(){
      if(this.indexToDelete !== -1){
        this.efsCard.limits.splice(this.indexToDelete,1)
        this.limitValue = undefined
        this.indexToDelete = -1
        this.dirty = true
        this.yesNo.show = false
      }
    },

    deleteLimit(limit){
      console.log(limit)
      this.indexToDelete = this.efsCard.limits.indexOf(limit)
      this.yesNo.title = "Delete Limit "+limit.limitId + ' - ' + this.$store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === limit.limitId)[0].description+" ?"
      this.yesNo.info = "Once saved this operation can not be undone and will affect any cards this policy is assigned to!"
      this.yesNo.show = true
    },


    cancelAddPrompt(){
      this.showAddPromptDialog = false
    },

    addPrompt(prompt){
      this.efsCard.infos.push(prompt)
      this.dirty = true
      this.showAddPromptDialog = false
    },

    confirmDeletePrompt(){
      if(this.indexToDelete !== -1){
        this.efsCard.infos.splice(this.indexToDelete,1)
        this.infoValue = undefined
        this.indexToDelete = -1
        this.dirty = true
        this.yesNoPrompt.show = false
      }
    },

    deletePrompt(prompt){
      this.indexToDelete = this.efsCard.infos.indexOf(prompt)
      this.yesNoPrompt.title = "Delete Prompt "+prompt.infoId + ' - ' + this.$store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === prompt.infoId)[0].description+" ?"
      this.yesNoPrompt.info = "Once saved this operation can not be undone and will affect any cards this policy is assigned to!"
      this.yesNoPrompt.show = true
    },


    updateCard(){
      this.pleaseWait.title = "Updating Card.. Please Wait..."
      this.pleaseWait.show = true
      let _this = this
      CardService.saveCard(this.card,this.efsCard)
          .then(function (response) {
            _this.card = response.data.card
            _this.dirty = false
            _this.snackBar.text = response.data.message;
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
          })
          .catch(function (error) {
            _this.snackBar.text = "Error saving card!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.pleaseWait.show = false
          })
    },

    policyChange(){
      this.policyChanged = false
      this.pleaseWait.title = "Changing policy.. Please Wait..."
      this.pleaseWait.show = true
      let _this = this

      CardService.saveCard(this.card,this.efsCard)
          .then(function (response) {
            console.log(response.data)
            _this.card = response.data.card
            _this.snackBar.text = response.data.message;
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
            _this.getEFSCard(_this.card)
          })
          .catch(function (error) {
            _this.pleaseWait.show = false
            _this.snackBar.text = "Error saving card!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
          })
    },

    revertPolicyNumber(){
      this.efsCard.header.policyNumber = this.oldPolicyNumber
      this.policyChanged = false
      this.dirty = this.wasDirty
    },

    policyNumberChange(){
      this.policyChanged = true
    },

    getEFSCard(card){
      let _this = this
      this.pleaseWait.title = 'Getting Card from EFS.. Please Wait...'
      this.pleaseWait.show = true
      PolicyService.cardAndPolicy(card.id)
          .then(function (response) {
            _this.efsCard = response.data.card
            _this.oldPolicyNumber = _this.efsCard.header.policyNumber
            _this.policy = response.data.policy
            _this.dirty = false
            console.log(_this.efsCard)
          })
          .catch(function (error) {
            _this.snackBar.text = "Error getting card/policy!\n(" + error.response.status + ") " + error.response.statusText + " ["+error.response.data.error+"]"
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.pleaseWait.show = false
          })
    },

    fetchDescriptions(){
      let _this = this

      PolicyService.descriptions().then(
          response => {
            _this.policyDescriptions = response.data.descriptions.value
          }
      ).catch(function (error) {
        _this.eCodeRequest = false;
        _this.viewMoneyCodeDialog = false;
        _this.snackBar.text = "Error getting Policy Descriptions!\n(" + error.response.status + ") " + error.response.statusText + " ["+error.response.data.error+"]"
        _this.snackBar.color = 'red'
        _this.snackBar.show = true
        console.log(error.response)
      }).then(function () {
      })
    },


  },


  computed: {

    policyList() {

      let items = []
      for(let i=0;i<this.policyDescriptions.length;i++){
        items.push({text:this.policyDescriptions[i].description, value:this.policyDescriptions[i].policyNumber})
      }

      return items
    },


    bread() {
      return [
        {
          text: 'Clients',
          disabled: false,
          to: '/funding/clients',
        },
        {
          text: this.client.name,
          disabled: false,
          to: '/funding/client',
        },

        {
          text: this.driver.first_name+' '+this.driver.last_name,
          disabled: false,
          to: '/funding/driver',
        },
        {
          text: "Card Manager",
          disabled: false,
          to: '/funding/cardmanager',
        },
        {
          text: this.card.number,
          disabled: false,
        },
      ]
    },
  },

}
</script>

<style scoped>

</style>

