<template>
  <v-dialog v-model="show" persistent :width="900">
    <v-card :disabled="adding">
      <v-card-title>{{title}}</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="efscardform" v-model="efsCardValid" lazy-validation>
          <v-row no-gutters class="mt-3">
            <v-col cols="12" md="5" class="pr-3">
              <v-text-field
                  label="Card Number"
                  v-model="cardToEdit.number"
                  :rules="[rules.required,rules.cardLength]"
                  type="text"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pr-3">
              <v-select
                label="Status"
                v-model="cardToEdit.status"
                :items="statList"
                ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="pr-3">
              <v-text-field
                  label="Card Limit"
                  v-model="cardToEdit.limit"
                  type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="pr-3">
              <v-text-field
                  label="Card Balance"
                  v-model="cardToEdit.balance"
                  type="number"
              >
              </v-text-field>
            </v-col>
            <v-col cols="12" class="text-right" style="margin-top: -20px; padding-bottom:10px;padding-right: 25px;font-size:10px">Setting the card limit to 0 will allow an unlimited balance.</v-col>
            <v-col cols="12">
              <v-textarea
                label="Memo"
                v-model="cardToEdit.memo"
                outlined
                ></v-textarea>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <div v-if="cardToEdit.status === 'Active'" style="color: #B71C1C; font-size: 16px; font-weight: bold;">NOTE: Setting the status to "Active" will set all other cards to "Inactive".</div>
        <v-spacer></v-spacer>
        <v-btn :loading="adding" elevation="0" color="primary" @click="add">
          save card
        </v-btn>
        <v-btn elevation="0" color="secondary" @click="cancel"> cancel </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "EfsCardDialog",

  props:{
    show:{
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'EFS Card'
    },
    card:{
      type: Object,
      default: null,
    }
  },

  data: () => ({

    adding: false,

    efsCardValid: true,

    statList:[
        'Active', 'Inactive', 'Hold'
    ],

    defaultCard:{
      id: 0,
      driver_id: 0,
      number: '',
      status: 'Inactive',
      limit: 0.00,
      balance: 0.00,
      memo: '',
    },
    cardToEdit: null,
    rules: {
      required: v => !!v || 'Required.',
      cardLength: v => v && v.search(/^\d{19}$/)  !== -1 || 'Card length must be 19 numeric characters.',
    },


  }),

  beforeMount(){
    if(this.card !== null){
      this.cardToEdit = {...this.card}
    }else{
      this.cardToEdit = {...this.defaultCard}
    }
  },

  methods:{
    add(){
      if(this.$refs.efscardform.validate() === true) {
        this.adding = true
        this.$emit('save', this.cardToEdit)
      }
    },
    cancel(){
      this.$emit('cancel')
    },
  },

  watch:{
    show(){
      if(this.$refs.efscardform){
        this.$refs.efscardform.resetValidation()
      }
      if(this.show === true){
        this.adding = false
        if(this.card){
          this.cardToEdit = {...this.card}
        }else{
          this.cardToEdit = {...this.defaultCard}
        }
      }
    }
  }

}
</script>

<style scoped></style>
