import axios from 'axios'

class MessageService {
    unreadCount() {
        return axios.post('messages/unreadcount', {});
    }
    clearUnreadForDriver(driver_id) {
        return axios.post('messages/driver/clearunread', {driver_id:driver_id});
    }
}

export default new MessageService();

