<template>
<v-row class="px-5">
  <v-col cols="12">
    <h2>Overview</h2>
  </v-col>
  <v-col cols="12" class="col-xl-6 cols-lg-6 col-md-6">
    <dash-card title="Transactions's" icon="mdi-account-multiple" to="/funding/transactions" :small-text="transactions" :large-text="transactionValue"></dash-card>
  </v-col>
  <v-col cols="12" class="col-xl-6 cols-lg-6 col-md-6">
    <dash-card color="#952175" title="Money Code's" icon="mdi-cash-usd" to="/" :small-text="moneyCodes" :large-text="moneyCodeValue"></dash-card>
  </v-col>
</v-row>
</template>

<script>
import DashCard from "@/components/DashViews/DashCard";
import DashboardService from '@/services/dashboard.service'

export default {
  name: "Dashboard",
  components: {DashCard},

  data: () => ({
    stats: null,
  }),

  created() {

  },
  mounted() {

    let mc_channel = this.$pusher.subscribe('money-code-changed');
    let ta_channel = this.$pusher.subscribe('transaction-changed');
    let _this = this
    mc_channel.bind('added', function() {
      _this.getStats()
    });
    ta_channel.bind('added', function() {
      _this.getStats()
    });

    this.getStats()

  },

  methods:{
    getStats(){
      let _this = this
      DashboardService.stats()
              .then(function (response) {
                _this.stats = response.data;
              })
              .catch(function (error) {
                console.log(error);
              })
              .then(function () {
                _this.loading = false
              })
    }
  },

  computed:{

    moneyCodes(){
      if(this.stats){
        return "Today " + this.stats.codes
      }
      return "Today 0"
    },

    moneyCodeValue(){
      if(this.stats){
        return "Value $" + this.stats.code_value.formatCurency()
      }
      return "Value $0.00"
    },
    transactions(){
      if(this.stats){
        return "Today " + this.stats.transactions
      }
      return "Today 0"
    },

    transactionValue(){
      if(this.stats){
        return "Value $" + this.stats.transaction_value.formatCurency()
      }
      return "Value $0.00"
    },
  }
}

</script>

<style scoped>

</style>
