<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-snackbar
          v-model="snackBar.show"
          :timeout="snackBar.timeout"
          :color="snackBar.color"
          top
          multi-line

      >{{snackBar.text}}</v-snackbar>
      <v-breadcrumbs
          v-if="user"
          style="background-color: #f5f5f5"
          :items="bread"
          large
      ></v-breadcrumbs>
    </v-col>
    <v-col cols="12">
      <v-card
          class="ma-5"
          elevation="2"
      >
        <v-row class="ml-1">
          <v-col cols="12" class="col-md-6">
            <v-toolbar-title>{{ user.name }}</v-toolbar-title>
          </v-col>
          <v-col cols="12" class="col-md-6" align="end">
            <v-switch
                v-model="user.change_password"
                hide-details
                label="Update Password"
            ></v-switch>
          </v-col>
        </v-row>
        <v-toolbar flat>
          <v-toolbar-title>{{ user.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn small @click="disableUser" v-if="user.enabled" title="Disable account.">
            <v-icon left color="red" >mdi-account-lock</v-icon>
            disable
          </v-btn>
          <v-btn small @click="enableUser" v-else title="Enable account.">
            <v-icon left color="green">mdi-account-check</v-icon>
            Enable
          </v-btn>
          <v-btn small @click="showSMSInput=true" class="ml-2" title="Change password.">
            <v-icon left color="blue">mdi-account-key</v-icon>
            password
          </v-btn>
          <v-btn small @click="requestCode" class="ml-2" title="Delete user.">
            <v-icon left color="red">mdi-account-remove</v-icon>
            delete
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="12" class="col-lg-6">
         <span>
            <v-icon>mdi-email</v-icon>
            {{user.email}}
            <br/>
          </span>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
        cols="12"
        class="pa-5"
    >
      <v-card>
        <v-card-title>Roles</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col v-for="role in perms.roles"
                 :key="role.id"
                 cols="12"
                 class="col-xl-2 col-lg-3 col-md-4"
          >
            <v-switch
                v-model="role.hasRole"
                :label="role.name"
                hide-details
                color="green"
                @change="setRole(role)"

            ></v-switch>
          </v-col>
        </v-row>
      </v-card-text>
      </v-card>
    </v-col>
    <v-col
        cols="12"
        class="pa-5"
    >
      <v-card>
        <v-card-title>Permissions</v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col v-for="perm in perms.permissions"
                   :key="perm.id"
                   cols="12"
                   class="col-xl-2 col-lg-3 col-md-4"
            >
              <v-switch
                  v-model="perm.hasPermission"
                  :label="perm.name"
                  hide-details
                  color="green"
                  @change="setPermission(perm)"

              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import PermissionService from '@/services/permissions.service'
import UserService from '@/services/user.service'


export default {
  name: "JfUser",

  props:{
    userToEdit:{
      type: Object,
      required: true,
      default: null,
    },
  },

  data: () => ({
    user: null,
    perms: [],
    snackBar:{
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'

    },
  }),

  methods:{

    disableUser(){

      let _this = this;

      UserService.disableUser(this.user.id)
          .then(function () {
            _this.$nextTick(() => {
              _this.user.enabled = false;
            });
          })
          .catch(function (error) {
            if(error.response.data){
              _this.snackBar.text = error.response.data.message
            }else{
              _this.snackBar.text = "Error disabling role!\n(" + error.response.status + ") " + error.response.statusText
            }
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
            console.log(error.response);
          })
    },

    enableUser(){

      let _this = this;

      UserService.enableUser(this.user.id)
          .then(function () {
            _this.$nextTick(() => {
              _this.user.enabled = true;
            });
          })
          .catch(function (error) {
            if(error.response.data){
              _this.snackBar.text = error.response.data.message
            }else{
              _this.snackBar.text = "Error enabling role!\n(" + error.response.status + ") " + error.response.statusText
            }
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
            console.log(error.response);
          })
    },

    loadPermissions(){
      PermissionService.userPermissionList(this.user.id).then(
          response => {
            this.perms = response.data;
          }
      )
    },

    setRole(role){
      let _this = this
      if(role.hasRole){
        PermissionService.giveUserRole(this.user.id,role.id)
            .then(function () {
              _this.snackBar.text = 'Role updated';
              _this.snackBar.color = 'green';
              _this.snackBar.show = true;
              _this.$nextTick(() => {
                _this.loadRoles();
              });
            })
            .catch(function (error) {
              if(error.response.data){
                _this.snackBar.text = error.response.data.message
              }else{
                _this.snackBar.text = "Error updating role!\n(" + error.response.status + ") " + error.response.statusText
              }
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
      }else{
        PermissionService.removeUserRole(this.user.id,role.id)
            .then(function () {
              _this.snackBar.text = 'Role updated';
              _this.snackBar.color = 'green';
              _this.snackBar.show = true;
              _this.$nextTick(() => {
                _this.loadPermissions();
              });
            })
            .catch(function (error) {
              if(error.response.data){
                _this.snackBar.text = error.response.data.message
              }else{
                _this.snackBar.text = "Error updating role!\n(" + error.response.status + ") " + error.response.statusText
              }
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })

      }
    },

    setPermission(perm){
      let _this = this
      if(perm.hasPermission){
        PermissionService.setUserPermission(this.user.id,perm.id,true)
            .then(function () {
              _this.snackBar.text = 'Permission granted';
              _this.snackBar.color = 'green';
              _this.snackBar.show = true;
              _this.$nextTick(() => {
                _this.loadRoles();
              });
            })
            .catch(function (error) {
              if(error.response.data){
                _this.snackBar.text = error.response.data.message
              }else{
                _this.snackBar.text = "Error updating permission!\n(" + error.response.status + ") " + error.response.statusText
              }
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })
      }else{
        PermissionService.setUserPermission(this.user.id,perm.id,false)
            .then(function () {
              _this.snackBar.text = 'Permission revoked';
              _this.snackBar.color = 'green';
              _this.snackBar.show = true;
              _this.$nextTick(() => {
                _this.loadPermissions();
              });
            })
            .catch(function (error) {
              if(error.response.data){
                _this.snackBar.text = error.response.data.message
              }else{
                _this.snackBar.text = "Error updating permission!\n(" + error.response.status + ") " + error.response.statusText
              }
              _this.snackBar.color = 'red';
              _this.snackBar.show = true;
              console.log(error.response);
            })

      }
    },


  },

  mounted() {
    if(this.userToEdit === null){
      this.$router.back();
    }
    this.user = this.userToEdit
    this.loadPermissions()
  },

  watch:{
    user(){
      this.user = this.userToEdit
    }
  },

  computed: {
    bread() {
      return [
        {
          text: 'Users',
          disabled: false,
          to: '/admin/users',
        },

        {
          text: this.user.name,
          disabled: false,
        },
      ]
    },
  },

}
</script>

<style scoped>

</style>
