<template>
  <v-row no-gutters>
        <v-col col="12">
          <v-card>
            <v-card-title>
              <v-btn icon @click="cancelSelect" style="margin-left: -20px; margin-top: -50px;" color="red">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <span style="margin-top:20px;margin-left:-20px">Select a Card</span>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                dense
                :items="cards"
                :headers = "headers"
                :search="search"
                @dblclick:row="cardSelect"
            >
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
</template>

<script>
export default {
  name: "CardSummaryList",

  props:{
    cards:{
      type: Array,
      required: true,
    }
  },

  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    search: '',

    headers: [
      {
        text: 'Card #',
        sortable: true,
        value: 'cardNumber'
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status'
      },
      {
        text: 'Driver',
        sortable: true,
        value: 'driverName'
      },
    ],

  }),

  methods: {

    cardSelect(event,table){
      this.$emit('add',table.item)
    },

    cancelSelect(){
      this.$emit('cancel')
    }

  },

}
</script>

<style scoped>

</style>
