<template>
  <v-row no-gutters>
    <v-col cols="12" class="pa-5">
      <v-row no-gutters>
        <v-col cols="12" md="4">
          <v-text-field
            label="Amount"
            v-model="limit.limit"
            type="number"
            @change="fireChange"
            :disabled="disabled"
            ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" class="pl-3">
          <v-select
            label="Select Option"
            v-model="selectOption"
            :items="options"
            @change="fireChange"
            :disabled="disabled"
            ></v-select>
        </v-col>
        <v-col cols="12" md="4" v-if="!selectOption" class="pl-3">
          <v-text-field
              label="Hours"
              v-model="limit.hours"
              type="number"
              @change="fireChange"
              :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4" v-if="selectOption" class="pl-3">
          <v-text-field
              label="Daily Max"
              v-model="limit.autoRollMax"
              type="number"
              @change="fireChange"
              :disabled="disabled"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="selectOption" class="pl-3" >
        </v-col>
      </v-row>
      <v-row no-gutters v-if="selectOption" justify="center">
        <v-col cols="auto">
          <v-checkbox :disabled="disabled" class="float-left" label="Sun" v-model="autoRollMap.sun" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Mon" v-model="autoRollMap.mon" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Tue" v-model="autoRollMap.tue" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Wed" v-model="autoRollMap.wed" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Thu" v-model="autoRollMap.thu" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Fri" v-model="autoRollMap.fri" @change="fireChange"></v-checkbox>
          <v-checkbox :disabled="disabled" class="float-left ml-5" label="Sat" v-model="autoRollMap.sat" @change="fireChange"></v-checkbox>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {

  name: "PolicyLimit",

  props: {
    limit: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({

    selectOption: 0,

    autoRollMap: null,

    options: [
      {text: 'Allow', value: 0},
      {text: 'Auto Rollover', value: 1},
    ],
  }),

  beforeMount() {
    this.autoRollMap = this.rollMap()
    if(parseInt(this.limit.hours)){
      this.selectOption = 0
    }else{
      this.selectOption = 1
    }
  },

  mounted() {
  },

  methods:{

    fireChange(){

      //round all number fields
      this.limit.limit = Math.round(this.limit.limit)
      if(this.limit.limit > 9999)
        this.limit.limit = 9999

      this.limit.hours = Math.round(this.limit.hours)
      this.limit.minHours = Math.round(this.limit.minHours)

      this.limit.autoRollMax = Math.round(this.limit.autoRollMax)
      if(this.limit.autoRollMax > 9999)
        this.limit.autoRollMax = 9999

      this.limit.autoRollMap = this.rollVal()
      if(this.selectOption === 1){
        this.limit.hours = 0
      }
      this.$emit('change',this.selectOption)
    },

    rollMap(){
      let bin = parseInt(this.limit.autoRollMap).toString(2)
      bin = bin.padStart(7,0)

      return {
        sat: bin.substr(0,1) === '1',
        fri: bin.substr(1,1) === '1',
        thu: bin.substr(2,1) === '1',
        wed: bin.substr(3,1) === '1',
        tue: bin.substr(4,1) === '1',
        mon: bin.substr(5,1) === '1',
        sun: bin.substr(6,1) === '1'
      }
    },

    rollVal(){
      let bs = this.autoRollMap.sat?'1':'0'
       bs += this.autoRollMap.fri?'1':'0'
       bs += this.autoRollMap.thu?'1':'0'
       bs += this.autoRollMap.wed?'1':'0'
       bs += this.autoRollMap.tue?'1':'0'
       bs += this.autoRollMap.mon?'1':'0'
       bs += this.autoRollMap.sun?'1':'0'
      return parseInt(bs,2)
    },

  },

}
</script>

<style scoped>

</style>
