<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="1000px"
    >

      <v-card>
        <v-card-title>
          <span v-if="clientToEdit" class="headline">Edit Client</span>
          <span v-else class="headline">Add Client</span>
        </v-card-title>
        <v-card-text>
          <v-container>

            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                      name="company"
                      label="Client Name *"
                      placeholder="Grade A Trucking"
                      v-model="client.name"
                      :rules="nameRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      name="address"
                      label="Address *"
                      placeholder="1005 Rightway Ave"
                      v-model="client.street"
                      :rules="addressRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      name="address2"
                      label="Address Line 2"
                      placeholder="Suite 11"
                      v-model="client.street2"
                      :rules="address2Rules"
                      :counter="100"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-text-field
                      name="city"
                      label="City"
                      placeholder="Huntingburg"
                      v-model="client.city"
                      :rules="cityRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-select
                      label="State"
                      placeholder="Indiana"
                      v-model="client.state"
                      :items="states"
                      item-text="name"
                      item-value="abbreviation"
                      :rules="stateRules"
                      required
                  ></v-select>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-text-field
                      label="Zip Code"
                      placeholder="47542"
                      v-model="client.zip"
                      :rules="zipRules"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-text-field
                      label="Phone Number"
                      placeholder="8126831234"
                      v-model="client.phone"
                      :rules="phoneRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-text-field
                      label="E-Mail Address"
                      placeholder="greg@gradeafunding.com"
                      v-model="client.email"
                      :rules="emailRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="col-lg-4">
                  <v-text-field
                      label="Fuel Fee"
                      placeholder="20"
                      v-model="client.fuel_discount"
                      :rules="fuelDiscountRules"
                      required
                      type="number"
                      suffix="%"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>

</template>

<script>

export default {
  name: "ClientForm",

  props:{
    showDialog: {
      type: Boolean,
      default: false,
      required: true
    },
    clientToEdit:{
      type: Object,
      default: null,
      required: false
    },
    clearForm:{
      type: Boolean,
      default: false,
      required: false
    }
  },


  data: () => ({

    valid: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 100) || 'Name must be 100 characters or less',
    ],
    addressRules: [
      v => !!v || 'Address is required',
      v => (v && v.length <= 100) || 'Address must be 100 characters or less',
    ],
    address2Rules: [
      v => (v && v.length <= 100) || 'Address line 2 must be 100 characters or less',
    ],
    cityRules: [
      v => !!v || 'City is required',
      v => (v && v.length <= 100) || 'City must be 100 characters or less',
    ],
    stateRules: [
      v => !!v || 'State is required',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => (v && v.length <= 100) || 'E-mail must be 100 characters or less',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    phoneRules:[
      v => !!v || 'Phone Number is required.',
      v => /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im.test(v) || 'Phone number is invalid.'

    ],
    fuelDiscountRules: [
      v => (v >= 0 && v <= 100) || 'Discount must be (0 - 100)',
    ],
    zipRules: [
      v => !!v || 'Zip Code is required.',
      v => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || 'Zip code is invalid.'

    ],

    defaultClient: {
      id: 0,
      name: '',
      email: '',
      phone: '',
      street: '',
      street2: '',
      city: '',
      state: '',
      zip: '',
      fuel_discount: '',
    },

    states: [
      {
        "name": "Alabama",
        "abbreviation": "AL"
      },
      {
        "name": "Alaska",
        "abbreviation": "AK"
      },
      {
        "name": "American Samoa",
        "abbreviation": "AS"
      },
      {
        "name": "Arizona",
        "abbreviation": "AZ"
      },
      {
        "name": "Arkansas",
        "abbreviation": "AR"
      },
      {
        "name": "California",
        "abbreviation": "CA"
      },
      {
        "name": "Colorado",
        "abbreviation": "CO"
      },
      {
        "name": "Connecticut",
        "abbreviation": "CT"
      },
      {
        "name": "Delaware",
        "abbreviation": "DE"
      },
      {
        "name": "District Of Columbia",
        "abbreviation": "DC"
      },
      {
        "name": "Federated States Of Micronesia",
        "abbreviation": "FM"
      },
      {
        "name": "Florida",
        "abbreviation": "FL"
      },
      {
        "name": "Georgia",
        "abbreviation": "GA"
      },
      {
        "name": "Guam",
        "abbreviation": "GU"
      },
      {
        "name": "Hawaii",
        "abbreviation": "HI"
      },
      {
        "name": "Idaho",
        "abbreviation": "ID"
      },
      {
        "name": "Illinois",
        "abbreviation": "IL"
      },
      {
        "name": "Indiana",
        "abbreviation": "IN"
      },
      {
        "name": "Iowa",
        "abbreviation": "IA"
      },
      {
        "name": "Kansas",
        "abbreviation": "KS"
      },
      {
        "name": "Kentucky",
        "abbreviation": "KY"
      },
      {
        "name": "Louisiana",
        "abbreviation": "LA"
      },
      {
        "name": "Maine",
        "abbreviation": "ME"
      },
      {
        "name": "Marshall Islands",
        "abbreviation": "MH"
      },
      {
        "name": "Maryland",
        "abbreviation": "MD"
      },
      {
        "name": "Massachusetts",
        "abbreviation": "MA"
      },
      {
        "name": "Michigan",
        "abbreviation": "MI"
      },
      {
        "name": "Minnesota",
        "abbreviation": "MN"
      },
      {
        "name": "Mississippi",
        "abbreviation": "MS"
      },
      {
        "name": "Missouri",
        "abbreviation": "MO"
      },
      {
        "name": "Montana",
        "abbreviation": "MT"
      },
      {
        "name": "Nebraska",
        "abbreviation": "NE"
      },
      {
        "name": "Nevada",
        "abbreviation": "NV"
      },
      {
        "name": "New Hampshire",
        "abbreviation": "NH"
      },
      {
        "name": "New Jersey",
        "abbreviation": "NJ"
      },
      {
        "name": "New Mexico",
        "abbreviation": "NM"
      },
      {
        "name": "New York",
        "abbreviation": "NY"
      },
      {
        "name": "North Carolina",
        "abbreviation": "NC"
      },
      {
        "name": "North Dakota",
        "abbreviation": "ND"
      },
      {
        "name": "Northern Mariana Islands",
        "abbreviation": "MP"
      },
      {
        "name": "Ohio",
        "abbreviation": "OH"
      },
      {
        "name": "Oklahoma",
        "abbreviation": "OK"
      },
      {
        "name": "Oregon",
        "abbreviation": "OR"
      },
      {
        "name": "Palau",
        "abbreviation": "PW"
      },
      {
        "name": "Pennsylvania",
        "abbreviation": "PA"
      },
      {
        "name": "Puerto Rico",
        "abbreviation": "PR"
      },
      {
        "name": "Rhode Island",
        "abbreviation": "RI"
      },
      {
        "name": "South Carolina",
        "abbreviation": "SC"
      },
      {
        "name": "South Dakota",
        "abbreviation": "SD"
      },
      {
        "name": "Tennessee",
        "abbreviation": "TN"
      },
      {
        "name": "Texas",
        "abbreviation": "TX"
      },
      {
        "name": "Utah",
        "abbreviation": "UT"
      },
      {
        "name": "Vermont",
        "abbreviation": "VT"
      },
      {
        "name": "Virgin Islands",
        "abbreviation": "VI"
      },
      {
        "name": "Virginia",
        "abbreviation": "VA"
      },
      {
        "name": "Washington",
        "abbreviation": "WA"
      },
      {
        "name": "West Virginia",
        "abbreviation": "WV"
      },
      {
        "name": "Wisconsin",
        "abbreviation": "WI"
      },
      {
        "name": "Wyoming",
        "abbreviation": "WY"
      }
    ],

  }),

  mounted() {
      //this.client = this.clientToEdit;
  },

  methods:{
    save(){
      if(this.$refs.form.validate() === true){
        this.$emit('close',this.client)
        this.client = this.defaultClient;
        this.$refs.form.resetValidation()
      }
    },

    cancel(){
      this.$refs.form.resetValidation()
      this.$emit('close',null)
    },
  },

  computed:{
    client(){
      return this.$props.clientToEdit;
    }
  }

}
</script>

<style scoped>

</style>
