<template>
  <v-row no-gutters>
    <v-dialog v-model="showCardSelect" persistent max-width="900">
      <CardSummaryList :cards="summaries" @cancel="cancelAddCardEvent" @add="addCardEvent"></CardSummaryList>
    </v-dialog>
    <card-transaction-dialog
        :show="cardTransactionDialog.show"
        :title="cardTransactionDialog.title"
        :transaction="cardTransactionDialog.transaction"
        @cancel="cardTransactionDialog.show=false"
        @save="saveTransaction"
    ></card-transaction-dialog>
    <please-wait
      :title="pleaseWait.title"
      :show="pleaseWait.show"
      ></please-wait>
    <select-picker
        :show="driverPicker.show"
        :title="driverPicker.title"
        :save-button="driverPicker.saveButton"
        :items="driverPicker.drivers"
        :items-title="driverPicker.itemTitle"
        @cancel="driverPicker.show=false"
        @save="moveCard"
    ></select-picker>
    <efs-card-dialog :card="cardDialog.card" :show="cardDialog.show" @cancel="cardDialog.show=false" @save="saveCard"></efs-card-dialog>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-dialog
        v-model="efsCardDialog.show"
        persistent
        max-width="600px"
        min-height="200px"
    >
      <v-card>
        <v-card-title>View EFS Card {{ efsCardDialog.card?'#'+efsCardDialog.card.efs_card.cardNumber:'' }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-simple-table v-if="efsCardDialog.card"
                          dense
          >
            <template v-slot:default>
              <thead>
              <tr>
                <th class="text-left">
                  Name
                </th>
                <th class="text-left">
                  Value
                </th>
              </tr>
              </thead>
              <tbody>
              <tr
                  v-for="(value, name) in efsCardDialog.card.efs_card.header"
                  :key="name"
              >
                <td>{{ name }}</td>
                <td>{{ value }}</td>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="efsCardDialog.show=false"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <v-breadcrumbs
              style="background-color: #f5f5f5"
              :items="bread"
              large
          ></v-breadcrumbs>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" class="pa-5">
          <v-toolbar flat>
            <v-toolbar-title>EFS Card Manager</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
                :loading="loadingSummaries"
                :disabled="summaries.length === 0"
              outlined
              @click="showCardSelect=true"
              >
              <v-icon left v-if="summaries.length">mdi-plus</v-icon>
              {{ summaries.length?'Add Card':'no cards' }}
            </v-btn>
          </v-toolbar>
          <v-data-table
            :headers="headers"
            :items="cards"
            :loading="loading"
            v-on:dblclick:row="doubleClickedRow"
          >
            <template v-slot:item.balance="{ item }">
              <span v-if="item.balance > 0" class="balance text-red">{{ Number(item.balance).formatCurency() }}</span>
              <span v-else class="balance text-green">{{ Number(item.balance).formatCurency() }}</span>
            </template>
            <template v-slot:item.remain="{ item }">
              <span v-if="item.balance > 0 && (item.limit - item.balance) <= 0" class="balance text-red">{{ (item.limit - item.balance).formatCurency() }}</span>
             <span v-else class="balance text-green">{{ (item.limit - item.balance).formatCurency() }}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                  title="Edit card"
                  class="mr-2"
                  @click="editCard(item)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                  title="Move Card to another driver"
                  class="mr-2"
                  @click="moveCardClicked(item)"
              >
                mdi-truck-delivery
              </v-icon>
              <v-icon
                  title="View EFS Card Status"
                  class="mr-2"
                  @click="getEFSCard(item)"
              >
                mdi-eye
              </v-icon>
              <v-icon
                  title="Increase Card Account"
                  class="mr-2"
                  @click="creditAccount(item)"
              >
                mdi-credit-card-plus-outline
              </v-icon>
              <v-icon
                  title="Pay Card Account"
                  class="mr-2"
                  @click="debitAccount(item)"
              >
                mdi-alpha-p-circle-outline
              </v-icon>
              <v-icon
                  title="Card ledger"
                  class="mr-2"
                  @click="getLedger(item)"
              >
                mdi-google-spreadsheet
              </v-icon>
            </template>
          </v-data-table>

        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12">
          <json-viewer
              v-if="jsonData"
              :value="jsonData"
              :expand-depth=5
              copyable
              boxed
              expanded

          ></json-viewer>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import JsonViewer from 'vue-json-viewer'

import CardService from '@/services/efs/card.service'
import ClientService from '@/services/client.service'
import EfsCardDialog from "@/components/FundingViews/cards/EfsCardDialog";
import SelectPicker from "@/components/core/SelectPicker";
import PleaseWait from "@/components/PleaseWait";
import CardTransactionDialog from "@/components/FundingViews/cards/CardTransactionDialog";
import CardSummaryList from "@/components/FundingViews/cards/CardSummaryList";

export default {
  //7083050030636387644
  name: "CardManager",
  components: {CardSummaryList, CardTransactionDialog, PleaseWait, SelectPicker, EfsCardDialog, JsonViewer},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    showCardSelect: false,

    pleaseWait:{
      show: false,
      title: 'Getting Card from EFS...Please Wait...',
    },

    cardTransactionDialog:{
      show: false,
      title: '',
      transaction: {
        id: 0,
        card_id: 0,
        type: 1,
        description: '',
        reference: '',
        amount: 0,
        who: '',
      },
      defaultTransaction: {
        id: 0,
        card_id: 0,
        type: 1,
        description: '',
        reference: '',
        amount: 0,
        who: '',
      },
    },


    efsCardDialog:{
      show: false,
      card: null,
    },


    driverPicker:{
      show: false,
      title: 'Move to new Driver',
      itemTitle: 'Select Driver',
      saveButton: 'move',
      drivers: [],
      cardToMove: null,
    },
    cardDialog:{
      show: false,
      card: null,
    },
    loading: false,
    client: null,
    driver: null,
    cards: [],
    ledger: [],
    loadingLedger: false,

    headers: [
      {
        text: 'Card #',
        sortable: true,
        value: 'number'
      },
      {
        text: 'Status',
        sortable: true,
        value: 'status'
      },
      {
        text: 'Limit',
        sortable: true,
        align: 'right',
        value: 'limit'
      },
      {
        text: 'Balance',
        sortable: true,
        align: 'right',
        value: 'balance'
      },
      {
        text: 'Remaining',
        sortable: true,
        align: 'right',
        value: 'remain'
      },
      {
        text: 'Memo',
        sortable: true,
        value: 'memo'
      },

      {text: 'Actions', value: 'actions', sortable: false},
    ],

    jsonData: null,

    loadingSummaries: true,
    summaries: [],

  }),

  beforeMount() {
    this.client = this.$store.state.client
    this.driver = this.$store.state.driver
  },

  mounted() {
    if(!this.driver){
      this.$router.back();
    }
    this.getDriverSelectItems()
    this.getCards()
    this.cardSummaries()
  },

  methods:{

    addCardEvent(crd){
      this.showCardSelect = false
      let card = {
        cardNumber: crd.cardNumber,
        header: {
          companyXRef: "",
          handEnter: "POLICY",
          infoSource: "BOTH",
          limitSource: "POLICY",
          locationOverride: "0",
          locationSource: "POLICY",
          overrideAllLocations: "false",
          originalStatus: null,
          payrollStatus: "FOLLOWS",
          override: "0",
          policyNumber: "1",
          status: 'ACTIVE',
          timeSource: "POLICY",
          lastUsedDate: null,
          lastTransaction: "0",
          payrollUse: "B",
          payrollAtm: "POLICY",
          payrollChk: "POLICY",
          payrollAch: "POLICY",
          payrollWire: "POLICY",
          payrollDebit: "POLICY"
        },
        infos: [
          {
            infoId: "NAME",
            lengthCheck: "false",
            matchValue: "",
            maximum: "0",
            minimum: "0",
            reportValue: this.driver.first_name+' '+this.driver.last_name,
            validationType: "REPORT_ONLY",
            value: "0"
          },
          {
            infoId: "SSUB",
            lengthCheck: "false",
            matchValue: "",
            maximum: "0",
            minimum: "0",
            reportValue: this.client.name,
            validationType: "REPORT_ONLY",
            value: "0"
          },
          {
            infoId: "DRID",
            lengthCheck: "false",
            matchValue: Math.floor(100000 + Math.random() * 900000),
            maximum: "0",
            minimum: "0",
            reportValue: "",
            validationType: "EXACT_MATCH",
            value: "0"
          },
          {
            infoId: "UNIT",
            lengthCheck: "false",
            matchValue: "",
            maximum: "0",
            minimum: "0",
            reportValue: this.driver.truck_number,
            validationType: "REPORT_ONLY",
            value: "0"
          },

        ],
        limits: [],
        locationGroups: '1'
      }
      this.pleaseWait.title = "Adding Card.. Please Wait..."
      this.pleaseWait.show = true
      let _this = this
      CardService.addCard(this.driver.id,card)
          .then(function (response) {
            _this.card = response.data.card
            _this.dirty = false
            _this.snackBar.text = response.data.message;
            _this.snackBar.color = 'green';
            _this.snackBar.show = true;
            _this.getCards()
          })
          .catch(function (error) {
            _this.snackBar.text = "Error saving card!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.pleaseWait.show = false
          })

      console.log(card)
    },

    cancelAddCardEvent(){
      this.showCardSelect = false
    },

    cardSummaries(){
      this.loadingSummaries = true
      let _this = this
      CardService.summaries('ANY')
          .then(function (response) {
            _this.summaries = response.data.cards
            console.log(response.data)
          })
          .catch(function (error) {
            console.log(error)
          })
          .then(function () {
            _this.loadingSummaries = false
          })
    },


    doubleClickedRow(mouse_event,item){
      this.$store.commit('current_card', item.item)
      this.$router.push({name: 'EfsCard', params: {card: item.item} })
    },

    getLedger(card) {
      this.$store.commit('current_card', card)
      this.$router.push('/funding/cardledger')
    },

    saveTransaction(transaction){
      let _this = this
      ClientService.saveTransaction(transaction)
          .then(function (response) {
            _this.snackBar.text = response.data.message
            _this.snackBar.color = 'green'
            _this.snackBar.show = true;
            _this.getCards()
          })
          .catch(function (error) {
            _this.snackBar.text = "Error saving transaction!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.cardTransactionDialog.show = false
          })
    },

    creditAccount(card){
      this.cardTransactionDialog.title = 'Increase Card #'+card.number
      this.cardTransactionDialog.transaction = {...this.cardTransactionDialog.defaultTransaction}
      this.cardTransactionDialog.transaction.card_id = card.id
      this.cardTransactionDialog.transaction.type = 1
      this.cardTransactionDialog.show = true
    },

    debitAccount(card){
      this.cardTransactionDialog.title = 'Pay Card #'+card.number
      this.cardTransactionDialog.transaction = {...this.cardTransactionDialog.defaultTransaction}
      this.cardTransactionDialog.transaction.card_id = card.id
      this.cardTransactionDialog.transaction.type = 2
      this.cardTransactionDialog.show = true
    },

    getEFSCard(card){
      let _this = this
      this.pleaseWait.show = true
      ClientService.getEFSCard(card.id)
          .then(function (response) {
            _this.efsCardDialog.card = response.data
            _this.efsCardDialog.show = true
          })
          .catch(function (error) {
            _this.snackBar.text = "Error getting card!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.pleaseWait.show = false
          })
    },

    moveCardClicked(card){
      this.driverPicker.title = "Move card #"+card.number
      this.driverPicker.cardToMove = card
      this.driverPicker.show = true
    },

    moveCard(driver_id){
      let _this = this
      ClientService.moveCard(this.driverPicker.cardToMove.id,driver_id)
          .then(function (response) {
            _this.snackBar.text = response.data.message
            _this.snackBar.color = 'green'
            _this.snackBar.show = true;
            _this.getCards()
          })
          .catch(function (error) {
            _this.snackBar.text = "Error moving card!\n(" + error.response.status + ") " + error.response.statusText
            _this.snackBar.color = 'red'
            _this.snackBar.show = true
            console.log(error)
          })
          .then(function () {
            _this.driverPicker.show = false
            _this.driverPicker.cardToMove = undefined
          })
    },

    newCard(){
      this.cardDialog.card = null
      this.cardDialog.show = true
    },

    editCard(card){
      this.doubleClickedRow(undefined,{item: card})
      //this.cardDialog.card = card
      //this.cardDialog.show = true
    },

    saveCard(card){
        let _this = this
        card.driver_id = this.driver.id
        ClientService.saveCard(card)
            .then(function (response) {
              _this.snackBar.text = response.data.message
              _this.snackBar.color = 'green'
              _this.snackBar.show = true;
              _this.getCards()
            })
            .catch(function (error) {
              _this.snackBar.text = "Error saving card!\n(" + error.response.status + ") " + error.response.statusText
              _this.snackBar.color = 'red'
              _this.snackBar.show = true
              console.log(error)
            })
            .then(function () {
              _this.cardDialog.show = false
            })
    },

    getCards() {
      let _this = this
      this.loading = true
      ClientService.getCards(this.driver.id)
          .then(function (response) {
            _this.cards = response.data;
          })
          .catch(function (error) {
            console.log(error);
            let msg = _this.$errorMessage(error)
            _this.snackBar.text = "Error loading cards!\n(" + msg + ")"
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
          })
          .then(function () {
            _this.loading = false
          })
    },

    getDriverSelectItems() {
      let _this = this
      ClientService.driverSelectList(this.client.id)
          .then(function (response) {
            _this.driverPicker.drivers = response.data;
          })
          .catch(function (error) {
            console.log(error);
            let msg = _this.$errorMessage(error)
            _this.snackBar.text = "Error loading drivers!\n(" + msg + ")"
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
          })
          .then(function () {
          })
    },


  },


  computed: {

    cardList(){
      let items = []
      let cards = this.summaries
      for(let i=0;i<cards.length;i++){
        items.push({text: cards.cardNumber})
      }
      return items
    },

    bread() {
      return [
        {
          text: 'Clients',
          disabled: false,
          to: '/funding/clients',
        },
        {
          text: this.client.name,
          disabled: false,
          to: '/funding/client',
        },

        {
          text: this.driver.first_name+' '+this.driver.last_name,
          disabled: false,
          to: '/funding/driver',
        },
        {
          text: "Card Manager",
          disabled: false,
        },
      ]
    },
  },

}
</script>

<style scoped>

.balance {
  font-weight: bold;
}

.text-green{
  color: #1B5E20;
}

.text-red{
  color: #D50000;
}

</style>
