<template>
  <v-card>
    <v-card-title>
      <v-icon left>mdi-gas-station</v-icon>
      {{ item.item }}
    </v-card-title>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Amount</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <span style="font-weight: bold">{{item.amount.toLocaleString(undefined,{style:'currency', currency:'USD',minimumFractionDigits:2})}}</span>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Quantity</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <span style="font-weight: bold">{{item.quantity.toLocaleString()}}</span>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Avg PPU</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <span style="font-weight: bold">{{item.avg.toLocaleString()}}</span>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "FuelItem",
  props:{
    item:{
      type: Object,
      required: true
    }
  }

}
</script>

<style scoped>

</style>
