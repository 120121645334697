<template>
  <router-view/>
</template>

<script>

import MessageService from '@/services/message.service'

// checks to see if auth jwt token is valid or has expired, if it gets back 401 error log out
export default {
  created: function () {
    this.$http.interceptors.response.use((response) => {
      return response
    }, (error) => {
      if (error.response.status === 401) {
        if (this.$store.getters.authorized) {
          this.$store.dispatch('logout')
        } else {
          return Promise.reject(error)
        }
      } else {
        return Promise.reject(error)
      }
    })
  },

  mounted(){

    this.getMessageCounts()
    const _this = this
    const channel = this.$pusher.subscribe('message-counts');
    channel.bind('changed', function (data) {
      _this.$store.commit('message_counts',data.counts)
    });
  },

  beforeDestroy() {
    this.$pusher.unsubscribe('message-counts')
  },

  methods:{

    getMessageCounts(){
      const _this = this
      MessageService.unreadCount().then(
          response => {
            _this.$store.commit('message_counts', response.data)
          }
      ).catch(function (error) {
        console.log(error.response);
      })


    },
  },

}


</script>

<style lang="scss">
@import './styles/index.scss';
/* Remove in 1.2 */
.v-datatable thead th.column.sortable i {
  vertical-align: unset;
}
</style>
