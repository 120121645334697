/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
import store from '../store'

export default [
    {
        path: '*',
        meta: {
            name: '',
            requiresAuth: true
        },
        redirect: {
            path: '/dashboard'
        }
    },
    // This  allows you to have pages apart of the app but no rendered inside the dash
    {
        path: '/',
        meta: {
            name: '',
            requiresAuth: false
        },
        component: () =>
            import(/* webpackChunkName: "routes" */ `@/views/LoginHome.vue`),
        // redirect if already signed in
        beforeEnter: (to, from, next) => {
            if (store.getters.authorized) {
                next('/dashboard')
            } else {
                next()
            }
        },
        children: [
            {
                path: '/',
                component: () => import(`@/components/LoginForm.vue`)
            }
        ]
    },
    {
        path: '/dashboard',
        meta: {
            name: 'Dashboard',
            requiresAuth: true
        },
        component: () => import(`@/views/DashboardView.vue`),
        children: [
            {
                path: '',
                name: 'Dashboard',
                component: () => import(`@/components/DashViews/Dashboard.vue`)
            },
            {
                path: 'about',
                meta: {
                    name: 'About',
                    requiresAuth: true
                },
                component: () => import(`@/components/DashViews/About.vue`)
            },
        ]
    },
    {
        path: '/funding',
        meta: {
            name: 'Funding',
            requiresAuth: true
        },
        component: () => import(`@/views/FundingView.vue`),
        children: [
            {
                path: '',
                meta: {
                    name: 'Funding',
                    requiresAuth: true
                },
                name: 'Funding',
                component: () => import(`@/components/FundingViews/Funding.vue`)
            },
            {
                path: 'moneycodes',
                meta: {
                    name: 'Money Codes',
                    requiresAuth: true
                },
                name: 'Money Codes',
                component: () => import(`@/components/FundingViews/MoneyCodes.vue`)
            },
            {
                path: 'clients',
                meta: {
                    name: 'Clients',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/Clients.vue`),
            },
            {
                path: 'client',
                props: true,
                name: 'Client',
                meta: {
                    name: 'Client',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/GaClient.vue`)
            },
            {
                path: 'clientcreditmanager',
                props: true,
                name: 'Client Credit Manager',
                meta: {
                    name: 'Client Credit Manager',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/CreditManager.vue`)
            },
            {
                path: 'clientfuelreport',
                props: true,
                name: 'Client Fuel Report',
                meta: {
                    name: 'Client Fuel Report',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/ClientFuelReport.vue`)
            },
            {
                path: 'driverfuelreport',
                props: true,
                name: 'Driver Fuel Report',
                meta: {
                    name: 'Driver Fuel Report',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/DriverFuelReport.vue`)
            },
            {
                path: 'driver',
                props: true,
                name: 'Driver',
                meta: {
                    name: 'Driver',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/GaDriver.vue`)
            },
            {
                path: 'cardmanager',
                props: true,
                name: 'CardManager',
                meta: {
                    name: 'CardManager',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/cards/CardManager.vue`)
            },
            {
                path: 'cardledger',
                props: true,
                name: 'CardLedger',
                meta: {
                    name: 'CardLedger',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/cards/CardTransactionLedger.vue`)
            },
            {
                path: 'efscard',
                props: true,
                name: 'EfsCard',
                meta: {
                    name: 'EfsLedger',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/cards/EfsCard.vue`)
            },
            {
                path: 'policylist',
                props: true,
                name: 'PolicyList',
                meta: {
                    name: 'PolicyList',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/policys/PolicyList.vue`)
            },
            {
                path: 'reports',
                props: true,
                name: 'FundingReports',
                meta: {
                    name: 'FundingReports',
                    requiresAuth: true
                },
                component: () => import(`@/components/FundingViews/reports/FundingReports.vue`),
                children: [
                    {
                        path: 'all',
                        meta: {
                            name: 'FuwlAll',
                            requiresAuth: true
                        },
                        name: 'FuelAll',
                        component: () => import(`@/components/FundingViews/reports/FuelAll.vue`)
                    },
                    {
                        path: 'bystate',
                        meta: {
                            name: 'ByState',
                            requiresAuth: true
                        },
                        name: 'ByState',
                        component: () => import(`@/components/FundingViews/reports/FuelByState.vue`)
                    },
                ],
            },

        ]
    },
    {
        path: '/admin',
        meta: {
            name: 'Admin',
            requiresAuth: true
        },
        component: () => import(`@/views/AdminView.vue`),
        children: [
            {
                path: '',
                name: 'Admin',
                component: () => import(`@/components/AdminViews/Admin.vue`)
            },
            {
                path: 'users',
                name: 'Users',
                component: () => import(`@/components/AdminViews/Users.vue`)
            },
            {
                path: 'user',
                name: 'User',
                props: true,
                component: () => import(`@/components/AdminViews/JfUser.vue`)
            },
            {
                path: 'roles',
                name: 'Roles',
                component: () => import(`@/components/AdminViews/Roles.vue`)
            },
            {
                path: 'permissions',
                name: 'Permissions',
                component: () => import(`@/components/AdminViews/Permissions.vue`)
            },
            {
                path: 'log',
                name: 'Log',
                component: () => import(`@/components/AdminViews/ActivityLog.vue`)
            },
        ]
    }


]
