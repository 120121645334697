<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="800"
  >
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-card>
      <v-card-title>Add Limit</v-card-title>
      <v-row no-gutters>
        <v-col cols="12" class="px-5 mb-5">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                  :complete="step > 1"
                  step="1"
              >
                Limit ID
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                  :complete="step > 2"
                  step="2"
              >
                Details
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-select
                    label="Select Limit"
                    v-model="newLimit.limitId"
                    :items="limits"
                ></v-select>
                <v-btn color="primary" @click="step2">Next</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <div v-if="newLimit.limitId"><span class="font-weight-bold"> Limit ID:</span> {{$store.state.efsLimitIds.filter(efsLimitIds => efsLimitIds.name === newLimit.limitId)[0].description}}</div>
                <policy-limit :limit="newLimit" @change="limitChange"></policy-limit>
                <v-btn outlined @click="step=1">Back</v-btn>
                <v-btn class="ml-3" color="primary" @click="finish">Finish</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>

        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>

<script>
import PolicyLimit from "@/components/FundingViews/policys/PolicyLimit";
export default {
  name: "AddLimitsDialog",
  components: {PolicyLimit},

  props: {
    show: {
      type: Boolean,
      default: false
    },
  },


  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    step: 1,

    emptyLimit:{
      hours: 1,
      limit: 0,
      limitId: '',
      minHours: 0,
      autoRollMap: 127,
      autoRollMax: 0
    },
    newLimit: null,
    limitOption: 1,

  }),

  beforeMount() {
    this.step = 1
    this.newLimit = {...this.emptyLimit}
  },

  methods:{

    limitChange(option){
      this.limitOption = option
    },

    cancel(){
      this.$emit('cancel')
    },

    step2(){
      if(this.newLimit.limitId){
        this.step = 2
      }else{
        this.snackBar.text = "You must select a Limit Id!"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
      }
    },

    finish(){
      if(this.newLimit.limit <= 0 || this.newLimit.limit > 9999 ) {
        this.snackBar.text = "Limit Amount must be between 1 an 9999"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
        return
      }else if(this.limitOption === 0 && this.newLimit.hours <= 0){
        this.snackBar.text = "You must enter limit hours!"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
        return
      }

      this.$emit('addLimit',this.newLimit)
    },

  },

  computed:{
    limits(){
      let items = []
      for(let i=0;i<this.$store.state.efsLimitIds.length;i++){
        items.push({text: this.$store.state.efsLimitIds[i].name+' - '+this.$store.state.efsLimitIds[i].description, value: this.$store.state.efsLimitIds[i].name})
      }
      return items
    },

  },


  watch:{
    show(val){
      if(val === true){
        this.step = 1
        this.newLimit = {...this.emptyLimit}
      }
    }
  }

}
</script>

<style scoped>

</style>
