<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">New User</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      label="Full Name *"
                      v-model="user.name"
                      :rules="nameRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Email Address *"
                      v-model="user.email"
                      :rules="emailRules"
                      :counter="100"
                      required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Password *"
                      v-model="user.password"
                      :rules="passwordRules"
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      required
                      hint="'Password must contain one digit 0-9, one upper case A-Z, one lower case a-z, one special character !@#$%&*()-+=^'"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      label="Confirm Password *"
                      v-model="user.confirmed"
                      :rules="[(v) => v === user.password || 'Passwords must match!']"
                      :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="showConfirmPassword ? 'text' : 'password'"
                      @click:append="showConfirmPassword = !showConfirmPassword"
                      required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="red darken-1"
              text
              @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="save"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {

  name: "UserForm",

  props:{
    showDialog: {
      type: Boolean,
      default: false,
      required: true
    },
  },

  data: () => ({
    showPassword: false,
    showConfirmPassword: false,
    valid: false,
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length >= 3 && v.length <= 100) || 'Name must be between 3 and 100 characters.',
    ],
    emailRules: [
      v => !!v || 'E-mail is required',
      v => (v && v.length <= 100) || 'E-mail must be 100 characters or less',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],
    passwordRules: [
      v => !!v || 'Password is required',
      v => (v && v.length >= 8) || 'Password must be at least 8 characters',
      v => (/(?=.*[0-9])/.test(v)) || 'Password must contains at least one digit. (0-9)',
      v => (/(?=.*[a-z])/.test(v)) || 'Password must contains at least one lower case alphabet. (a-z)',
      v => (/(?=.*[A-Z])/.test(v)) || 'Password must contains at least one upper case alphabet. (A-Z)',
      v => (/(?=.*[!@#$%^&-+=()])/.test(v)) || 'Password must contains at least one special character which includes !@#$%&*()-+=^.',
    ],

    testRule: {
      email: v => {
        return this.checkEmail(v)
      }
    },

    user: {
      name: '',
      email: '',
      password: '',
      confirmed: '',
    },

    defaultUser: {
      name: '',
      email: '',
      password: '',
      confirmed: '',
    },
  }),


  methods:{

    checkEmail(){
      return this.user.confirmed === this.user.email;
    },

    save(){
      if(this.$refs.form.validate() === true){
        this.$emit('close',this.user)
        this.user = this.defaultUser;
        this.$refs.form.resetValidation()
      }
    },

    cancel(){
      this.$emit('close',null)
      this.$refs.form.resetValidation()
    }
  }


}
</script>

<style scoped>

</style>
