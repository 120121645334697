// https://vuex.vuejs.org/en/state.html

export default {
/*
    authStatus: '',
    token: localStorage.getItem('token') || '',
    userName: localStorage.getItem('userName') || '',
    userEmail: localStorage.getItem('userEmail') || '',
    uid: localStorage.getItem('uid') || 0,
    tableList: [],
    isAdmin: localStorage.getItem('isAdmin') || false,
    client: JSON.parse(localStorage.getItem('client')) || null,
    driver: JSON.parse(localStorage.getItem('driver')) || null,
    tokenExpires: localStorage.getItem('tokenExpires') || 0,
    card: JSON.parse(localStorage.getItem('card')) || null,
    messageCounts: JSON.parse(localStorage.getItem('messageCounts')) || null,
    permissions: {
        clientCreditView: true,
    },

 */

    authStatus: '',
    token: '',
    user: null,
    userName: '',
    userEmail: '',
    uid: 0,
    tableList: [],
    isAdmin: false,
    client:  null,
    driver:  null,
    tokenExpires:  0,
    card:  null,
    messageCounts: null,
    permissions: null,


    efsInfoIds: [
        {name: 'BDAY',description: 'Birthday'},
        {name: 'BLID', description: 'Billing ID'},
        {name: 'CNTN', description: 'Control Number'},
        {name: 'DLIC', description: 'Driver’s License #'},
        {name: 'DLST', description: 'Driver’s License State'},
        {name: 'DRID', description: 'Driver ID'},
        {name: 'EXPT', description: 'Expense Type'},
        {name: 'FSTI', description: 'First Initial'},
        {name: 'GLCD', description: 'GL Code'},
        {name: 'HBRD', description: 'Hubometer'},
        {name: 'HRRD', description: 'Reefer Hour Reading'},
        {name: 'LCST', description: 'License State'},
        {name: 'LICN', description: 'License #'},
        {name: 'LSTN', description: 'Last Name'},
        {name: 'NAME', description: 'Driver Name'},
        {name: 'ODRD', description: 'Odometer'},
        {name: 'OINV', description: 'Original Invoice'},
        {name: 'PONB', description: 'Purchase Order #'},
        {name: 'PPIN', description: 'Personal Identifier'},
        {name: 'RTMP', description: 'Reefer Temperature'},
        {name: 'SSEC', description: 'Social Security Number'},
        {name: 'SSUB', description: 'Sub Fleet Identifier'},
        {name: 'TLOC', description: 'Terminal Location'},
        {name: 'TRIP', description: 'Trip #'},
        {name: 'TRLR', description: 'Trailer #'},
        {name: 'UNIT', description: 'Unit #'},
    ],
    efsLimitIds: [
        {name: 'ADD', description: 'Additives'},
        {name: 'AMDS', description: 'Aviation Merchandise'},
        {name: 'ANFR', description: 'Anti-Freeze'},
        {name: 'AVGS', description: 'Aviation Gas'},
        {name: 'BDSL', description: 'BioDiesel'},
        {name: 'BRAK', description: 'Brakes and Wheels'},
        {name: 'CADV', description: 'Cash Advance'},
        {name: 'CLTH', description: 'Clothing'},
        {name: 'CNG', description: 'Compressed Natural Gas'},
        {name: 'COUP', description: 'Coupon'},
        {name: 'DEF', description: 'Diesel Exhaust Fluid Bulk'},
        {name: 'DEFC', description: 'Diesel Exhaust Fluid Container'},
        {name: 'DELI', description: 'Restaurant/Deli'},
        {name: 'DSL', description: 'Diesel'},
        {name: 'DSLM', description: 'Mexico Diesel'},
        {name: 'ELEC', description: 'Electronics'},
        {name: 'FAX', description: 'Faxdefc'},
        {name: 'FURN', description: 'Furnace Oil'},
        {name: 'GAS', description: 'Gasoline'},
        {name: 'GASM', description: 'Mexico Gas Magna'},
        {name: 'GASP', description: 'Premium Gas Mexico'},
        {name: 'GROC', description: 'C-Store/Groceries'},
        {name: 'HARD', description: 'Hardware/Accessories'},
        {name: 'IDLE', description: 'IdleAire'},
        {name: 'JET', description: 'Jet Fuel'},
        {name: 'KERO', description: 'Kerosene'},
        {name: 'LABR', description: 'Labor'},
        {name: 'LMPR', description: 'Lumper'},
        {name: 'LNG', description: 'Liquid Natural Gas'},
        {name: 'MDSL', description: 'Marked/Tax Exempt Diesel'},
        {name: 'MERC', description: 'Miscellaneous Merchandise'},
        {name: 'MGAS', description: 'Marked/Tax Exempt Gas'},
        {name: 'MRFR', description: 'Marked Reefer'},
        {name: 'NGAS', description: 'Natural Gas'},
        {name: 'OIL', description: 'Oil'},
        {name: 'OILC', description: 'Oil Change'},
        {name: 'PART', description: 'Parts'},
        {name: 'PHON', description: 'Phone Time'},
        {name: 'PNT', description: 'Paint'},
        {name: 'PROP', description: 'Propane'},
        {name: 'RECP', description: 'Recap'},
        {name: 'REPR', description: 'Repair Service'},
        {name: 'REST', description: 'Restaurant'},
        {name: 'RFND', description: 'Refund'},
        {name: 'RFR', description: 'Reefer'},
        {name: 'RFRM', description: 'Thermo'},
        {name: 'SCAN', description: 'Tons Imaging'},
        {name: 'SCLE', description: 'Weigh Scale'},
        {name: 'SHWR', description: 'Shower'},
        {name: 'SPLT', description: 'Split/Other Method of Payment'},
        {name: 'STAX', description: 'Sales Tax'},
        {name: 'TIRE', description: 'Tires/Tire Repairs'},
        {name: 'TOLL', description: 'Ambassador Bridge Toll'},
        {name: 'TRAL', description: 'Trailer'},
        {name: 'TRPP', description: 'Trip Permit'},
        {name: 'ULSD', description: 'Ultra Low Sulfur Diesel'},
        {name: 'WASH', description: 'Car Wash'},
        {name: 'WIFI', description: 'Fleet WiFi Billing'},
        {name: 'WWFL', description: 'Windshield Washer Fluid'},
    ],

}


