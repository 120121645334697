<template>
  <div>
    <v-row lass="pa-5">
      <v-col cols="12"> </v-col>

      <v-col cols="12">
        <v-row justify="center">
          <v-dialog
            v-model="showClientDialog"
            fullscreen
            transition="dialog-bottom-transition"
          >
            <v-card>
              <v-toolbar dark color="primary">
                <v-btn icon dark @click="cancel">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>
                  <span v-if="clientToEdit" class="headline">Edit Client</span>
                  <span v-else class="headline">Add Client</span>
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          label="Client Name *"
                          placeholder="Grade A Trucking"
                          v-model="clientToEdit.name"
                          :rules="nameRules"
                          :counter="100"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Address *"
                          placeholder="1005 Rightway Ave"
                          v-model="clientToEdit.street"
                          :rules="addressRules"
                          :counter="100"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Address Line 2"
                          placeholder="Suite 11"
                          v-model="clientToEdit.street2"
                          :counter="100"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-text-field
                          label="City"
                          placeholder="Huntingburg"
                          v-model="clientToEdit.city"
                          :rules="cityRules"
                          :counter="100"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-select
                          label="State"
                          placeholder="Indiana"
                          v-model="clientToEdit.state"
                          :items="states"
                          item-text="name"
                          item-value="abbreviation"
                          :rules="stateRules"
                          required
                        ></v-select>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-text-field
                          label="Zip Code"
                          placeholder="47542"
                          v-model="clientToEdit.zip"
                          :rules="zipRules"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-text-field
                          label="Phone Number"
                          placeholder="8126831234"
                          v-model="clientToEdit.phone"
                          :rules="phoneRules"
                          :counter="100"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-text-field
                          label="E-Mail Address"
                          placeholder="greg@gradeafunding.com"
                          v-model="clientToEdit.email"
                          :rules="emailRules"
                          :counter="100"
                          required
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-4">
                        <v-select
                          label="Fuel Report"
                          :items="fuelReportItems"
                          v-model="clientToEdit.fuel_report"
                        ></v-select>
                      </v-col>
                      <v-col cols="12" class="col-lg-3">
                        <v-text-field
                          label="Fuel Fee"
                          placeholder="20"
                          v-model="clientToEdit.fuel_discount"
                          :rules="fuelDiscountRules"
                          required
                          type="number"
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-3">
                        <v-text-field
                          label="Minimum Fee"
                          placeholder="15.00"
                          v-model="clientToEdit.minimum_fee"
                          required
                          type="number"
                          prefix="$"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-3">
                        <v-text-field
                          label="Transaction Fee"
                          placeholder="30.00"
                          v-model="clientToEdit.transaction_fee"
                          :rules="transactionFeeRules"
                          required
                          type="number"
                          suffix="%"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" class="col-lg-3 text-right">
                      <v-switch
                        label="Active"
                        v-model="clientToEdit.active"
                      ></v-switch>
                    </v-col>

                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="cancel">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-col>
      <v-col v-if="1" cols="12">
        <v-snackbar
          v-model="snackBar.show"
          :timeout="snackBar.timeout"
          :color="snackBar.color"
          top
          multi-line
          >{{ snackBar.text }}</v-snackbar
        >
        <v-row>
          <v-col cols="12">
            <v-data-table
              style="cursor: pointer"
              :items="filteredClients"
              :headers="headers"
              sort-by="name"
              :search="search"
              v-on:dblclick:row="doubleClickedRow"
              :loading="loading"
            >
              <template v-slot:top>
                <v-toolbar flat>
                  <v-btn icon color="blue" @click="getClients" :loading="loading" :disabled="loading">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                  <v-toolbar-title>Client's</v-toolbar-title>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                  ></v-text-field>
                  <v-spacer></v-spacer>
                              <v-switch
              v-if="inactiveClients > 0"
              class="pr-5 pt-5"
              v-model="showInactive"
              :label="
                showInactive
                  ? 'Hide ' + inactiveClients + ' Inactive'
                  : 'Show ' + inactiveClients + ' Inactive'
              "
            ></v-switch>

                  <v-btn color="primary" @click="newClient">
                    <v-icon left>mdi-plus</v-icon>
                    New Client
                  </v-btn>
                </v-toolbar>
              </template>
              <template v-slot:item.active_drivers="{item}">
                <span v-if="item.active_drivers === 0">0</span>
                <span v-else style="color: green;font-weight: bold;">{{item.active_drivers}}</span>
              </template>
              <template v-slot:item.inactive_drivers="{item}">
                <span v-if="item.inactive_drivers === 0">0</span>
                <span v-else style="color: red;font-weight: bold;">{{item.inactive_drivers}}</span>
              </template>
              <template v-slot:item.name="{ item }">
                <v-icon v-if="!item.active" small color="red">mdi-close</v-icon>
                <v-badge
                  v-if="messageCount(item)"
                  left
                  :content="messageCount(item)"
                  color="red"
                  class="ml-3 mr-2"
                >
                </v-badge>
                {{ item.name }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small class="mr-2" @click="viewClient(item)">
                  mdi-eye
                </v-icon>

                <v-icon small class="mr-2" @click="editClient(item)">
                  mdi-pencil
                </v-icon>
                <v-icon small @click="deleteClient(item)"> mdi-delete </v-icon>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-else cols="12"> Access to this page is restricted! </v-col>
    </v-row>
  </div>
</template>

<script>
import ClientService from "@/services/client.service";

export default {
  name: "Clients",
  data: () => ({
    loading: false,

    snackBar: {
      show: false,
      text: "",
      timeout: 5000,
      color: "green",
    },

    showInactive: false,
    showClientDialog: false,
    editedIndex: -1,
    clientToEdit: null,
    defaultClient: {
      id: 0,
      name: "",
      email: "",
      phone: "",
      street: "",
      street2: "",
      city: "",
      state: "",
      zip: "",
      fuel_discount: "",
      minimum_fee: 15.0,
      transaction_fee: 30.0,
      fuel_report: null,
      active: true,
    },

    fuelReportItems: ["None", "Daily", "Weekly", "Both"],

    search: null,
    clientList: [],
    headers: [
      {
        text: "Name",
        sortable: true,
        value: "name",
      },
      {
        text: "A",
        sortable: true,
        value: "active_drivers",
        align: "end"
      },
      {
        text: "I",
        sortable: true,
        value: "inactive_drivers",
        align: "end"
      },
      {
        text: "Phone Number",
        sortable: true,
        value: "phone",
      },
      {
        text: "City",
        sortable: true,
        value: "city",
      },
      {
        text: "State",
        sortable: true,
        value: "state",
      },
      {
        text: "Zip",
        sortable: true,
        value: "zip",
      },

      {
        text: "Email Address",
        sortable: true,
        value: "email",
      },
      {
        text: "Fuel Report",
        value: "fuel_report",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],

    valid: false,
    nameRules: [
      (v) => !!v || "Name is required",
      (v) => (v && v.length <= 100) || "Name must be 100 characters or less",
    ],
    addressRules: [
      (v) => !!v || "Address is required",
      (v) => (v && v.length <= 100) || "Address must be 100 characters or less",
    ],
    cityRules: [
      (v) => !!v || "City is required",
      (v) => (v && v.length <= 100) || "City must be 100 characters or less",
    ],
    stateRules: [(v) => !!v || "State is required"],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => (v && v.length <= 100) || "E-mail must be 100 characters or less",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    phoneRules: [
      (v) => !!v || "Phone Number is required.",
      (v) =>
        /^[(]?[0-9]{3}[)]?[-\s]?[0-9]{3}[-\s]?[0-9]{4,6}$/im.test(v) ||
        "Phone number is invalid.",
    ],
    fuelDiscountRules: [
      (v) => (v >= 0 && v <= 100) || "Discount must be (0 - 100)",
    ],
    transactionFeeRules: [
      (v) => (v >= 0 && v <= 100) || "Transaction fee must be (0 - 100)",
    ],
    zipRules: [
      (v) => !!v || "Zip Code is required.",
      (v) => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(v) || "Zip code is invalid.",
    ],
    states: [
      {
        name: "Alabama",
        abbreviation: "AL",
      },
      {
        name: "Alaska",
        abbreviation: "AK",
      },
      {
        name: "American Samoa",
        abbreviation: "AS",
      },
      {
        name: "Arizona",
        abbreviation: "AZ",
      },
      {
        name: "Arkansas",
        abbreviation: "AR",
      },
      {
        name: "California",
        abbreviation: "CA",
      },
      {
        name: "Colorado",
        abbreviation: "CO",
      },
      {
        name: "Connecticut",
        abbreviation: "CT",
      },
      {
        name: "Delaware",
        abbreviation: "DE",
      },
      {
        name: "District Of Columbia",
        abbreviation: "DC",
      },
      {
        name: "Federated States Of Micronesia",
        abbreviation: "FM",
      },
      {
        name: "Florida",
        abbreviation: "FL",
      },
      {
        name: "Georgia",
        abbreviation: "GA",
      },
      {
        name: "Guam",
        abbreviation: "GU",
      },
      {
        name: "Hawaii",
        abbreviation: "HI",
      },
      {
        name: "Idaho",
        abbreviation: "ID",
      },
      {
        name: "Illinois",
        abbreviation: "IL",
      },
      {
        name: "Indiana",
        abbreviation: "IN",
      },
      {
        name: "Iowa",
        abbreviation: "IA",
      },
      {
        name: "Kansas",
        abbreviation: "KS",
      },
      {
        name: "Kentucky",
        abbreviation: "KY",
      },
      {
        name: "Louisiana",
        abbreviation: "LA",
      },
      {
        name: "Maine",
        abbreviation: "ME",
      },
      {
        name: "Marshall Islands",
        abbreviation: "MH",
      },
      {
        name: "Maryland",
        abbreviation: "MD",
      },
      {
        name: "Massachusetts",
        abbreviation: "MA",
      },
      {
        name: "Michigan",
        abbreviation: "MI",
      },
      {
        name: "Minnesota",
        abbreviation: "MN",
      },
      {
        name: "Mississippi",
        abbreviation: "MS",
      },
      {
        name: "Missouri",
        abbreviation: "MO",
      },
      {
        name: "Montana",
        abbreviation: "MT",
      },
      {
        name: "Nebraska",
        abbreviation: "NE",
      },
      {
        name: "Nevada",
        abbreviation: "NV",
      },
      {
        name: "New Hampshire",
        abbreviation: "NH",
      },
      {
        name: "New Jersey",
        abbreviation: "NJ",
      },
      {
        name: "New Mexico",
        abbreviation: "NM",
      },
      {
        name: "New York",
        abbreviation: "NY",
      },
      {
        name: "North Carolina",
        abbreviation: "NC",
      },
      {
        name: "North Dakota",
        abbreviation: "ND",
      },
      {
        name: "Northern Mariana Islands",
        abbreviation: "MP",
      },
      {
        name: "Ohio",
        abbreviation: "OH",
      },
      {
        name: "Oklahoma",
        abbreviation: "OK",
      },
      {
        name: "Oregon",
        abbreviation: "OR",
      },
      {
        name: "Palau",
        abbreviation: "PW",
      },
      {
        name: "Pennsylvania",
        abbreviation: "PA",
      },
      {
        name: "Puerto Rico",
        abbreviation: "PR",
      },
      {
        name: "Rhode Island",
        abbreviation: "RI",
      },
      {
        name: "South Carolina",
        abbreviation: "SC",
      },
      {
        name: "South Dakota",
        abbreviation: "SD",
      },
      {
        name: "Tennessee",
        abbreviation: "TN",
      },
      {
        name: "Texas",
        abbreviation: "TX",
      },
      {
        name: "Utah",
        abbreviation: "UT",
      },
      {
        name: "Vermont",
        abbreviation: "VT",
      },
      {
        name: "Virgin Islands",
        abbreviation: "VI",
      },
      {
        name: "Virginia",
        abbreviation: "VA",
      },
      {
        name: "Washington",
        abbreviation: "WA",
      },
      {
        name: "West Virginia",
        abbreviation: "WV",
      },
      {
        name: "Wisconsin",
        abbreviation: "WI",
      },
      {
        name: "Wyoming",
        abbreviation: "WY",
      },
    ],
  }),

  beforeMount() {
    this.clientToEdit = this.defaultClient;
  },

  mounted() {
    this.getClients();
  },

  methods: {
    messageCount(client) {
      const counts = this.$store.state.messageCounts;
      for (let i = 0; i < counts.clients.length; i++) {
        if (counts.clients[i].id === client.id) {
          return counts.clients[i].count;
        }
      }
      return 0;
    },

    viewClient(client) {
      this.$store.commit("current_client", client);
      this.$router.push({ name: "Client", params: { client: client } });
    },

    doubleClickedRow(mouse_event, item) {
      this.$store.commit("current_client", item.item);
      this.$router.push({ name: "Client", params: { client: item.item } });
    },

    getClients() {
      this.loading = true;
      let _this = this;
      ClientService.clientList()
        .then(function (response) {
          _this.clientList = response.data;
        })
        .catch(function (error) {
          console.log(error);
          let msg = _this.$errorMessage(error);
          _this.snackBar.text = "Error loading client!\n(" + msg + ")";
          _this.snackBar.color = "red";
          _this.snackBar.show = true;
        })
        .then(function () {
          _this.loading = false;
        });
    },

    newClient() {
      this.editedIndex = -1;
      this.clientToEdit = Object.assign({}, this.defaultClient);
      this.showClientDialog = true;
    },

    editClient(client) {
      this.editedIndex = this.clientList.indexOf(client);
      this.clientToEdit = Object.assign({}, client);
      this.showClientDialog = true;
    },
    deleteClient(item) {
      console.log(item);
    },

    closeDialog(client) {
      this.showClientDialog = false;
      if (client) {
        let _this = this;
        if (client.id > 0) {
          ClientService.updateClient(client)
            .then(function (response) {
              Object.assign(
                _this.clientList[_this.editedIndex],
                response.data.client
              );
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;

              //_this.getClients()
            })
            .catch(function (error) {
              console.log(error);
              _this.snackBar.text =
                "Error adding client!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
              console.log(error.response);
            });
        } else {
          ClientService.addClient(client)
            .then(function (response) {
              _this.snackBar.text = response.data.message;
              _this.snackBar.color = "green";
              _this.snackBar.show = true;
              //_this.getClients()
              _this.clientList.push(response.data.client);
            })
            .catch(function (error) {
              _this.snackBar.text =
                "Error adding client!\n(" +
                error.response.status +
                ") " +
                error.response.statusText;
              _this.snackBar.color = "red";
              _this.snackBar.show = true;
              console.log(error.response);
            });
        }
      }
    },

    save() {
      if (this.$refs.form.validate() === true) {
        this.closeDialog(this.clientToEdit);
      }
    },

    cancel() {
      this.$refs.form.resetValidation();
      this.showClientDialog = false;
    },
  },

  computed: {
        inactiveClients() {
      return this.clientList.filter((client) => client.active == false).length;
    },

    filteredClients() {
      if (this.showInactive) {
        return this.clientList;
      }
      return this.clientList.filter((client) => client.active == true);
    },

  },

  watch: {
    showClientDialog() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>

<style scoped>
</style>
