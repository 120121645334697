<template>
  <v-dialog
      v-model="show"
      max-width="800"
      persistent
  >
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-card>
      <v-card-title>Add Info Prompt</v-card-title>
      <v-row no-gutters>
        <v-col cols="12" class="px-5 mb-5">
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step
                  :complete="step > 1"
                  step="1"
              >
                Prompt ID
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                  :complete="step > 2"
                  step="2"
              >
                Validation
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                  :complete="step > 3"
                  step="3"
              >
                <div v-if="newPrompt.validationType === 'REPORT_ONLY'">Report Value</div>
                <div v-else-if="newPrompt.validationType === 'EXACT_MATCH'">Match Value</div>
                <div v-else>Value</div>
              </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-select
                    label="Prompt For"
                    v-model="newPrompt.infoId"
                    :items="prompts"
                ></v-select>
                <v-btn color="primary" @click="step2">Next</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
              </v-stepper-content>
              <v-stepper-content step="2">
                <div v-if="newPrompt.infoId"><span class="font-weight-bold"> Prompt ID:</span> {{$store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === newPrompt.infoId)[0].description}}</div>
                <v-select
                    label="Validation Type"
                    v-model="newPrompt.validationType"
                    :items="validations"
                ></v-select>
                <v-btn color="primary" @click="step=1">Back</v-btn>
                <v-btn class="ml-3" color="primary" @click="step3">Next</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>
              </v-stepper-content>
              <v-stepper-content step="3">
                <div v-if="newPrompt.infoId"><span class="font-weight-bold"> Prompt ID:</span> {{$store.state.efsInfoIds.filter(efsInfoIds => efsInfoIds.name === newPrompt.infoId)[0].description}}</div>
                <div v-if="newPrompt.validationType"><span class="font-weight-bold"> Validation:</span> {{validations.filter(validation => validation.value === newPrompt.validationType)[0].text}}</div>
                <v-text-field
                    v-if="newPrompt.validationType === 'REPORT_ONLY'"
                    label="Report Value"
                    v-model="newPrompt.reportValue"
                ></v-text-field>
                <v-text-field
                    v-else-if="newPrompt.validationType === 'EXACT_MATCH'"
                    label="Match Value"
                    v-model="newPrompt.matchValue"
                ></v-text-field>
                <v-btn color="primary" @click="step=2">Back</v-btn>
                <v-btn class="ml-3" color="primary" @click="lastStep">Finish</v-btn>
                <v-btn class="ml-3" color="red" @click="cancel">Cancel</v-btn>

              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-col>
      </v-row>
    </v-card>

  </v-dialog>
</template>

<script>
export default {
  name: "AddInfosDialog",

  props: {
    show: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({

    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },


    step: 1,

emptyPrompt:{
      infoId: '',
      lengthCheck: 'false',
      matchValue: '',
      maximum: 0,
      minimum: 0,
      reportValue: '',
      validationType: '',
      value: 0
    },
    newPrompt: null,

    validations: [
      {
        text: "Exact Match",
        value: "EXACT_MATCH"
      },
      {
        text: "Report Only",
        value: "REPORT_ONLY"
      },
    ],
  }),

  beforeMount() {
    this.step = 1
    this.newPrompt = {...this.emptyPrompt}
  },

  mounted() {
  },

  methods:{

    cancel(){
      this.$emit('cancel')
    },

    step2(){
      if(this.newPrompt.infoId){
        this.step = 2
      }else{
        this.snackBar.text = "You must select a Prompt Id!"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
      }
    },

    step3(){
      if(this.newPrompt.validationType){
        this.step = 3
      }else{
        this.snackBar.text = "You must select a Validation Type!"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
      }
    },

    lastStep(){
      if(this.newPrompt.validationType === 'REPORT_ONLY' && this.newPrompt.reportValue) {
        this.newPrompt.matchValue = ''
      }else if(this.newPrompt.validationType === 'EXACT_MATCH' && this.newPrompt.matchValue){
        this.newPrompt.reportValue = ''
      }else{
        this.snackBar.text = "You must enter a Value!"
        this.snackBar.color = 'red';
        this.snackBar.show = true;
        return
      }

      this.$emit('addPrompt',this.newPrompt)

      /*
      if(typeof this.policy.infos === 'undefined'){ //this should never happen since infos is defined in the api if missing
        Object.defineProperty(this.policy,'infos',{
          value: [],
          writable: true
        })
      }
      this.policy.infos.push(this.newPrompt)
      this.adding = false
      this.dirty = true

       */
    },

  },

  computed: {

    prompts() {
      let items = []
      for (let i = 0; i < this.$store.state.efsInfoIds.length; i++) {
        items.push({text: this.$store.state.efsInfoIds[i].description, value: this.$store.state.efsInfoIds[i].name})
      }
      return items
    },
  },

    watch:{
    show(){
      if(this.show === true){
        this.step = 1
        this.newPrompt = {...this.emptyPrompt}
      }
    }
  }

}
</script>

<style scoped>

</style>
