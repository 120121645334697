<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="320" height="200">
    <defs>
      <linearGradient id="grad1" gradientTransform="rotate(90)">
        <stop offset="0%"  stop-color="rgba(0,0,50,1)" />
        <stop offset="50%"  stop-color="rgba(0,0,200,1)" />
        <stop offset="100%" stop-color="rgba(0,0,50,1)" />
      </linearGradient>
    </defs>
    <rect width="320" height="200" rx="5" style="fill:url('#grad1')"></rect>
    <image href="/assets/images/logo.svg" x="5" y="5" width="64"></image>
    <text x="74" y="25" style="fill: whitesmoke;font-size: 20px">Money Code</text>
    <text x="74" y="44" style="fill: whitesmoke;font-size: 16px">{{title}}</text>
    <text x="74" y="60" style="fill: whitesmoke;font-size: 14px">{{name}}</text>
    <text x="40" y="110" style="fill: whitesmoke;font-size: 30px">{{code.split('').join(' ')}}</text>
    <text x="40" y="125" style="fill: whitesmoke;font-size: 12px">{{formattedDate}}</text>
    <text x="278" y="130" style="fill: whitesmoke;font-size: 16px" text-anchor="end">{{amount}}</text>
    <text x="20" y="160" style="fill: whitesmoke;font-size: 16px">{{broker}}</text>
    <text x="300" y="190" style="fill: whitesmoke;font-size: 16px" text-anchor="end">{{load}}</text>
  </svg>
</template>

<script>

import moment from "moment";


export default {
  name: "MoneyCodeCard",

  props:{
    title:{
      type: String,
      default: "My Company"
    },
    code:{
      type: String,
      default: "0123456789"
    },
    amount:{
      type: String,
      default: "0.00"
    },
    name:{
      type: String,
      default: "John Doe"
    },
    broker:{
      type: String,
      default: "Joes Trucking"
    },
    load:{
      type: String,
      default: "XYZ-989-37y8"
    },
    date:{
      type: String,
      default: "0000-00-00"
    },
  },


  data: () => ({
  }),

  methods:{
  },

  computed:{
    formattedDate(){
      let d = moment(this.date)
      return d.format('MM/DD/YYYY')
    }
  }

}
</script>

<style scoped>

</style>
