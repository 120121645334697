<template>
  <v-row class="pa-3">
    <v-col></v-col>
  </v-row>
</template>

<script>
export default {
  name: "Admin",
  mounted() {
    this.$router.push('/admin/users')
  }
}
</script>

<style scoped>

</style>
