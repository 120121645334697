import axios from 'axios'

class CardService {

    addCard(driver_id, efs_card) {
        return axios.post('/efs/card/add', {driver_id: driver_id, efs_card: efs_card})
    }

    saveCard(card, efs_card) {
        return axios.post('/efs/card/save', {card: card, efs_card: efs_card})
    }
    summaries(status){
        return axios.post('/efs/card/summaries', {status: status})
    }
}

export default new CardService()
