<template>
  <v-img
      src="/assets/images/trucker1.jpg"
      height="100vh"
      gradient="rgba(255,255,255,.9),rgba(255,255,255,.5), rgba(255,255,255,0)"
  >
    <v-snackbar
        v-model="snackbar"
        :color="color"
        :top="true"
    >
      {{ errorMessages }}
      <v-btn
          dark
          text
          @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>

    <v-row
        class="fill-height pt-16"
        justify="center"

    >

      <v-col cols="12" class="col-xl-4">
        <v-container class="text-center">
          <v-sheet class="py-10 d-flex justify-center" color="transparent">
            <v-img
                max-width="200"
                src="/assets/images/logo.svg"></v-img>
          </v-sheet>
          <v-card class="text-center" color="rgba(230,230,230,.9)" v-if="showLogin">
            <v-card-title>Sign In</v-card-title>
            <v-card-text>
              <v-form v-model="valid" ref="loginform">
                <v-text-field
                    name="email"
                    ref="email"
                    v-model="email"
                    :rules="[(v) => !!email || 'This field is required',(v) => /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid']"
                    prepend-icon="mdi-account"
                    label="Login"
                    placeholder="E-mail address"
                    required
                />
                <v-text-field

                    ref="password"
                    v-model="password"
                    :rules="[() => !!password || 'This field is required']"
                    :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    placeholder="*********"
                    counter
                    required
                    @keydown.enter="login"
                    @click:append="showPassword = !showPassword"
                />
              </v-form>
            </v-card-text>
            <v-divider class="mt-5"/>
            <v-card-actions>
              <v-btn
                  block
                  text
                  color="general"
                  @click="login"
                  :loading="loggingIn"
              >
                Login
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-card
              v-else
              class="text-center"
              color="rgba(230,200,200,.9)"
          >
            <v-card-title>You Must Change Your Password</v-card-title>
            <v-card-text>
              <v-form v-model="pu.valid" ref="pwupdateform">
                <v-text-field
                    v-model="pu.password"
                    :rules="passwordRules"
                    :append-icon="pu.showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="pu.showPassword ? 'text' : 'password'"
                    prepend-icon="mdi-lock"
                    label="Password"
                    placeholder="*********"
                    counter
                    required
                    @keydown.enter="login"
                    @click:append="pu.showPassword = !pu.showPassword"
                    autofocus
                />
                <v-text-field
                    v-model="pu.password2"
                    :rules="[(v) => v === pu.password || 'Passwords do not match!']"
                    :append-icon="pu.showPassword2 ? 'mdi-eye-off' : 'mdi-eye'"
                    :type="pu.showPassword2 ? 'text' : 'password'"
                    prepend-icon="mdi-lock-plus"
                    label="Repete Password"
                    placeholder="*********"
                    counter
                    required
                    @keydown.enter="login"
                    @click:append="pu.showPassword2 = !pu.showPassword2"
                />
              </v-form>
            </v-card-text>
            <v-divider class="mt-5"/>
            <v-card-actions>
              <v-btn
                  block
                  text
                  color="general"
                  @click="updatePassword"
                  :loading="pu.saving"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-sheet class="pt-3" color="transparent">
            <small>Copyright© 2020 All Rights Reserved</small>
          </v-sheet>

        </v-container>
      </v-col>
    </v-row>

  </v-img>

</template>

<script>

import UserService from '@/services/user.service'

export default {

  data: function () {
    return {
      valid: false,
      email: '',
      password: '',
      errorMessages: 'Incorrect login info\n',
      snackbar: false,
      color: 'red',
      showPassword: false,
      loggingIn: false,
      showLogin: true,
      passwordRules: [
        v => !!v || 'Password is required',
        v => (v && v.length >= 8) || 'Password must be at least 8 characters',
        v => (/(?=.*[0-9])/.test(v)) || 'Password must contains at least one digit. (0-9)',
        v => (/(?=.*[a-z])/.test(v)) || 'Password must contains at least one lower case alphabet. (a-z)',
        v => (/(?=.*[A-Z])/.test(v)) || 'Password must contains at least one upper case alphabet. (A-Z)',
        v => (/(?=.*[!@#$%^&-+=()])/.test(v)) || 'Password must contains at least one special character which includes !@#$%&*()-+=^.',
      ],
      pu: {
        valid: true,
        password: '',
        password2: '',
        showPassword: false,
        showPassword2: false,
        saving: false
      }


    }
  },

  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    login: function () {

      if(!this.valid){
        this.snackbar = true;
        return;
      }

      this.loggingIn = true;

      let email = this.email
      let password = this.password
      this.$store.dispatch('login', { email, password })
          .then(
              response => {
                this.loggingIn = false;
                if(response.data.user.change_password){
                  this.showLogin = false;
                }else{
                  this.$router.push('/funding')
                }
              }
          )
          .catch(err => {
            this.loggingIn = false;
            console.log(err)
                this.snackbar = true
              }
          )
    },

    updatePassword(){
      if(this.$refs.pwupdateform.validate() === true){
        this.$refs.pwupdateform.resetValidation()
        this.pu.saving = true;
        let _this = this
        UserService.updatePassword(this.email,this.password,this.pu.password)
            .then(function () {
              _this.pu.saving = false
              _this.password = _this.pu.password
              _this.login()
            })
            .catch(function (error) {
              _this.pu.saving = false
              _this.errorMessages = "Error updating password"
              _this.snackbar = true
              console.log(error.response)
            })
      }
    },


  },
  watch: {
    showLogin() {
      if(this.$refs.loginform){
        this.$refs.loginform.resetValidation()
      }
      if(this.$refs.pwupdateform){
        this.$refs.pwupdateform.resetValidation()
      }
    }
  }
}
</script>
