// https://vuex.vuejs.org/en/mutations.html

export default {
    auth_request (state) {
        state.authStatus = 'loading'
    },
    auth_success (state, data) {
        state.user = data.user
        state.authStatus = 'success'
        state.token = data.token
        state.userName = data.user.name
        state.userEmail = data.user.email
        state.uid = data.user.id
        state.isAdmin = data.isAdmin
        state.tokenExpires = data.expires
        state.permissions = data.permissions
    },
    auth_error (state) {
        state.authStatus = 'error'
    },
    logout (state) {
        state.authStatus = ''
        state.token = ''
        state.isAdmin = false
        state.userName = ''
        state.userEmail = ''
        state.uid = 0
        state.tokenExpires = 0
    },

    setTableList (state, tableList) {
        state.tableList = tableList
    },

    current_client(state,client){
        state.client = client
        //localStorage.setItem('client',JSON.stringify(client));
    },

    current_driver(state,driver){
        state.driver = driver
        //localStorage.setItem('driver',JSON.stringify(driver));
    },

    current_card(state,card){
        state.card = card
        //localStorage.setItem('card',JSON.stringify(card));
    },

    message_counts(state,counts){
        state.messageCounts = counts
        //localStorage.setItem('messageCounts',JSON.stringify(counts));
    }

}
