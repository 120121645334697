import axios from 'axios';

class UserService {

    users() {
        return axios.post('auth/users', {});
    }

    addUser(user){
        return axios.post('auth/register', {name:user.name, email:user.email,password:user.password,password_confirmation:user.confirmed});
    }

    disableUser(user_id) {
        return axios.post('auth/admin/disableuser', {
            user_id: user_id,
        });
    }

    enableUser(user_id) {
        return axios.post('auth/admin/enableuser', {
            user_id: user_id,
        });
    }

    setChangePassword(user_id,to) {
        return axios.post('auth/setchangepassword', {
            user_id: user_id,
            to: to
        });
    }

    updatePassword(email,password,new_password){
        return axios.post('auth/updatepassword', {
            email: email,
            password: password,
            new_password: new_password
        });

    }

    userCan(permission){
        return axios.post('auth/usercan', {
            permission: permission
        });
    }

}
export default new UserService();

