import axios from 'axios';

class PermissionService {

    hasRole(role) {
        return axios.post('auth/has-role', {
            role: role,
        });
    }

    hasPermission(permission) {
        return axios.post('auth/has-permission', {
            permission: [permission],
        });
    }

    roleList() {
        return axios.post('auth/admin/roles', {
        });
    }

    addRole(name) {
        return axios.post('auth/admin/addrole', {
            name: name,
        });
    }

    renameRole(role_id,new_name) {
        return axios.post('auth/admin/renamerole', {
            new_name: new_name,
            role_id: role_id
        });
    }

    deleteRole(role_id) {
        return axios.post('auth/admin/deleterole', {
            role_id: role_id
        });
    }

    setPermission(role_id,perm_id,to) {
        return axios.post('auth/admin/setpermission', {
            role_id: role_id,
            perm_id: perm_id,
            to: to
        });
    }

    setUserPermission(user_id,perm_id,to) {
        return axios.post('auth/admin/setuserpermission', {
            user_id: user_id,
            perm_id: perm_id,
            to: to
        });
    }


    permissionList() {
        return axios.post('auth/admin/permissions', {
        });
    }

    userPermissionList(user_id) {
        return axios.post('auth/admin/userpermissions', {
            user_id: user_id
        });
    }

    giveUserPermission(user_id, permission_id) {
        return axios.post('auth/admin/giveuserpermission', {
            user_id: user_id,
            permission_id: permission_id
        });
    }

    revokeUserPermission(user_id, permission_id) {
        return axios.post('auth/admin/revokeuserpermission', {
            user_id: user_id,
            permission_id: permission_id
        });
    }

    giveUserRole(user_id, role_id) {
        return axios.post('auth/admin/giveuserrole', {
            user_id: user_id,
            role_id: role_id
        });
    }

    removeUserRole(user_id, role_id) {
        return axios.post('auth/admin/removeuserrole', {
            user_id: user_id,
            role_id: role_id
        });
    }

    addPermission(name) {
        return axios.post('auth/admin/addpermission', {
            name: name,
        });
    }

    renamePermission(perm_id,new_name) {
        return axios.post('auth/admin/renamepermission', {
            new_name: new_name,
            perm_id: perm_id
        });
    }

    deletePermission(perm_id) {
        return axios.post('auth/admin/deletepermission', {
            perm_id: perm_id
        });
    }




}
export default new PermissionService();

