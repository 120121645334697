<template>
  <v-row no-gutters>
    <v-snackbar
        v-model="snackBar.show"
        :timeout="snackBar.timeout"
        :color="snackBar.color"
        top
        multi-line
    >
      {{ snackBar.text}}
    </v-snackbar>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12">
          <v-card class="pa-3">
            <report-date-time-picker title="All Fuel Transactions" @change="change" :show-pdf="showPdf" :show-csv="showPdf" @csv="csvClicked" @pdf="renderPDF"></report-date-time-picker>
            <v-divider></v-divider>
            <v-data-table
                :items="reportData.rows"
                :headers="headers"
                :loading="loading"
            >
              <template v-slot:footer.prepend>
                <div style="background-color: #00acc1;width:100%">
                  Footer
                </div>
              </template>

              <template v-slot:item.retail="{ item }">
                {{ item.retail.formatCurency() }}
              </template>
              <template v-slot:item.ga_discount="{ item }">
                {{ item.ga_discount.formatCurency() }}
              </template>
              <template v-slot:item.customer_discount="{ item }">
                {{ item.customer_discount.formatCurency() }}
              </template>
              <template v-slot:item.ga_income="{ item }">
                {{ item.ga_income.formatCurency() }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import ReportDateTimePicker from "@/components/core/ReportDateTimePicker";
import ReportService from '@/services/report.service'

export default {
  name: "FuelAll",
  components: {ReportDateTimePicker},
  data: () => ({
    snackBar: {
      show: false,
      text: '',
      timeout: 5000,
      color: 'green'
    },

    loading: false,
    showPdf: false,

    from: new Date().toISOString().substr(0, 10),
    to: new Date().toISOString().substr(0, 10),
    span: 'today',

    reportData: [],

    headers: [
      {
        text: 'Transaction Date',
        sortable: true,
        value: 'transactionDate',
      },
      {
        text: 'Reference Number',
        sortable: true,
        value: 'ref',
      },
      {
        text: 'Name',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Retail Amount',
        sortable: true,
        value: 'retail',
        align: 'right'
      },
      {
        text: 'Grade A Discount',
        sortable: true,
        value: 'ga_discount',
        align: 'right'
      },
      {
        text: 'Customer Discount',
        sortable: true,
        value: 'customer_discount',
        align: 'right'
      },
      {
        text: 'Grade A Income',
        sortable: true,
        value: 'ga_income',
        align: 'right'
      },
    ],

  }),


  mounted() {
    this.getReportData();
  },


  methods:{


    renderPDF(){

      let body = []
      let headers = []
      let widths = []
      for(let i=0;i<this.headers.length;i++){
        headers.push(this.headers[i].text)
        widths.push('*')
      }
      body.push(headers)

      for(let i=0;i<this.reportData.rows.length;i++){
        let row = []
        for(let ii=0;ii<this.headers.length;ii++){
          if(this.headers[ii].value === 'retail'
              || this.headers[ii].value === 'ga_discount'
              || this.headers[ii].value === 'customer_discount'
              || this.headers[ii].value === 'ga_income'
          ) {
            row.push({text: this.reportData.rows[i][this.headers[ii].value].formatCurency(), alignment:'right'})

          }else if(this.headers[ii].value === 'transactionDate'){
            row.push(new Date(this.reportData.rows[i][this.headers[ii].value]).toLocaleDateString())
          }else{
            row.push(this.reportData.rows[i][this.headers[ii].value])
          }
        }
        body.push(row)
      }

      let from = new Date(this.from).toLocaleDateString()
      let to = new Date(this.to).toLocaleDateString()

      let docDefinition = {
        pageOrientation: 'landscape',
        info: {
          title: 'Fuel Profit Report',
          author: 'Grade A App',
          subject: 'fuel profit report',
          keywords: 'fuel report profit',
        },
        content: [
          {text: 'Fuel Profit Report', style: 'header',fontSize: 15},
          {text: 'Grade A Transport Inc.'},
          {text: 'Start Date: '+from},
          {text: 'End Date: '+to, margin: [0,0,0,20]},
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              // headers are automatically repeated if the table spans over multiple pages
              // you can declare how many rows should be treated as headers
              headerRows: 1,
              widths: widths,
              body: body
            }
          },
          {
            layout: 'lightHorizontalLines', // optional
            table: {
              headerRows: 1,
              widths: ['*','*','*','*','*','*','*'],
              body: [
                ['','','','','','',''],
                [
                  '',
                  '',
                  '',
                  {text: this.reportData.total_retail.formatCurency(),alignment:'right'},
                  {text: this.reportData.total_ga_discount.formatCurency(),alignment:'right'},
                  {text: this.reportData.total_customer_discount.formatCurency(),alignment:'right'},
                  {text: this.reportData.total_ga_income.formatCurency(),alignment:'right'}
                ],
              ]
            },
          },
        ],
        defaultStyle: {
          fontSize: 8,
          bold: false
        }
      };

      pdfMake.createPdf(docDefinition).download('fuel-profit-report');
    },


    csvClicked(){
     const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(this.reportData.csv);
      anchor.target = '_blank';
      anchor.download = 'fuelprofit'+this.from+'-'+this.to+'.csv';
      anchor.click();
    },

    getReportData(){
      this.loading = true;
      let _this = this
      ReportService.fuelProfitReport(this.fromWithTime,this.toWithTime)
          .then(function (response) {
            //_this.loading = false;
            _this.reportData = response.data;
            if(_this.reportData.rows.length)
              _this.showPdf = true
            //console.log(response)
          })
          .catch(function (error) {
            console.log(error);
            let msg = _this.$errorMessage(error)
            _this.snackBar.text = "Error loading report data!\n(" + msg + ")"
            _this.snackBar.color = 'red';
            _this.snackBar.show = true;
          })
          .then(function () {
            _this.loading = false
          })
    },

    change(obj){
      this.from = obj.from;
      this.to = obj.to;
      this.span = obj.span;
      this.getReportData()
    }
  },

  computed: {

    capDateSpan(){
      return this.dateSpan.charAt(0).toUpperCase() + this.dateSpan.slice(1);
    },

    fromWithTime(){
      return this.from + ' 00:00:00'
    },

    toWithTime(){
      return this.to + ' 23:59:59'
    },
  },


}
</script>

<style scoped>

</style>
