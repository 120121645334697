<template>
  <v-container fill-height fluid>
    <v-row class="title" align="center"
           justify="center">
      <v-col cols="12" align="center">Grade A Companies LLC</v-col>
      <v-col cols="12" align="center">1407 Industrial Park Drive</v-col>
      <v-col cols="12" align="center">Huntingburg, IN 47542</v-col>
      <v-col cols="12" align="center"></v-col>
    </v-row>
  </v-container></template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>

</style>
