<template>
  <v-dialog
      v-model="show"
      persistent
      max-width="600px"
  >
    <v-card :min-height="200" min-width="300">
      <v-card-text>
        <v-overlay
            :absolute="true"
            :opacity="0.7"
        >
          <v-row
              class="fill-height"
              align-content="center"
              justify="center"
          >
            <v-col
                class="text-h6 text-center"
                cols="12"
            >
              {{ title }}
            </v-col>
            <v-col cols="10">
              <v-progress-linear
                  color="green"
                  indeterminate
                  rounded
                  height="16"
              ></v-progress-linear>
            </v-col>
          </v-row>
        </v-overlay>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
name: "PleaseWait",

  props:{
    title:{
      type: String,
      default: 'Please Wait'
    },
    show:{
      type: Boolean,
      default: false,
    }
  }
}
</script>

<style scoped>

</style>
